import React, { Component, FC, Fragment, useEffect, useState } from 'react';
import { createStyles, WithStyles, withStyles, Theme, LinearProgress } from '@material-ui/core';
import { connect, useSelector, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { withRouter, RouteComponentProps /* useHistory */ } from 'react-router';
import { TReduxStore } from '../RootReducer';
import { TAction } from 'loop-front';
import AppNavigation from '../Router/AppNavigation';
import ConfirmationDialog from '../Components/Dialogs/ConfirmationDialog';
import { TDialogConfig, TDispatch } from '../Models/App/@types';
import { OApp } from '../Models/App';
import ToastMessage from '../Components/ToastMessage';
import useBoot from 'Boot';
import { useStoreState } from 'Stores';
import LoopFront from 'loop-front';
import Cookies from 'universal-cookie';
import AxiosUtils from 'Resources/AxiosUtils';
import PictureInputField from 'Components/PictureInputField';
import { attachField } from 'react-forms';
import CategoryButtonGroup from 'Components/CategoryButtonGroup';
import FetchPost from 'Components/FetchPost';
import UploadFile from 'Components/UploadFile';
import 'rf-autocomplete';
import 'rf-quill';

attachField('picture', <PictureInputField />);
attachField('upload-file', <UploadFile />);
attachField('category-select', <CategoryButtonGroup />);
attachField('post-select', <FetchPost />);

const cookies = new Cookies(); // temporary fix for login issue

interface IStateProps {}

interface IDispatchProps {}

interface IState {}

interface OwnProps {}

// eslint-disable-next-line
interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps, RouteComponentProps, OwnProps {}

class RootLayout extends Component<IProps, IState> {
	render() {
		// eslint-disable-next-line
		const { classes } = this.props;
		return (
			<Fragment>
				<Layout />
			</Fragment>
		);
	}
}

const Layout: FC = () => {
	const { isCompleted } = useBoot();
	const { user } = useStoreState(({ Auth: { user } }) => ({ user }));

	// const history = useHistory();

	useEffect(() => {
		// if (isCompleted && (!user || !user?.id)) {
		// 	history.replace('/');
		// }

		LoopFront.setAuthHeader(cookies.get('access_token'));
		AxiosUtils.setAuthHeader(cookies.get('access_token'));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, isCompleted]);

	return isCompleted ? (
		<>
			<AppNavigation />
			<DialogGlobal />
			<ToastMessage />
		</>
	) : (
		<LinearProgress />
	);
};

function DialogGlobal() {
	const dialog = useSelector<TReduxStore, TDialogConfig>((state) => state.App.dialog);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch<TDispatch>();

	const onClose = () => {
		dispatch(OApp.hideConfirmationDialog());
	};

	const onAgree = async () => {
		if (!dialog.onAccept) return;

		setLoading(true);
		try {
			await dialog.onAccept();
			onClose();
		} catch (error) {
			dispatch(
				OApp.showToast({
					message: 'Something went wrong',
					variant: 'error',
				})
			);
		}
		setLoading(false);
	};

	const onDisagree = async () => {
		if (dialog.onCancel) dialog.onCancel();
		onClose();
	};

	return (
		<ConfirmationDialog
			open={dialog.open}
			message={dialog.message}
			onClose={onClose}
			onAgree={onAgree}
			loading={loading}
			onDisagree={onDisagree}
		/>
	);
}

const mapStateToProps = (state: TReduxStore): IStateProps => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<TReduxStore, {}, TAction>): IDispatchProps => ({});

const STYLES = (theme: Theme) => createStyles({});

export default withRouter(
	connect<IStateProps, IDispatchProps, OwnProps, TReduxStore>(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(RootLayout))
);
