import LoopFront from 'loop-front';

const CustomActions = {
	CHANGE_ORDER: 'CHANGE_ORDER',
	DELETE_LIST_ITEMS: 'DELETE_LIST_ITEMS',
	SHOW_MAIN_FORM: 'SHOW_MAIN_FORM',
	HIDE_MAIN_FORM: 'HIDE_MAIN_FORM',
	ADD_LIST_ITEM: 'ADD_LIST_ITEM',
	SET_EDITING_ITEM: 'SET_EDITING_ITEM',
	UPDATE_LIST_ITEM: 'UPDATE_LIST_ITEM',
};

const CustomEntities = {};
const CustomActivities = {};

class ExploreHome extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivities> {
	constructor() {
		super('exploreHomes', CustomActions, CustomEntities, CustomActivities);
	}
}

export const OExploreHome = new ExploreHome();
