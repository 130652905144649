import { Box, Chip, createStyles, Icon, makeStyles, MenuItem, Select, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TDispatch } from '../../Models/App/@types';
import { OPost } from '../../Models/Post';
import { TPostListingFilter, TPostSort, TPostSortOrder } from '../../Models/Post/@types';
import { TReduxStore } from '../../RootReducer';

interface IProps {}

const Sort: React.FC<IProps> = (props) => {
	const classes = useStyles();
	return (
		<Box display="flex" alignItems="center" className={classes.root}>
			<Box mr="20px">
				<Typography>Sort by </Typography>
			</Box>
			<Box mr="8px">
				<SortOptions />
			</Box>
			<SortOrder />
		</Box>
	);
};

function SortOrder() {
	const config = OPost.sortOrderOptions;
	const dispatch = useDispatch<TDispatch>();
	const sortOrder = useSelector<TReduxStore, TPostSortOrder>((state) => state.Post.sortOrder);
	const listingFilter = useSelector<TReduxStore, TPostListingFilter>((state) => state.Post.listingFilter);

	const handleChange = (value: any) => {
		dispatch(OPost.setSortOrder(listingFilter, value));
	};

	const isSelected = (conf: typeof config[0]) => {
		return conf.key === sortOrder;
	};
	const getLabel = (conf: typeof config[0]) => {
		return <Icon>{conf.icon}</Icon>;
	};

	return (
		<Box>
			<Box display="flex" flexDirection="row" style={{ boxSizing: 'border-box', flexWrap: 'wrap' }}>
				{config.map((c) => (
					<Box display="inline-block" m="4px" key={c.label}>
						<Chip color={isSelected(c) ? 'primary' : 'default'} label={getLabel(c)} onClick={(e) => handleChange(c.key)} />
					</Box>
				))}
			</Box>
		</Box>
	);
}

function SortOptions() {
	const config = OPost.sortOptions;
	const dispatch = useDispatch<TDispatch>();
	const sort = useSelector<TReduxStore, TPostSort>((state) => state.Post.sort);
	const listingFilter = useSelector<TReduxStore, TPostListingFilter>((state) => state.Post.listingFilter);

	const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
		dispatch(OPost.setSort(listingFilter, event.target.value as TPostSort));
	};

	return (
		<Box>
			<Select value={sort} onChange={handleChange} className={''}>
				{config.map((item, index) => {
					return (
						<MenuItem key={index} value={item.key}>
							{item.label}
						</MenuItem>
					);
				})}
			</Select>
		</Box>
	);
}

// function ActionButtons(props: { close: () => void }) {
// 	const dispatch = useDispatch<TDispatch>();
// 	const sort = useSelector<TReduxStore, TPostSort>((state) => state.Post.sort);
// 	const sortOrder = useSelector<TReduxStore, TPostSortOrder>((state) => state.Post.sortOrder);
// 	const listingFilter = useSelector<TReduxStore, TPostListingFilter>((state) => state.Post.listingFilter);
// 	const apply = () => {
// 		dispatch(OPost.applySorting(listingFilter, sort, sortOrder));
// 		props.close();
// 	};

// 	const reset = () => {
// 		dispatch(OPost.resetSorting());
// 		props.close();
// 	};
// 	return (
// 		<Box display="flex">
// 			<Button size="large" style={{ borderRadius: 0 }} variant="contained" color="primary" onClick={(e) => apply()} fullWidth>
// 				Apply
// 			</Button>
// 			<Button size="large" style={{ borderRadius: 0 }} variant="contained" color="primary" onClick={(e) => reset()} fullWidth>
// 				Reset
// 			</Button>
// 		</Box>
// 	);
// }

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			background: theme.palette.common.white,
			padding: '4px 16px',
			borderRadius: 6,
			boxShadow: theme.shadows[4],
		},
	})
);

export default Sort;
