import React, { FC } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import CategoryButton from "./Buttons/CategoryButton";
import { FormikProps } from "formik";
import { TPostType } from "Models/App/@types";
import { IFieldProps } from "react-forms";

interface CategoryButtonGroupFieldProps {
	postTypes: Array<TPostType>;
	name: string;
}

interface CategoryButtonGroupProps extends IFieldProps {
	fieldProps?: CategoryButtonGroupFieldProps;
}

const CategoryButtonGroup: FC<CategoryButtonGroupProps> = (props) => {
	const { fieldProps = {} as CategoryButtonGroupFieldProps, formikProps = {} as FormikProps<unknown> } = props;
	const { postTypes = [], name = '' } = fieldProps;
	const classes = useStyles();

	const handleCategoryClick = (category: TPostType) => {
		formikProps.setFieldValue(name, category.value);
	};

	return <div className={classes.typesContainer}>
		{postTypes.map((item, index) => {
			return (
				<CategoryButton isActive={item.value === formikProps.values[name]} key={index} category={item} onClick={handleCategoryClick} />
			);
		})}
	</div>;
};
export default CategoryButtonGroup;

const useStyles = makeStyles(() => createStyles({
	typesContainer: {
		display: 'flex',
	},
}));