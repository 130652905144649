import _ from 'lodash';
import { TTagListingFilter, TTagCategory, TTag } from '../../Models/Tag/@types';


class TagUtils {

    static buildFilter = (filter: TTagListingFilter) => {
        let where: Record<string, any> = {}
        filter.forEach(f => {
            where[f.value] = true;
        })
        return { where }
    }
    static transformSelectedTagsToTagCategories = (selectedTagIds: string[], tagCategories: TTagCategory[]) => {
        let selectedCategories: TTagCategory[] = [];
        _.forEach(tagCategories, category => {
            let tags: TTag[] = [];
            let tagIds: string[] = [];
            _.forEach(category.tags, t => {
                if (_.indexOf(selectedTagIds, t.id) > -1) {
                    tags.push(t);
                    tagIds.push(t.id);
                }
            })
            selectedCategories.push({ ...category, tags, tagIds })
        })
        return selectedCategories.filter(c => c.tagIds.length > 0)
    }
}

export default TagUtils