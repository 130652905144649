import { ListDocument } from "Models/ExploreList/@types";
import groupBy from 'lodash/groupBy';


export function transformListType(type: string | string[]) {
    if (typeof type === 'string') return [type];
    return type;
}

export function transformListDocumentsToFormData(list: ListDocument[]) {
    let data: Record<string, string[]> = {};
    const temp = groupBy(list.map(l => l.subject), 'subjectType');
    const keys = Object.keys(temp);
    keys.forEach(k => {
        data[k] = temp[k].map(d => d.id)
    })
    return data;
}