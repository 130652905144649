import React, { FC } from "react";
import { IFieldProps } from "react-forms";
import { FormikProps } from "formik";
import PictureInput, { PictureInputProps as PictureInputComponentProps } from "./PictureInput";
import { InputLabel } from "@material-ui/core";


/* 
	Wrapper over PictureInput component. This is intended to be used with attachField and react-forms. 
	The PictureInput component is not intended to do so. This component must replace the PictureInput component once it is not being used anywhere.
*/
export interface PictureInputFieldProps extends PictureInputComponentProps {
	label?: string;
	shrinkLabel?: boolean;
	name?: string;
}

interface PictureInputProps extends IFieldProps {
	fieldProps?: PictureInputFieldProps;
}

const PictureInputField: FC<PictureInputProps> = (props) => {
	const { fieldProps = {} as PictureInputFieldProps, formikProps = {} as FormikProps<any> } = props;
	const { name = '', label = 'Image', ...pictureInputProps } = fieldProps;
	return <>
		<InputLabel shrink>{label}</InputLabel>
		<PictureInput
			onPicUpload={(pic) => formikProps.setFieldValue(name, pic)}
			initialPicture={formikProps.values[name]}
			onPictureRemove={() => formikProps.setFieldValue(name, null)}
			{...pictureInputProps} /></>;
};

export default PictureInputField;