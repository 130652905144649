import React, { useState } from 'react'
import { createStyles, makeStyles, Theme, useTheme, Box } from '@material-ui/core'
import DialogForm from '../../Components/Dialogs/DialogForm';
import { TTagCategory, TTag } from '../../Models/Tag/@types';
import TagCategoryCard from '../../Components/Cards/TagCategoryCard';




interface IProps {
    handleSubmit: () => Promise<void>
    open: boolean
    dialogTitle: string
    onClose: () => void
    tagCategories: TTagCategory[]
    handleTagSelect: (tag: TTag) => void
    selectedTagIds: string[]
}

const AssignTag: React.FC<IProps> = (props) => {

    const theme = useTheme<Theme>();
    const { tagCategories = [], selectedTagIds = [], dialogTitle } = props;
    const classes = useStyles(theme);
    const [loading, setLoading] = useState(false);







    const handleSubmit = async () => {
        setLoading(true);
        try {
            await props.handleSubmit();
            props.onClose();
        } catch (error) {

        }
        setLoading(false)
    }





    return (
        <DialogForm
            heading={dialogTitle}
            onSubmit={handleSubmit}
            loading={loading}
            onClose={props.onClose}
            open={props.open}
        >
            <Box width="700px" pb="20px" m="0 auto">
                {
                    tagCategories.map(tag => tag.tags && tag.tags.length > 0 ? (
                        <div key={tag.id} className={classes.tagCategoryItem}>
                            <TagCategoryCard
                                category={tag}
                                selectedTagIds={selectedTagIds}
                                onTagSelect={props.handleTagSelect}
                            />
                        </div>
                    ) : null)
                }
            </Box>

        </DialogForm>
    )
}


export function useAssignTag<T extends any>(onSubmit: (tagIds: string[], item: T) => Promise<void>, tagCategories: TTagCategory[]) {
    const [open, setOpen] = useState(false);
    const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
    const [dialogTitle, setDialogTitle] = useState('');
    const [entity, setEntity] = useState<T | undefined>(undefined);


    const handleTagSelect = (tag: TTag) => {
        if (selectedTagIds.indexOf(tag.id) > -1)
            setSelectedTagIds(selectedTagIds.filter(t => t !== tag.id));
        else
            setSelectedTagIds(current => [...current, tag.id]);
    }

    const handleSubmit = async () => {
        if (!entity) return;
        await onSubmit(selectedTagIds, entity);
        setEntity(undefined);
        setDialogTitle('')
    }




    const assignTag = (initialSelection: string[] = [], entity: T, title: string = '') => {
        setSelectedTagIds(initialSelection);
        setEntity(entity);
        setDialogTitle(title);
        setOpen(true);
    }

    return {
        assignTag,
        AssignTagComponent: (
            <AssignTag
                dialogTitle={dialogTitle}
                selectedTagIds={selectedTagIds}
                handleTagSelect={handleTagSelect}
                tagCategories={tagCategories}
                open={open}
                handleSubmit={handleSubmit}
                onClose={() => setOpen(false)}
            />
        )
    }

}

const useStyles = makeStyles((theme: Theme) => createStyles({

    tagCategoryItem: {
        borderBottom: `1px solid ${theme.palette.grey['400']}`,
        '&:last-child': {
            borderBottom: 'none'
        }
    }
}))

export default AssignTag