import Config from './config';
import AxiosUtils from './Resources/AxiosUtils';
import { useState, useEffect } from 'react';
import { useStoreActions } from 'Stores';
import useAsyncTask from 'Hooks/useAsyncTask';

const VALIDATE_CONFIG_PROPERTIES = ['BASE_URL', 'API_URL'];

const validateConfig = () => {
	VALIDATE_CONFIG_PROPERTIES.forEach(key => {
		const val = Config.get(key);
		if (!val)
			throw new Error(`App config must define ${key}, got: ${val}, ${JSON.stringify(Config)}`);
	});

};

const useBoot = () => {
	const [isCompleted, setIsCompleted] = useState(false);
	const { loadAuthInfo: loadAuthInfoAction } = useStoreActions(({ Auth: { loadAuthInfo } }) => ({ loadAuthInfo }));
	const loadAuthInfo = useAsyncTask(loadAuthInfoAction);

	useEffect(() => {

		AxiosUtils.setBaseAPI_URL((Config.get('API_URL') || '') as string);

		validateConfig();

		/** Override console.log as per environment file */
		if (Config.get('CONSOLE_LOGGING') === false) {
			console.log = () => { };
		}

		Promise.all([
			loadAuthInfo.run({}),
		]).then(() => {
			setIsCompleted(true);
		}).catch(() => {
			setIsCompleted(true);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { isCompleted };

};

export default useBoot;