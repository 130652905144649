import React from 'react';
import { createStyles, makeStyles, Theme, Paper, Box, Avatar, Typography } from '@material-ui/core';
import { TUser } from '../../Models/User/@types';
import { TIndustry } from '../../Models/App/@types';
import { Link } from 'react-router-dom';
import utilities from '../../Resources/Utils';

interface IProps {
	user: TUser;
	industriesConstants?: TIndustry[];
	redirectUrl?: string;
	listItem?: boolean;
}

const UserCardAlt: React.FC<IProps> = (props) => {

	const classes = useStyles(props);
	const { user, industriesConstants = [], listItem = false } = props;

	const getSubtitle = () => {
		const industry = utilities.findValues(industriesConstants, user.industry);
		const subtitle: string[] = [];
		if (industry && industry.length > 0)
			subtitle.push(industry[0]);
		if (user.location)
			subtitle.push(user.location);
		return subtitle.join('<span class="separator"></span>');
	};

	const jobTitle = user.recentJobTitle ? `${user.recentJobTitle} ${user.recentCompany ? ' at ' + user.recentCompany : ''}` : '';
	return listItem ? (
		<Link to={props.redirectUrl || '#'}>
			<Box display="flex" alignItems="center" p="10px" >
				<Avatar
					alt={user.name}
					src={(user.profileImage || {}).thumbnail || ''}
					className={classes.avatarMini}
				/>
				<Typography gutterBottom variant="body2">{user.name}</Typography>
			</Box>
		</Link>

	) : (
			<Paper elevation={1}>
				<Link to={props.redirectUrl || '#'}>
					<Box display="flex" flexDirection="column" alignItems="center" p="20px 10px" >
						<Avatar
							alt={user.name}
							src={(user.profileImage || {}).thumbnail || ''}
							className={classes.avatar}
						/>
						<Box mt="4px">
							<Typography gutterBottom variant="body2"><b>{user.name}</b></Typography>
						</Box>
						<Typography variant="caption" color="textSecondary">{jobTitle}</Typography>
						<Typography variant="caption"
							color="textSecondary"
							className={classes.subtitle}
							dangerouslySetInnerHTML={{ __html: getSubtitle() }}
						/>
					</Box>
				</Link>

			</Paper>
		);
};

const useStyles = makeStyles((theme: Theme) => createStyles({
	avatar: {
		width: 70,
		height: 70,
	},
	avatarMini: {
		width: 30,
		height: 30,
		marginRight: 8
	},
	subtitle: {
		display: 'flex',
		alignItems: 'center',
		'& .separator': {
			width: 4,
			height: 4,
			borderRadius: 2,
			margin: '0px 3px',
			background: theme.palette.text.secondary
		}
	}
}));

export default UserCardAlt;