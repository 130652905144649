import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import { AxiosPromise } from 'axios';
import PostDialogForm from 'features/post/PostDialogForm';
import { TAction } from 'loop-front';
import { Chapter } from 'Models/chapter/@types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { OPost } from '../../Models/Post';
import { TPost } from '../../Models/Post/@types';
import { TReduxStore } from '../../RootReducer';

interface IStateProps {
	showPostForm: boolean;
	editedPost?: TPost;
}

interface IDispatchProps {
	closeForm: () => void;
	submitNewPost: (formData: Partial<(TPost | Chapter) & { scope: string; type: string; teamId: string }>) => AxiosPromise;
}

interface IState {
	loading: boolean;
	formData: Partial<TPost & { scope: string; type: string; teamId: string }>;
}

interface OwnProps {}

interface MatchParams {
	id: string;
}

interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps, OwnProps, RouteComponentProps<MatchParams> {}

class PostForm extends Component<IProps, IState> {
	render() {
		const {
			showPostForm,
			match: {
				params: { id },
			},
			editedPost,
		} = this.props;

		return (
			<PostDialogForm
				showDuration
				type={'post'}
				initialFormData={{ postType: 'post', userId: id, ...editedPost, isShownInFeed: !editedPost ? true : editedPost.isShownInFeed }}
				heading={editedPost?.id ? 'Update Post' : 'Add new post'}
				open={showPostForm}
				onClose={this.props.closeForm}
				onSubmit={this.props.submitNewPost}
			/>
		);
	}
}

const mapStateToProps = (state: TReduxStore): IStateProps => ({
	showPostForm: state.Post.showMainForm,
	editedPost: state.Post.editedPost,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<TReduxStore, {}, TAction>): IDispatchProps => ({
	closeForm: () => {
		dispatch({ type: OPost.Actions.HIDE_FORM });
		dispatch({ type: OPost.Actions.SET_EDITED_POST, data: undefined });
	},
	submitNewPost: (formData) => dispatch(OPost.postActivity(OPost.Activites.CREATE, formData, {})),
});

const STYLES = (theme: Theme) => createStyles({});

export default withRouter(
	connect<IStateProps, IDispatchProps, OwnProps, TReduxStore>(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(PostForm))
);
