import { Box, Chip, FormControlLabel, FormLabel, InputLabel, Paper, Radio, RadioGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DialogForm, { FullScreenDialogProps } from 'Components/Dialogs/DialogForm';
import useAsyncTask from 'Hooks/useAsyncTask';
import { TPostType, TDispatch } from 'Models/App/@types';
import { Chapter } from 'Models/chapter/@types';
import { TPost } from 'Models/Post/@types';
import { TUser } from 'Models/User/@types';
import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TReduxStore } from 'RootReducer';
import _startCase from 'lodash/startCase';
import useToastMessage from 'Hooks/useToastMessage';
import { parsePostForm } from 'Models/Post/PostParsers';
import { OPost } from 'Models/Post';
import { Formik, FormikProps } from 'formik';
import { MLFormContent, RowSchema } from 'react-forms';
import clsx from 'clsx';
import { TTag, TTagCategory } from 'Models/Tag/@types';
import utilities from 'Resources/Utils';
import _uniqWith from 'lodash/uniqWith';

interface IStateProps {
	appUser?: TUser;
	postTypes?: Array<TPostType>;
	list?: TTagCategory[];
}
export interface PostDialogFormProps extends Omit<FullScreenDialogProps, 'onSubmit' | 'loading' | 'classes'> {
	type?: 'chapter' | 'post';
	initialFormData?: Partial<TPost | Chapter>;
	onSubmit: (formData: Partial<TPost | Chapter>) => Promise<any>;
	showDuration?: boolean;
}

const PostDialogForm: FC<PostDialogFormProps> = (props) => {
	const { type = 'post', initialFormData = {}, onSubmit, showDuration, ...dialogFormProps } = props;
	const classes = useStyles();
	const dispatch = useDispatch<TDispatch>();
	const submitTask = useAsyncTask(onSubmit);

	const withToast = useToastMessage();

	const {
		postTypes = [],
		appUser: user,
		list: tagCategories,
	} = useSelector<TReduxStore, IStateProps>(({ App: { postTypes }, User: { appUser }, Tag: { list } }) => ({
		postTypes,
		appUser,
		list,
	}));

	const handleScopeChange = (formikProps: FormikProps<any>, value: string) => {
		if (value === 'everyone' || value === 'followers') {
			formikProps.setFieldValue('scope', value);
		} else {
			formikProps.setFieldValue('scope', 'team');
			formikProps.setFieldValue('teamId', value);
		}
	};

	const handleSubmit = async (formData: Partial<TPost | Chapter>) => {
		let res;
		if (!formData.id)
			res = await withToast(
				async () =>
					await submitTask.run(formData).catch((error) => {
						throw error;
					})
			);
		else {
			res = await withToast(async () => await dispatch(OPost.patchItem(formData.id || '', parsePostForm(formData))));
		}
		if (res && res.status === 200) handleClose();
	};

	const handleClose = async () => {
		dialogFormProps.onClose?.();
	};
	const allTags: (TTag | undefined)[] = tagCategories?.map((tagCategory) => tagCategory.tags).flat() || [];
	// const pathwayTagIds = (tagCategories || []).find((tagCategory) => tagCategory.name === 'Pathways')?.tagIds || [];

	const config = getConfig(postTypes, classes);
	return (
		<Formik<Partial<TPost | Chapter> & { scope?: string; teamId?: string }>
			/* formId={'post-form'} */ onSubmit={(formData) => handleSubmit(formData)}
			initialValues={{ ...initialFormData }}
			enableReinitialize
		>
			{(formikProps) => {
				const { values } = formikProps;
				return (
					<DialogForm loading={formikProps.isSubmitting} onSubmit={formikProps.handleSubmit} {...dialogFormProps}>
						<div className={classes.root}>
							<Paper className={classes.paper}>
								<MLFormContent formId={'post-form'} schema={config.category} formikProps={formikProps} />
								{type === 'post' ? (
									<>
										<MLFormContent formId={'post-form'} schema={config.learning} formikProps={formikProps} />
										<FormLabel component="legend">Share post with</FormLabel>
										<RadioGroup
											aria-label="Share post with"
											name="scope"
											onChange={(e, value) => handleScopeChange(formikProps, value)}
											value={
												formikProps.values.scope === 'everyone' || formikProps.values.scope === 'followers'
													? formikProps.values.scope
													: formikProps.values.teamId
											}
										>
											<FormControlLabel value="everyone" control={<Radio />} label="Everyone" />
											<FormControlLabel value="followers" control={<Radio />} label="Follower" />
											{(user?.teams || []).map((team, i) => {
												return <FormControlLabel key={i} control={<Radio />} label={team.name} value={team.id} />;
											})}
										</RadioGroup>
									</>
								) : null}
								{!!values.type && values.type !== 'video' && values.type !== 'podcast' ? (
									<Box>
										{' '}
										<Typography variant={'subtitle2'}> Preview:</Typography>
										<iframe width={340} height={128} title={'preview'} src={values.url} />
										<MLFormContent formId={'post-form'} schema={config.pdf} formikProps={formikProps} />
									</Box>
								) : null}
								{type === 'chapter' && !!formikProps.values.type ? (
									<Box>
										<MLFormContent formId={'post-form'} schema={config.curatorOpns} formikProps={formikProps} />
									</Box>
								) : null}
								{!!formikProps.values.type && showDuration ? (
									<Box mt={2}>
										<InputLabel shrink>Duration of {_startCase(formikProps.values.type)}</InputLabel>
										<Box mt={0.5}>
											<MLFormContent formikProps={formikProps} formId={'post-form'} schema={config.lastSection} />
										</Box>
									</Box>
								) : null}
								{!!formikProps.values.type ? (
									<>
										<Box mb={3}>
											<Typography variant={'h6'}>Select Tags</Typography>
										</Box>
										{(
											_uniqWith(
												allTags?.filter((tag) => !!tag /* && !pathwayTagIds.includes(tag.id) */),
												(val1, val2) => val1?.id === val2?.id
											) as TTag[]
										).map((t: TTag) => {
											if (!t) return null;
											const isActive = values.tagIds?.includes(t.id);
											return (
												<Chip
													key={t.id}
													label={t.name}
													className={clsx(classes.chip, isActive ? classes.activeChip : classes.inactiveChip)}
													onClick={() =>
														formikProps.setFieldValue(
															'tagIds',
															utilities.toggleItemFromList(values.tagIds || [], t.id, '', (a, b) => a === b)
														)
													}
												/>
											);
										})}
									</>
								) : null}
							</Paper>
						</div>
					</DialogForm>
				);
			}}
		</Formik>
	);
};

export default PostDialogForm;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 30,
	},
	paper: {
		width: 450,
		padding: 20,
	},
	learning: {
		marginBottom: 20,
	},
	checkbox: {
		marginLeft: 10,
	},
	durationField: {
		width: 125,
		'& input[type="number"]': {
			'& ::-webkit-outer-spin-button': {
				'-webkit-appearance': 'none',
				margin: 0,
			},
			'&::-webkit-inner-spin-button': {
				'-webkit-appearance': 'none',
				margin: 0,
			},
			appearance: 'textfield',
		},
	},
	chip: {
		marginRight: 8,
		marginBottom: 8,
	},
	inactiveChip: {
		backgroundColor: theme.palette.grey['700'],
		'&:active': { backgroundColor: theme.palette.grey['500'], borderColor: 'white' },
		'&:hover': { backgroundColor: theme.palette.grey['500'], borderColor: 'white' },
		'&:focus': { backgroundColor: theme.palette.grey['500'], borderColor: 'white' },
	},
	activeChip: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		'&:active': { backgroundColor: theme.palette.primary.main, borderColor: theme.palette.primary.main },
		'&:focus': { backgroundColor: theme.palette.primary.main, borderColor: theme.palette.primary.main },
		'&:hover': { backgroundColor: theme.palette.primary.main, borderColor: theme.palette.primary.main },
	},
}));

const getConfig = (postTypes: TPostType[], classes: Record<string, string>): Record<string, RowSchema[]> => {
	return {
		category: [
			{
				type: 'category-select',
				valueKey: 'type',
				fieldProps: {
					postTypes,
				},
			},
			{
				type: 'post-select',
				valueKey: '',
				fieldProps: {
					postTypes,
				},
			},
		],
		curatorOpns: [
			{
				type: 'text',
				valueKey: 'curatorQuestion',
				fieldProps: {
					label: "Curator's question",
					fullWidth: true,
				},
			},
			{
				type: 'text',
				valueKey: 'curatorAnswer',
				fieldProps: {
					label: "Curator's answer",
					fullWidth: true,
					multiline: true,
					rows: 2,
				},
			},
		],
		pdf: [
			{
				type: 'upload-file',
				valueKey: 'assets',
				fieldProps: {
					label: 'Upload Pdf if required',
				},
			},
		],
		learning: [
			{
				type: 'text',
				valueKey: 'learning',
				fieldProps: {
					multiline: true,
					rows: 2,
					fullWidth: true,
					variant: 'outlined',
					className: classes.learning,
					label: 'Learning',
				},
			},
		],
		lastSection: [
			[
				{
					type: 'text',
					valueKey: 'contentDuration.hours',
					fieldProps: {
						type: 'number',
						className: classes.durationField,
						label: 'Hours',
					},
				},
				{
					type: 'text',
					valueKey: 'contentDuration.minutes',
					fieldProps: {
						type: 'number',
						className: classes.durationField,
						label: 'Minutes',
					},
				},
				{
					type: 'text',
					valueKey: 'contentDuration.sec',
					fieldProps: {
						type: 'number',
						className: classes.durationField,
						label: 'Seconds',
					},
				},
			],
			[
				{
					type: 'checkbox',
					valueKey: 'openInNewTab',
					fieldProps: {
						label: 'Open lesson in new tab',
						className: classes.checkbox,
					},
				},
				{
					type: 'checkbox',
					valueKey: 'isShownInFeed',
					fieldProps: {
						label: 'Show this post/chapter on feed',
						className: classes.checkbox,
					},
				},
			],
		],
	};
};
