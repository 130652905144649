import { TAction } from 'loop-front';
import { OPost } from 'Models/Post';
import { Reducer } from 'redux';
import { OMicroCourse } from '.';
import utilities from '../../Resources/Utils';
import { MicroCourseState } from './@types';
import { parseMicroCourse } from './microCourseParser';

export const RMicroCourse: Reducer<MicroCourseState, TAction> = (state = { list: [], showMainForm: false, showChapterForm: false }, action) => {
	const {
		LIST_RECEIVED,
		SHOW_MICRO_COURSE_FORM,
		HIDE_MICRO_COURSE_FORM,
		ACTIVITY_POST_SUCCESS,
		SINGLE_ITEM_RECEIVED,
		ITEM_PATCH_SUCCESS,
		POST_ENTITY_OF_ITEM_SUCCESS,
		MICRO_COURSE_DELETED,
		SHOW_CHAPTER_FORM,
		HIDE_CHAPTER_FORM,
		MICRO_COURSE_UPDATED,
	} = OMicroCourse.Actions;
	const { CREATE_NEW } = OMicroCourse.Activites;
	const { PUBLISH, CHAPTER } = OMicroCourse.Entities;
	switch (action.type) {
		case SINGLE_ITEM_RECEIVED:
			return { ...state, microCourseDetails: parseMicroCourse(action.data), microCourseChapters: [] };
		case ITEM_PATCH_SUCCESS:
			if (Array.isArray(action.data)) return { ...state, list: action.data };
			else if (typeof action.data === 'object') {
				// object is a catch all in JS. Put more specific checks above this.
				return {
					...state,
					microCourseDetails:
						state.microCourseDetails && state.microCourseDetails?.id === action.data.id
							? { ...state.microCourseDetails, ...action.additionalDispatchData.data }
							: state.microCourseDetails,
					list: utilities.updateItemList(state.list, parseMicroCourse(action.data), 'UPDATE'),
				};
			} else return state;
		case LIST_RECEIVED:
			return { ...state, list: action.data?.map(parseMicroCourse) };
		case SHOW_MICRO_COURSE_FORM:
			return { ...state, showMainForm: true, editedMicroCourse: action.data };
		case HIDE_MICRO_COURSE_FORM:
			return { ...state, showMainForm: false, editedMicroCourse: undefined };
		case SHOW_CHAPTER_FORM:
			return { ...state, showChapterForm: true, editedChapter: action.data };
		case HIDE_CHAPTER_FORM:
			return { ...state, showChapterForm: false, editedChapter: undefined };
		case MICRO_COURSE_UPDATED:
			return { ...state, microCourseDetails: { ...state.microCourseDetails, ...action.data } };
		case ACTIVITY_POST_SUCCESS: {
			switch (action.activity) {
				case CREATE_NEW:
					return { ...state, list: utilities.updateItemList([...(state.list || [])], parseMicroCourse(action.data), 'ADD') };
				default:
					return state;
			}
		}
		case OMicroCourse.Actions.ACTIVITY_RECEIVED: {
			switch (action.activity) {
				case OMicroCourse.Activites.COUNT:
					return { ...state, totalCount: action.data.count };
				default:
					return { ...state };
			}
		}
		case OMicroCourse.Actions.SHOW_MICRO_COURSE_GALLERY:
			return { ...state, showMicroCourseGallery: true };
		case OMicroCourse.Actions.HIDE_MICRO_COURSE_GALLERY:
			return { ...state, showMicroCourseGallery: false };
		case OPost.Actions.ACTIVITY_POST_SUCCESS: {
			switch (action.activity) {
				case OPost.Activites.CREATE_CHAPTER: {
					let updatedMicroCourse = {
						...state.microCourseDetails,
						chapterIds: [...(state.microCourseDetails?.chapterIds || []), action.data.id],
						chapters: [...(state.microCourseDetails?.chapters || []), action.data],
					};
					return {
						...state,
						microCourseDetails: updatedMicroCourse,
						list: utilities.updateItemList(state.list, updatedMicroCourse, 'UPDATE'),
					};
				}
				default:
					return state;
			}
		}
		case POST_ENTITY_OF_ITEM_SUCCESS: {
			switch (action.entity) {
				case PUBLISH:
					return {
						...state,
						microCourseDetails:
							state.microCourseDetails?.id === action.data.id
								? { ...state.microCourseDetails, ...parseMicroCourse(action.data) }
								: state.microCourseDetails,
						list: utilities.updateItemList(state.list, parseMicroCourse(action.data), 'UPDATE'),
					};
				case CHAPTER: {
					let updatedMicroCourse = {
						...state.microCourseDetails,
						chapterIds: [...(state.microCourseDetails?.chapterIds || []), action.data.id],
						chapters: [...(state.microCourseDetails?.chapters || []), action.data],
					};
					return {
						...state,
						microCourseDetails: updatedMicroCourse,
						list: utilities.updateItemList(state.list, updatedMicroCourse, 'UPDATE'),
					};
				}
				default:
					return state;
			}
		}
		case MICRO_COURSE_DELETED: {
			return {
				...state,
				microCourseDetails: action.data.id === state.microCourseDetails?.id ? undefined : state.microCourseDetails,
				list: utilities.updateItemList(state.list, { id: action.data.id }, 'DELETE'),
			};
		}

		case OPost.Actions.ITEM_PATCH_SUCCESS: {
			return {
				...state,
				microCourseDetails: state.microCourseDetails
					? {
							...state.microCourseDetails,
							chapters: utilities.updateItemList(state.microCourseDetails.chapters || [], action.data, 'UPDATE'),
					  }
					: state.microCourseDetails,
			};
		}
		default:
			return state;
	}
};
