import { ExploreList, ListDocument } from './@types';
import { parseMicroCourse } from 'Models/MicroCourse/microCourseParser';
import { parsePost } from 'Models/Post/PostParsers';
import _omit from 'lodash/omit';
import { transformListType } from 'features/ExploreList/transformers';

export const parseExploreListForm = (obj: Partial<ExploreList>): Record<string, any> => {
	const listDocuments = obj.listDocuments;
	obj = _omit(obj, 'listDocuments', 'type');

	return { ...obj, 'list-documents': listDocuments };
};

export const parseExploreList = (obj: Record<string, any>): ExploreList => {
	let listDocuments: ListDocument[] | undefined = obj['list-documents'];
	listDocuments = listDocuments?.map(parseListDocument);

	return { ...obj, type: transformListType(obj['type'] || []), listDocuments } as ExploreList;
};

export const parseListDocument = (LD: Record<string, any>): ListDocument => {
	LD.subjectType === 'MicroCourse' ? (LD.subject = parseMicroCourse(LD.subject)) : (LD.subject = parsePost(LD.subject));
	return LD as ListDocument;
};
