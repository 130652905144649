import { Box, Fab } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import React, { FC, useEffect, useState } from 'react';
import useConfirmationDialog from '../Hooks/useConfirmationDialog';
import useToastMessage from '../Hooks/useToastMessage';
import PictureModel from '../Models/Picture';
import { TPicture } from '../Models/Picture/@types';
import SolidSmallIconButton from './Buttons/SolidSmallIconButton';
import FileInput, { FileInputProps, TFile } from './FileInput';
import Loader from './Loader';

export interface PictureInputProps extends Omit<FileInputProps, 'onDone'> {
	onPicUpload?: (picture: TPicture) => any;
	initialPicture?: TPicture | null;
	onPictureRemove?: () => any;
	confirmationMessage?: string;
}

const PictureInput: FC<PictureInputProps> = (props) => {
	const classes = useStyles();
	const { onPicUpload, initialPicture, onPictureRemove, confirmationMessage = 'You sure want to remove this image?', ...fileInputProps } = props;
	const [selectedPicture, setSelectedPicture] = useState<TPicture | undefined | null>(initialPicture);
	const [uploading, setUploading] = useState(false);
	const withToast = useToastMessage();

	const withConfirmationDialog = useConfirmationDialog();

	const handleUpload = async (files: TFile[]) => {
		setUploading(true);
		await withToast(
			async () => {
				if (files.length < 1) {
					throw new Error('File is not selected');
				}
				const pic = await PictureModel.upload(files[0], files[0].name || 'Thumbnail', 'chet').catch((err) => {
					setUploading(false);
					throw err;
				});
				onPicUpload?.(pic as TPicture);
				setSelectedPicture(pic);
				return pic;
			},
			{ successToastMessage: 'Image Uploaded' }
		);
		setUploading(false);
	};

	const handleRemovePicture = () => {
		withConfirmationDialog(
			() => {
				setSelectedPicture(undefined);
				onPictureRemove?.();
			},
			{ message: confirmationMessage }
		);
	};

	useEffect(() => {
		setSelectedPicture(initialPicture);
	}, [initialPicture]);

	return (
		<div className={classes.root}>
			{uploading ? <Loader /> : selectedPicture?.url ? <img className={classes.avatar} alt={'Content'} src={selectedPicture.url} /> : null}
			{selectedPicture?.url || uploading ? null : (
				<Fab disabled={uploading} color={'primary'} size={'small'}>
					<FileInput onDone={(files) => handleUpload(files)} {...fileInputProps} />
					<PhotoCameraIcon />
				</Fab>
			)}
			{selectedPicture?.url && !uploading ? (
				<Box position={'absolute'} bottom={0} left={'50%'} className={classes.delButtonContainer}>
					<SolidSmallIconButton onClick={handleRemovePicture} leftGutter={false}>
						<CloseIcon fontSize={'small'} color={'inherit'} />
					</SolidSmallIconButton>
				</Box>
			) : null}
		</div>
	);
};

const useStyles = makeStyles<Theme>((theme) => {
	return createStyles({
		root: {
			display: 'flex',
			justifyContent: 'center',
			position: 'relative',
			width: '100%',
		},
		avatar: {
			width: '100%',
			height: 'auto',
		},
		delButtonContainer: {
			position: 'absolute',
			bottom: 0,
			left: '50%',
			transform: 'translate(-10px, 10px)',
		},
	});
});

export default PictureInput;
