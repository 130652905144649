import { combineReducers } from 'redux';
import { App } from '../Models/App/Reducer';
import { RUser } from '../Models/User/Reducer';
import { RTeam } from '../Models/Team/Reducer';
import { RPost } from '../Models/Post/Reducer';
import { RTag } from '../Models/Tag/Reducer';
import { RSetting } from '../Models/Setting/Reducer';
import { RMicroCourse } from '../Models/MicroCourse/Reducer';
import { RExploreList } from '../Models/ExploreList/Reducer';
import { RExploreHome } from 'Models/ExploreHome/Reducer';
import { RPathway } from 'Models/Pathway/Reducer';

const RootReducer = combineReducers({
	App,
	Post: RPost,
	User: RUser,
	Team: RTeam,
	Tag: RTag,
	Setting: RSetting,
	MicroCourse: RMicroCourse,
	ExploreList: RExploreList,
	ExploreHome: RExploreHome,
	Pathway: RPathway
});

export type TReduxStore = ReturnType<typeof RootReducer>;

export default RootReducer;
