import React, { FC } from 'react';
import { ListDocument, ExploreList } from 'Models/ExploreList/@types';
import { ExploreHome } from 'Models/ExploreHome/@types';
import { Box, makeStyles, Theme, createStyles, Typography, IconButton, Icon, Tooltip } from '@material-ui/core';
import ListDocumentCard from './ListDocumentCard';
import { Link } from 'react-router-dom';

interface ListicleProps {
	color: ExploreHome['color'];
	dragHandleProps: any;
	exploreHomeItem: ExploreHome;
	deleteItem: (item: ExploreHome) => void;
}

const Listicle: FC<ListicleProps> = (props) => {
	const { deleteItem, exploreHomeItem, color, dragHandleProps } = props;
	const { subject: exploreList } = exploreHomeItem;
	const { name, listDocuments, isPublished } = exploreList as ExploreList;
	const classes = useStyles();

	return (
		<Box display={'flex'} maxWidth={'100%'} flexDirection={'column'} className={classes.root}>
			<Box pr={2} display={'flex'} className={classes.topContainer}>
				<Typography variant={'h5'}>{name}</Typography>
				{!isPublished ? (
					<Tooltip title="This list is unpublished. This list will not be shown on Explore.">
						<Icon className="warn">report</Icon>
					</Tooltip>
				) : null}
				<Box>
					<IconButton component={Link} size={'small'} to={`/dashboard/exploreLists/${exploreList?.id}/details`}>
						<Icon>edit</Icon>
					</IconButton>
					<IconButton onClick={() => deleteItem(exploreHomeItem)} size={'small'}>
						<Icon>delete_outline</Icon>
					</IconButton>
					<IconButton {...dragHandleProps} size={'small'}>
						<Icon>open_with</Icon>
					</IconButton>
				</Box>
			</Box>
			<Box className={classes.cardsContainer}>
				{listDocuments.map((document: ListDocument) => (
					<ListDocumentCard key={document.id} color={color} listDocument={document} />
				))}
			</Box>
		</Box>
	);
};

export default Listicle;

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		root: {
			overflowX: 'hidden',
		},
		cardsContainer: {
			display: 'flex',
			width: '100%',
			overflowX: 'auto',
		},
		topContainer: {
			'&>h5': {
				flex: 1,
			},
			'& .material-icons.warn': {
				color: theme.palette.error.main,
			},
		},
	})
);
