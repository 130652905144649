import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk';
import { withRouter, RouteComponentProps } from 'react-router';
import { TReduxStore } from '../../RootReducer';
import { TAction } from 'loop-front';
import DashboardLayout from './DashboardLayout';

interface IStateProps { }

interface IDispatchProps { }

interface IState { }

interface OwnProps { }

// eslint-disable-next-line
interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps, RouteComponentProps, OwnProps { }

class Dashboard extends Component<IProps, IState> {

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root} >
                <DashboardLayout />
            </div>
        )
    }
}

const mapStateToProps = (state: TReduxStore): IStateProps => ({

})

const mapDispatchToProps = (dispatch: ThunkDispatch<TReduxStore, {}, TAction>): IDispatchProps => ({

})

const STYLES = (theme: Theme) => createStyles({
    root: {
    }
})

export default withRouter(connect<IStateProps, IDispatchProps, OwnProps, TReduxStore>(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(Dashboard)))