import React from 'react';
import DraggableList, { TemplateProps } from 'react-draggable-list';

interface IProps<T = unknown> {
	list: T[];
	handleUpdateListOrder: (nextList: T[], movedItem: T, oldIndex: number, newIndex: number) => void;
	renderItem: (item: T, dragHandleProps: any, dragged: boolean) => React.ReactElement;
}

function ReorderableList<T extends any>(props: IProps<T>) {
	const { list } = props;
	const RenderTemplate: React.FC<TemplateProps<T, unknown>> = (categoryItemProps) => {
		let { item, itemSelected, dragHandleProps } = categoryItemProps;
		const dragged = itemSelected !== 0;
		return props.renderItem(item, dragHandleProps, dragged);
	};
	return (
		<div>
			<DraggableList
				list={list || []}
				itemKey="id"
				template={RenderTemplate as any}
				onMoveEnd={(nexList, movedItem, oldIndex, newIndex) => props.handleUpdateListOrder([...nexList], movedItem, oldIndex, newIndex)}
			/>
		</div>
	);
}

export default ReorderableList;
