import React, { FC } from 'react';
import { createStyles, Theme, ListItem, Icon, Typography, makeStyles } from '@material-ui/core';
import { TPostType } from '../../Models/App/@types';
import clsx from 'clsx';

interface CategoryButtonProps {
	isActive?: boolean;
	category: TPostType;
	onClick?: (category: TPostType) => void;
}

const CategoryButton: FC<CategoryButtonProps> = (props) => {
	const classes = useStyles();

	const { category, isActive, onClick } = props;
	const handleClick = () => {
		onClick?.(category);
	};
	let icon = '';
	switch (category.value) {
		case 'book': icon = 'book'; break;
		case 'event': icon = 'event'; break;
		case 'podcast': icon = 'radio'; break;
		case 'course': icon = 'bookmark'; break;
		case 'other': icon = 'chrome_reader_mode'; break;
	}

	return (
		<ListItem button disableGutters className={clsx((isActive ? classes.active : ''), classes.root)} onClick={handleClick} >
			<Icon>{icon}</Icon>
			<Typography variant='body2' color='textSecondary' >{category.name}</Typography>
		</ListItem>
	);

};

const useStyles = makeStyles<Theme>((theme: Theme) => createStyles({
	root: {
		justifyContent: 'center',
		transitionDuration: '300ms'
	},
	active: {
		border: `1px Solid ${theme.palette.primary.main}`,
		borderRadius: 4,
	}
}));

export default CategoryButton;