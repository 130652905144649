import { omit } from 'lodash';
import { TPathway } from './@types';

export const parseToPathway = (obj: Record<string, any>) => {
	return { ...obj, ogImage: obj._ogImage, coverImage: obj._coverImage } as TPathway;
};

export const parsePathwayForm = (obj: Partial<TPathway>) => {
	const { ogImage: _ogImage, coverImage: _coverImage } = obj;
	obj = omit(obj, ['ogImage', 'coverImage']);
	return { ...obj, _ogImage, _coverImage };
};