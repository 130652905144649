import React, { Fragment, useState } from 'react';
import RequestOnType from '../../Components/RequestOnType';
import { TLinkPreview } from '../../Models/Post/@types';
import { Box, List, ListItem, ListItemAvatar, Avatar, ListItemText, Collapse, Button, LinearProgress } from '@material-ui/core';
import LinkPreviewCard from '../../Components/Cards/LinkPreviewCard';

const PAGE_SIZE = 10;

export interface SearchBooksProps {
	onSelect: (podcastEpisode: TLinkPreview) => void;
	initialData?: TLinkPreview;
}

const SearchBooks: React.FC<SearchBooksProps> = (props) => {
	const [searchResult, setSearchResult] = useState<Array<TLinkPreview>>([]);
	const [selectedBook, setSelectedBook] = useState<TLinkPreview | undefined>(props.initialData);
	const [hasMore, setHasMore] = useState(true);
	const [loadingMore, setLoadingMore] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);

	const parseGoogleBooks = (data: any): Array<TLinkPreview> => {
		return (data.items || []).map((item: any) => {
			const { title, authors, description, infoLink, imageLinks } = item.volumeInfo;
			return { url: infoLink, image: (imageLinks || {}).thumbnail || '', title, description, author: (authors || []).join(', ') };
		});
	};

	const onResponse = React.useMemo(
		() => (res: any) => {
			pageNumber > 1 && setLoadingMore(false);
			setSearchResult([...(pageNumber === 1 ? [] : searchResult), ...parseGoogleBooks(res.data)]);
		},
		[pageNumber, searchResult]
	);

	const onChange = React.useMemo(() => {
		return () => {
			loadingMore && setLoadingMore(false);
			searchResult.length > 0 && pageNumber === 1 && setSearchResult([]);
			selectedBook && setSelectedBook(undefined);
			pageNumber > 1 && setPageNumber(1);
		};
	}, [pageNumber, searchResult, selectedBook, loadingMore]);

	const onBookClick = (item: TLinkPreview) => {
		item.image = (item.image || '').replace(/http:\/\//i, 'https://').replace(/zoom=[0-9]/i, 'zoom=4');
		props.onSelect(item);
		setSelectedBook(item);
	};

	const handleLoadMoreClick = React.useMemo(
		() => () => {
			if (searchResult.length < PAGE_SIZE * pageNumber) {
				setHasMore(false);
				return;
			}
			setLoadingMore(true);
			setPageNumber(pageNumber + 1);
		},
		[pageNumber, searchResult]
	);

	return (
		<Fragment>
			<Box my={2}>
				<RequestOnType
					queryKey={'q'}
					onResponse={onResponse}
					onChange={onChange}
					showLoading
					config={{
						url: 'volumes',
						baseURL: 'https://www.googleapis.com/books/v1/',
						params: { maxResults: PAGE_SIZE, startIndex: PAGE_SIZE * (pageNumber - 1) },
					}}
					label="Start typing to find your book"
					extraData={[pageNumber]}
				/>
			</Box>
			<Collapse in={Boolean(selectedBook)}>
				{selectedBook && (
					<LinkPreviewCard
						previewData={selectedBook}
						onChange={(data) => {
							setSelectedBook(data);
							props.onSelect(data);
						}}
					/>
				)}
			</Collapse>
			{searchResult.length > 0 && !selectedBook ? (
				<Box my={1} maxHeight={400} overflow={'auto'}>
					<List>
						{searchResult.map((item) => {
							return (
								<ListItem key={item.id} button onClick={() => onBookClick(item)}>
									<ListItemAvatar>
										<Avatar alt={item.title} src={item.image} />
									</ListItemAvatar>
									<ListItemText primary={item.title} secondary={item.author} />
								</ListItem>
							);
						})}
					</List>
					{hasMore ? (
						loadingMore ? (
							<LinearProgress />
						) : (
							<Box display={'flex'} justifyContent={'center'}>
								<Button onClick={handleLoadMoreClick}>Load More</Button>
							</Box>
						)
					) : null}
				</Box>
			) : null}
		</Fragment>
	);
};

export default SearchBooks;
