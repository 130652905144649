import LoopFront from "loop-front";



const CustomActions = {

}

const CustomEntities = {
   
}
const CustomActivities = {
    
}


class Setting extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivities> {
    constructor() {
        super('settings', CustomActions, CustomEntities, CustomActivities);
    }

}

export const OSetting = new Setting();


