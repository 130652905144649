import { TPostSort, TPostSortOrder, TPostListingFilter } from "./@types";
import dayjs from 'dayjs';

const getDateRange = (days: number | undefined, startDate?: string, endDate?: string) => {
    if (!days)
        return [dayjs(startDate).toDate(), dayjs(endDate).toDate()]

    const today = dayjs();
    const _startDate = today.subtract(days, 'day')
    return [_startDate.toDate(), today.toDate()]
}


const SORT_FONFIG: Record<TPostSort, any> = {
    comments: 'commentCount',
    created: 'created',
    likes: 'likeCount'
}
class PostUtils {
    static getOrderFilter = (sort: TPostSort, order: TPostSortOrder) => {
        return `${SORT_FONFIG[sort]} ${order}`
    }

    static buildQueryFromFilter = (appliedFilter: TPostListingFilter, filterStartDate?: string, filterEndDate?: string) => {
        let filter: Record<string, any> = {};
        let type: Record<string, any> = {}
        let inq: string[] = [];

        appliedFilter.type.forEach(t => {
            inq.push(t.value);
        });

        if (inq.length > 0) {
            type = { inq }
            filter = { ...filter, type }
        }

        if (appliedFilter.date !== 'none') {
            switch (appliedFilter.date) {
                case 'last10':
                    filter.created = { between: getDateRange(10) };
                    break;
                case 'last30':
                    filter.created = { between: getDateRange(30) };
                    break;
                case 'range':
                    filter.created = { between: getDateRange(undefined, filterStartDate, filterEndDate) };
                    break;
                case 'today':
                    filter.created = { between: getDateRange(undefined, dayjs().startOf('day').toISOString(), dayjs().endOf('day').toISOString()) };
                    break;
                case 'yesterday':
                    filter.created = { between: getDateRange(undefined, dayjs().subtract(1, 'day').startOf('day').toISOString(), dayjs().endOf('day').toISOString()) };
                    break;
            }
        }
        return filter;
    }
}


export default PostUtils;