import { createStyles, makeStyles, MenuItem, Select, Theme } from '@material-ui/core';
import React from 'react';

// interface IProps {}
export interface SelectTextFieldProps {
	config?: {
		title?: string;
		onClick?: () => void;
		onChange?: (status: boolean) => void;
		value?: string;
		flag?: boolean;
		disabled?: boolean;
		options?: Array<{
			text?: string;
			isDisabled?: boolean;
			value?: string;
		}>;
	};
}
const SelectTextField: React.FC<SelectTextFieldProps> = (props) => {
	const classes = useStyles();
	const { config } = props;

	const handleChange = async (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
		e.preventDefault();
		if (config && config.onChange) config.onChange(e.target.value === 'Approve');
	};

	return (
		<div>
			<Select value={config ? config.value : ''} onChange={handleChange} className={classes.textField}>
				{config
					? (config.options || []).map((item, index) => {
							return (
								<MenuItem key={index} value={item.value} disabled={item.isDisabled}>
									{item.text}
								</MenuItem>
							);
					  })
					: null}
			</Select>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textField: {
			width: '150px',
			height: '40px',
			margin: 5,
			border: '1',
			textAlign: 'center',
			justifyContent: 'center',
			backgroundColor: theme.palette.common.white,
		},
	})
);

export default SelectTextField;
