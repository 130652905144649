import LoopFront from 'loop-front';
import { TDispatch } from 'Models/App/@types';
import { MicroCourse as TMicroCourse } from './@types';

const CustomActivities = {
	CREATE_NEW: 'createNew',
	PUBLISH: 'publish',
	CHANGE_ORDER: 'change-order',
};

const CustomActions = {
	SHOW_MICRO_COURSE_FORM: 'SHOW_MICRO_COURSE_FORM',
	HIDE_MICRO_COURSE_FORM: 'HIDE_MICRO_COURSE_FORM',
	MICRO_COURSE_DELETED: 'MICRO_COURSE_DELETED',
	SHOW_CHAPTER_FORM: 'SHOW_CHAPTER_FORM',
	HIDE_CHAPTER_FORM: 'HIDE_CHAPTER_FORM',
	SHOW_MICRO_COURSE_GALLERY: 'SHOW_MICRO_COURSE_GALLERY',
	HIDE_MICRO_COURSE_GALLERY: 'HIDE_MICRO_COURSE_GALLERY',
	MICRO_COURSE_UPDATED: 'MICRO_COURSE_UPDATED',
};

const CustomEntities = {
	PUBLISH: 'publish',
	CHAPTER: 'chapters',
};

class MicroCourse extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivities> {
	constructor() {
		super('microCourses', CustomActions, CustomEntities, CustomActivities);
	}

	requestCreateNew = (data: TMicroCourse) => this.postActivity(this.Activites.CREATE_NEW, data);

	deleteMicroCourse = (id: string) => async (dispatch: TDispatch) => {
		const res = await LoopFront.request({ url: `microCourses/trash`, method: 'DELETE', params: { ids: [id] } });
		dispatch({ type: this.Actions.MICRO_COURSE_DELETED, data: { id } });
		return res;
	};
	getMicroCourseCount = () => async (dispatch: TDispatch) => {
		try {
			await dispatch(OMicroCourse.getActivity(OMicroCourse.Activites.COUNT));
		} catch (error) {
			throw error;
		}
	};
}

export const OMicroCourse = new MicroCourse();
