import React, { useState, useEffect } from 'react';
import LoopFront from 'loop-front';
import RootLayout from './Screens/RootLayout';
import Config from './Resources/Config';
import { useDispatch } from 'react-redux';
import { TDispatch } from './Models/App/@types';
import { OApp } from './Models/App';
import { createBrowserHistory } from 'history';
import { LinearProgress } from '@material-ui/core';
import RootProvider from 'Contexts';

export const HISTORY = createBrowserHistory();

const App: React.FC = () => {
	const dispatch = useDispatch<TDispatch>();
	const [splash, setSplash] = useState(true);
	const appInit = async () => {
		const location = window.location.pathname;
		const newLocation = !location || location === '/' ? '/dashboard' : location;
		const res = await dispatch(OApp.init()).catch((error) => {
			HISTORY.push('/');
			console.log('caught');
			setSplash(false);
		});
		res && HISTORY.push(newLocation);
		setSplash(false);
	};

	useEffect(() => {
		LoopFront.init(Config.API_BASE_URL, { log: false });
		appInit();
		// eslint-disable-next-line
	}, []);

	return (
		<RootProvider>
			{!splash ? <RootLayout /> : <LinearProgress />}
		</RootProvider>
	);
};

export default App;
