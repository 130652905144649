import { action, Action, thunk, Thunk } from 'easy-peasy';
import { Token } from 'Models/Auth/@types';
import UserModel from 'Models/User';
import { TUser } from 'Models/User/@types';
import AxiosUtils from 'Resources/AxiosUtils';
import utilities from 'Resources/Utils';
import type { TRootStore } from 'Stores';

export const COOKIES_ACCESS_TOKEN = 'access_token';
export const COOKIES_USER_ID = 'user_id';
// type StaticAction<T = void> = Action<AuthState, T>;
// type ThunkAction<T = void, R = void> = Thunk<AuthState, T,  TRootStore, Promise<R>>;

export interface AuthState {
	token: Token | null;
	user: TUser | null;
	setToken: Action<AuthState, Partial<Token> | null>;
	setUser: Action<AuthState, TUser | null>;
	setAuthInfo: Thunk<AuthState, Partial<Token>, any, TRootStore, Promise<void | TUser>>;
	removeAuthInfo: Action<AuthState>;
	loadAuthInfo: Thunk<AuthState, {} | undefined, any, TRootStore, Promise<void>>;
	login: Thunk<AuthState, { email: string; password: string }, any, TRootStore, Promise<Token>>;
	logout: Thunk<AuthState, any, any, TRootStore, Promise<void>>;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const AuthState: AuthState = {
	token: null,
	user: null,
	setToken: action((state, token) => {
		if (!token || !token.id || !token.userId) {
			state.token = null;
			return;
		}
		state.token = {
			id: token.id || '',
			userId: token.userId || '',
			ttl: token.ttl || 0,
			created: token.created || '',
		};
	}),
	setUser: action((state, user) => {
		state.user = user;
	}),
	setAuthInfo: thunk(async (actions, token) => {
		if (!token || !token.id || !token.userId) {
			return;
		}
		actions.setToken(token);
		AxiosUtils.setAuthHeader(token.id);
		const user = await UserModel.fetchMe(token.id).catch((err) => {
			localStorage.removeItem(COOKIES_ACCESS_TOKEN);
			localStorage.removeItem(COOKIES_USER_ID);
		});
		if (!user || !((user.roles.findIndex((role) => role.name === 'ADMIN') ?? -2) > -1)) {
			localStorage.removeItem(COOKIES_ACCESS_TOKEN); // Remove non admin user's details.
			localStorage.removeItem(COOKIES_USER_ID);
			utilities.clearCookies();
			return;
		}
		localStorage.setItem(COOKIES_ACCESS_TOKEN, token.id);
		localStorage.setItem(COOKIES_USER_ID, token.userId);
		return user;
	}),
	removeAuthInfo: action((state) => {
		AxiosUtils.setAuthHeader(undefined);
		localStorage.removeItem(COOKIES_ACCESS_TOKEN);
		localStorage.removeItem(COOKIES_USER_ID);
		state.token = null;
		state.user = null;
	}),
	loadAuthInfo: thunk(async (actions, args) => {
		const token = localStorage.getItem(COOKIES_ACCESS_TOKEN);
		const userId = localStorage.getItem(COOKIES_USER_ID);
		if (!token || !userId) {
			actions.removeAuthInfo();
			return;
		}
		await actions.setAuthInfo({ id: token, userId });
	}),
	login: thunk(async (actions, credentials: { email: string; password: string }) => {
		const token = await UserModel.login({ ...credentials, isWeb: true });
		await actions.setAuthInfo(token);
		return token;
	}),
	logout: thunk(async (actions, args) => {
		await UserModel.logOut().catch((err) => {});
		actions.removeAuthInfo();
	}),
};

export default AuthState;
