import React, { FC } from 'react';
import { Fab, FabProps } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface SolidSmallIconButtonProps extends Omit<FabProps, 'size' | 'onClick' | 'color'> {
	onClick?: () => void;
	leftGutter?: boolean;
	color?: 'primary' | 'secondary' | 'textPrimary' | 'textSecondary';
}

const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

const SolidSmallIconButton: FC<SolidSmallIconButtonProps> = (props) => {
	const classes = useStyles();
	const { onClick, leftGutter = true, color = 'textPrimary', ...fabProps } = props;

	const colorKey = `color${capitalize(color)}`;

	return (
		<Fab
			onClick={props.onClick}
			size={'small'}
			{...fabProps}
			className={clsx(classes.iconButtons, { [classes.leftGutter]: leftGutter }, classes[`${colorKey}`])}
		>
			{props.children}
		</Fab>
	);
};

const useStyles = makeStyles<Theme>((theme) => {
	const {
		palette: {
			primary,
			secondary,
			text: { primary: textPrimary, secondary: textSecondary },
		},
	} = theme;
	return createStyles({
		iconButtons: {
			minHeight: 21,
			height: 21,
			width: 21,
			boxShadow: 'none',
			color: '#fff',
			'& svg': {
				fontSize: 13,
			},
		},
		leftGutter: {
			marginLeft: 8,
		},

		colorPrimary: {
			backgroundColor: primary['main'],
			'&:hover': {
				backgroundColor: primary.main,
				opacity: '100%',
			},
		},
		colorSecondary: {
			backgroundColor: secondary['main'],
			'&:hover': {
				backgroundColor: secondary['main'],
				opacity: '100%',
			},
		},
		colorTextPrimary: {
			backgroundColor: textPrimary,
			'&:hover': {
				backgroundColor: primary.main,
				opacity: '100%',
			},
		},
		colorTextSecondary: {
			backgroundColor: textSecondary,
			'&:hover': {
				backgroundColor: primary.main,
				opacity: '100%',
			},
		},
	});
});

export default SolidSmallIconButton;
