import { Box, Button, Typography } from "@material-ui/core";
import React, { useContext, useState } from "react";
import useToastMessage from "./useToastMessage";
import { DialogContext } from "../Components/Dialogs/AppDialogProvider";
import Loader from "../Components/Loader";


const DEFAULT_MESSAGE = 'Confirm action?';



const useConfirmationDialog = () => {
	const { showDialog, hideDialog } = useContext(DialogContext);

	const withConfirmationDialog = (action: () => any, config: WithConfirmationDialogConfig = {}, toastConfig?: WithConfirmationDialogToastConfig) => {
		const { message } = config;
		showDialog(<div />, {
			headerProps: {
				isCloseButton: false
			},
			isActionCloseButton: false,
			PaperProps: {
				style: {
					width: 400,
					padding: 16
				},
			},
			title: message || DEFAULT_MESSAGE,

			actionsChildren: <ActionButton
				agree={action}
				hideDialog={hideDialog}
				config={config}
				toastConfig={toastConfig}
			/>
		});
	};


	return withConfirmationDialog;
};




const ActionButton: React.FC<ActionButtonProps> = (props) => {
	const {
		agree,
		config,
		toastConfig,
		hideDialog
	} = props;

	const withToast = useToastMessage();
	const [loading, setLoading] = useState(false);
	const isAsync = agree.constructor.name === 'AsyncFunction';

	const accept = async () => {
		if (isAsync) {
			setLoading(true);
			try {
				if (toastConfig) {
					await withToast(agree, toastConfig);
				} else {
					await agree();
				}
			} catch (error) {

			}
			setLoading(false);
			hideDialog();
		} else {
			if (toastConfig) {
				withToast(agree, toastConfig);
			}
			agree();
			hideDialog();
		}
	};


	return (
		<Box mr={2} display={'flex'} alignItems={'center'} >
			<Box mr={1.5} >
				<Button color={'primary'} size={'small'} disabled={loading} onClick={accept}>
					{loading! ? <Loader size={'sm'} /> : <Typography color={'inherit'} >{config.agreeText || 'YES'}</Typography>}
				</Button>
			</Box>
			<Button size={'small'} disabled={loading} variant="contained" color="primary" onClick={hideDialog}>{
				<Typography color={'inherit'} style={{ textTransform: 'capitalize' }} >{config.cancelText || 'Cancel'}</Typography>
			}</Button>
		</Box>
	);
};



export interface WithConfirmationDialogConfig {
	message?: string;
	agreeText?: string;
	cancelText?: string;
}

export interface WithConfirmationDialogToastConfig {
	successToastMessage?: string;
	errorToastMessage?: string;
}

type ActionButtonProps = {
	agree: (() => void | Promise<void>),
	config: WithConfirmationDialogConfig,
	toastConfig?: WithConfirmationDialogToastConfig,
	hideDialog: () => void;
};

export default useConfirmationDialog;