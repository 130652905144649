import { TDispatch } from 'Models/App/@types';
import LoopFront from 'loop-front';
import { OExploreHome } from '.';
import { ExploreHome, ExploreHomeState } from './@types';

export const getHomeDataAction = (tagId: string, params?: Record<string, any>, updateExistingList?: boolean) => {
	return async (dispatch: TDispatch) => {
		const res = await LoopFront.request({
			url: 'exploreHomes/get-data',
			params: {
				...params,
				filter: {
					...params?.filter,
					include: { relation: 'subject' },
					order: 'order ASC',
					where: { tagId },
				},
				exploreHomeSubjectFilter: {
					...params?.exploreHomeSubjectFilter,
					ExploreList: { include: { relation: 'list-documents', scope: { include: 'subject' } } },
				},
			},
			method: 'GET',
		});
		if (res.data) {
			dispatch({ type: OExploreHome.Actions.LIST_RECEIVED, data: { data: res.data, updateExistingList } });
		}
	};
};

export const reorderExploreHomeItems = (id: string, newIndex: number, finalList: ExploreHome[]) => {
	return async (dispatch: TDispatch) => {
		dispatch({ type: OExploreHome.Actions.CHANGE_ORDER, data: finalList });
		/* const res = */ await LoopFront.request({
			url: 'exploreHomes/change-order',
			data: { id, order: newIndex + 1 },
			method: 'PATCH',
		});
		// if (res) dispatch({ type: OExploreHome.Actions.CHANGE_ORDER, data: finalList });
	};
};

export const trashExploreHomeItem = (ids: string[]) => {
	return async (dispatch: TDispatch, getState: any) => {
		const state: { ExploreHome: ExploreHomeState } = getState();
		const res = await LoopFront.request({
			url: 'exploreHomes/trash',
			data: { ids },
			method: 'DELETE',
		});
		if (res) {
			dispatch({ type: OExploreHome.Actions.DELETE_LIST_ITEMS, data: state.ExploreHome.list.filter((item) => !ids.includes(item.id)) });
		}
	};
};
