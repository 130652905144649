import React, { FC } from "react";
import { TPostType } from "Models/App/@types";
import { IFieldProps } from "react-forms";
import { TLinkPreview } from "Models/Post/@types";
import { Box } from "@material-ui/core";
import FetchUrlData from "features/post/FetchUrlData";
import SearchPodcast from "features/post/SearchPodcast";
import SearchBooks from "features/post/SearchBooks";
import { FormikProps, FormikState } from "formik";

interface FetchPostFieldProps {
	postTypes: TPostType[];
}

interface FetchPostProps extends IFieldProps {
	fieldProps?: FetchPostFieldProps;
}

const FetchPost: FC<FetchPostProps> = (props) => {
	const { fieldProps = {} as FetchPostFieldProps, formikProps = {} as FormikProps<any> } = props;
	const { postTypes = [] } = fieldProps;
	const onPostSelect = (post: Partial<TLinkPreview>) => {
		formikProps.setFormikState((prevState: FormikState<any>) => ({ ...prevState, values: { ...formikProps.values, ...post } }));
	};

	return <Box my={2}>
		{postTypes
			.map((scope) => scope.value)
			.filter((item) => item !== 'podcast' && item !== 'book')
			.find((item) => item === formikProps.values.type) ? (
			<FetchUrlData initialPost={formikProps.values as TLinkPreview} onSelect={onPostSelect} />
		) : null}
		{formikProps.values.type === 'podcast' ? <SearchPodcast initialData={formikProps.values as TLinkPreview} onSelect={onPostSelect} /> : null}
		{formikProps.values.type === 'book' ? <SearchBooks initialData={formikProps.values as TLinkPreview} onSelect={onPostSelect} /> : null}
	</Box>;
};

export default FetchPost;