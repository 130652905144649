import { FormikProps } from "formik";
import { uploadAsset } from "Models/Asset";
import React, { FC } from "react";
import { IFieldProps, MUIFileInput } from "react-forms";
import { Box, Button, CircularProgress, IconButton, InputLabel, Typography } from "@material-ui/core";
import useAsyncTask from "Hooks/useAsyncTask";
import _get from "lodash/get";
import CancelIcon from '@material-ui/icons/Cancel';

interface UploadFileFieldProps {
	name?: string;
	label?: string;
	buttonLabel?: JSX.Element | string;
}

interface UploadFileProps extends IFieldProps {
	fieldProps?: UploadFileFieldProps;
}


const UploadFile: FC<UploadFileProps> = (props) => {
	const { fieldProps = {} as UploadFileFieldProps, formikProps = {} as FormikProps<any> } = props;
	const { name = '', label = 'Upload File', buttonLabel = 'UPLOAD' } = fieldProps;
	const fileUploadTask = useAsyncTask(uploadAsset);
	const value = _get(formikProps, `values.${name}`);
	const wrapWith = (input: JSX.Element) => {
		return <>
			<InputLabel shrink>{label}</InputLabel>
			<Button> {buttonLabel}{input}</Button>
		</>;
	};
	const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files?.[0]) {
			const file = await fileUploadTask.run(files[0]);
			formikProps.setFieldValue(name, file);
		}
		// files && processFilesWithCallback(files, (prop: { imgs: TFile[]; rem: any[]; }) => { fileUploadTask.run(prop.rem); });
	};
	const removeItem = () => {
		formikProps.setFieldValue(name, undefined);
	};
	return <> {
		fileUploadTask.status === 'PROCESSING' ?
			<CircularProgress /> :
			!value?.[0]?.name ? <MUIFileInput fieldProps={{
				accept: 'application/pdf',
				name,
				nativeInputProps: { onChange: handleChange, },
				wrapWith
			}} formikProps={formikProps} />
				: <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
					<Typography variant={'subtitle1'}>{value[0].name}</Typography>
					<IconButton onClick={removeItem}><CancelIcon /></IconButton>
				</Box>}
	</>;
};

export default UploadFile;