import { omit } from 'lodash';
import { parsePost } from '../Post/PostParsers';
import { MicroCourse } from './@types';

export const parseMicroCourseForm = (data: Partial<MicroCourse>) => {
	const { curators, coverImage: _coverImage, relatedMicroCourses, ogImage: _ogImage, endorsedByCompany, endorsedByCompanyId, ...rest } = data;
	data = omit(data, ['relatedMicroCourses', 'ogImage', 'coverImage', 'endorsedByCompanyId', 'endorsedByCompany']);
	return {
		...rest,
		_coverImage,
		_ogImage,
		endorsedByCompanyId: endorsedByCompany?.id,
		...(relatedMicroCourses ? { relatedMicroCourseIds: relatedMicroCourses.map((val: MicroCourse) => val.id) } : {}),
	};
};

export const parseMicroCourse = (data: Record<string, any>): MicroCourse => {
	const { isPublished, _coverImage, _ogImage, ...rest } = data;
	return {
		...rest,
		isPublished: !!isPublished,
		coverImage: _coverImage,
		ogImage: _ogImage,
		...(data.chapters ? { chapters: data.chapters.map(parsePost) } : {}),
	} as MicroCourse;
};
