import _ from "lodash";
import LoopFront from "loop-front";
import { TReduxStore } from "../../RootReducer";
import { ITEM_LIMIT, STATIC_POST_FILTER } from "../../Screens/Posts";
import { TDispatch } from "../App/@types";
import {
  TDateOptions, TPostFilterKey,
  TPostListingFilter, TPostSort,
  TPostSortOrder
} from "./@types";
import PostUtils from "./PostUtils";

const CustomActions = {
  USERS_POST_RECEIVED: "USERS_POST_RECEIVED",
  POST_UNLIKE_SUCCESS: "POST_UNLIKE_SUCCESS",
  POST_LIKE_SUCCESS: "POST_LIKE_SUCCESS",
  BOOKMARK_SUCCESS: "BOOKMARK_SUCCESS",
  BOOKMARK_DELETE_SUCCESS: "BOOKMARK_DELETE_SUCCESS",
  SINGLE_POST_RECEIVED: "SINGLE_POST_RECEIVED",
  SHOW_FORM: "SHOW_FORM",
  HIDE_FORM: "HIDE_FORM",
  POSTS_LOADING: "POSTS_LOADING",
  POST_SET_SORT_ORDER: "POST_SET_SORT_ORDER",
  POST_SET_SORT: "POST_SET_SORT",
  POST_RESET_SORTING: "POST_RESET_SORTING",
  POST_APPLY_SORTING: "POST_APPLY_SORTING",
  POST_SET_LISTING_FILTER_TYPE: "POST_SET_LISTING_FILTER_TYPE",
  APPLY_SELECTED_POST_FILTERS: "APPLY_SELECTED_POST_FILTERS",
  CLEAR_POST_LISTING_FILTERS: "CLEAR_POST_LISTING_FILTERS",
  POST_SET_LISTING_FILTER_DATE: "POST_SET_LISTING_FILTER_DATE",
  POST_FILTER_SET_DATE: "POST_FILTER_SET_DATE",
  SET_EDITED_POST: "SET_EDITED_POST",
};

const CustomActivities = {
  FEED: "feed",
  PREVIEW_LINK: "preview-link",
  CREATE: "create",
  LIKE: "like",
  COMMENT: "comment",
  BOOKMARK: "bookmark",
  DELETE: "delete",
  CREATE_CHAPTER: "create-chapter"
};

const CustomEntities = {
  LIKERS: "likers",
  COMMENTERS: "commenters",
  BOOKMARKERS: "bookmarkers",
};

class Post extends LoopFront<
  typeof CustomActions,
  typeof CustomEntities,
  typeof CustomActivities
  > {
  constructor() {
    super("posts", CustomActions, CustomEntities, CustomActivities);
  }


  dateOptions: Array<{ label: string; key: TDateOptions }> = [
    { label: "Today", key: "today" },
    { label: "Yesterday", key: "yesterday" },
    { label: "Last 10 days", key: "last10" },
    { label: "Last 30 days", key: "last30" },
    { label: "Range", key: "range" },
  ];

  sortOptions: Array<{ label: string; key: TPostSort }> = [
    { label: "Created", key: "created" },
    { label: "Likes", key: "likes" },
    { label: "Comments", key: "comments" },
  ];
  sortOrderOptions: Array<{
    label: string;
    key: TPostSortOrder;
    altLabel: string;
    icon: string;
  }> = [
      {
        label: "Oldest",
        key: "ASC",
        altLabel: "Low to High",
        icon: "arrow_downward",
      },
      {
        label: "Latest",
        key: "DESC",
        altLabel: "High to Low",
        icon: "arrow_upward",
      },
    ];

  requestLikers = (userId: string, params?: any) =>
    this.requestGetEntityByItem(userId, this.Entities.LIKERS, params);
  requestCommenters = (userId: string, params?: any) =>
    this.requestGetEntityByItem(userId, this.Entities.COMMENTERS, params);
  requestBookmarkers = (userId: string, params?: any) =>
    this.requestGetEntityByItem(userId, this.Entities.BOOKMARKERS, params);

  setPostDateFilter = (type: "start" | "end", value: string) => (
    dispatch: TDispatch
  ) => {
    dispatch({
      type: this.Actions.POST_FILTER_SET_DATE,
      data: {
        type,
        value,
      },
    });
  };

  setFilter = (filter: TPostFilterKey, value: any) => (dispatch: TDispatch) => {
    if (filter === "type")
      return dispatch({
        type: this.Actions.POST_SET_LISTING_FILTER_TYPE,
        data: value,
      });
    if (filter === "date")
      return dispatch({
        type: this.Actions.POST_SET_LISTING_FILTER_DATE,
        data: value,
      });
  };

  applySelectedFilter = (appliedFilter?: TPostListingFilter, sort?: TPostSort, sortOrder?: TPostSortOrder) => async (
    dispatch: TDispatch,
    getState: () => TReduxStore
  ) => {
    if (!appliedFilter) appliedFilter = getState().Post.listingFilter;
    const { filterStartDate, filterEndDate } = getState().Post;
    const whereQuery = PostUtils.buildQueryFromFilter(
      appliedFilter,
      filterStartDate,
      filterEndDate
    );
    if (!sort) sort = getState().Post.appliedSort;
    if (!sortOrder) sortOrder = getState().Post.appliedSortOrder;
    dispatch({
      type: this.Actions.POSTS_LOADING,
      data: true,
    });
    try {
      await dispatch(
        this.getItemsList({
          filter: {
            limit: _.isEmpty(whereQuery) ? 0 : ITEM_LIMIT,
            skip: 0,
            ...STATIC_POST_FILTER,
            where: {
              deleted: false,
              ...PostUtils.buildQueryFromFilter(
                appliedFilter,
                filterStartDate,
                filterEndDate
              )
            },
            order: PostUtils.getOrderFilter(sort, sortOrder),
          },
        })
      );
    } catch (error) { }
    dispatch({
      type: this.Actions.APPLY_SELECTED_POST_FILTERS,
      data: appliedFilter,
    });
    // try {
    //   await dispatch(
    //     this.getItemsList({
    //       filter: {
    //         limit: _.isEmpty(whereQuery)? 0 : ITEM_LIMIT,
    //         skip:0,
    //         ...STATIC_POST_FILTER,
    //         where: {
    //           deleted: false,
    //           whereQuery
    //         },
    //       },
    //     })
    //   );
    // } catch (error) {}
    dispatch({
      type: this.Actions.POSTS_LOADING,
      data: false,
    });
  };

  resetFilter = () => async (dispatch: TDispatch) => {
    dispatch({
      type: this.Actions.CLEAR_POST_LISTING_FILTERS,
    });
    dispatch({
      type: this.Actions.POSTS_LOADING,
      data: true,
    });
    try {
      await dispatch(
        this.getItemsList({
          filter: {
            ...STATIC_POST_FILTER,
            limit: 20,
          },
        })
      );
    } catch (error) { }
    dispatch({
      type: this.Actions.POSTS_LOADING,
      data: false,
    });
  };

  setSort = (appliedFilter: TPostListingFilter, sort: TPostSort) => (dispatch: TDispatch) => {
    dispatch({
      type: this.Actions.POST_SET_SORT,
      data: sort,
    });
    dispatch(this.applySorting(appliedFilter, sort, undefined));
  };

  setSortOrder = (appliedFilter: TPostListingFilter, sortOrder: TPostSortOrder) => (dispatch: TDispatch) => {
    dispatch({
      type: this.Actions.POST_SET_SORT_ORDER,
      data: sortOrder,
    });
    dispatch(this.applySorting(appliedFilter, undefined, sortOrder));
  };

  applySorting = (appliedFilter?: TPostListingFilter, sort?: TPostSort, sortOrder?: TPostSortOrder) => async (
    dispatch: TDispatch,
    getState: () => TReduxStore
  ) => {
    if (!appliedFilter) appliedFilter = getState().Post.listingFilter;
    const { filterStartDate, filterEndDate } = getState().Post;
    if (!sort) sort = getState().Post.appliedSort;
    if (!sortOrder) sortOrder = getState().Post.appliedSortOrder;

    dispatch({
      type: this.Actions.POSTS_LOADING,
      data: true,
    });
    try {
      await dispatch(
        this.getItemsList({
          filter: {
            limit: ITEM_LIMIT,
            skip: 0,
            ...STATIC_POST_FILTER,
            where: {
              deleted: false,
              ...PostUtils.buildQueryFromFilter(
                appliedFilter,
                filterStartDate,
                filterEndDate
              )
            },
            order: PostUtils.getOrderFilter(sort, sortOrder),
          },
        })
      );
    } catch (error) { }
    dispatch({
      type: this.Actions.POST_APPLY_SORTING,
      data: appliedFilter,
    });
    dispatch({
      type: this.Actions.POSTS_LOADING,
      data: false,
    });
    // await dispatch(
    //   this.getItemsList({
    //     filter: {
    //       limit: _.isEmpty(whereQuery)? 0 : ITEM_LIMIT,
    //       skip:0,
    //       ...STATIC_POST_FILTER,
    //       where: {
    //          deleted: false,
    //          whereQuery
    //         },
    //       order: PostUtils.getOrderFilter(sort, sortOrder),
    //     },
    //   })
    // );
    // dispatch({
    //   type: this.Actions.POSTS_LOADING,
    //   data: false,
    // });
  };

  resetSorting = () => async (dispatch: TDispatch) => {
    dispatch({
      type: this.Actions.POST_RESET_SORTING,
    });
    dispatch({
      type: this.Actions.POSTS_LOADING,
      data: true,
    });
    await dispatch(
      this.getItemsList({
        filter: {
          limit: ITEM_LIMIT,
          ...STATIC_POST_FILTER,
          where: { deleted: false },
          order: PostUtils.getOrderFilter("created", "DESC"),
        },
      })
    );
    dispatch({
      type: this.Actions.POSTS_LOADING,
      data: false,
    });
  };
}

export const OPost = new Post();
