
import LoopFront from "loop-front";
import { TFile } from "../../Components/FileInput";
import { TPicture } from "./@types";

class PictureModel {
    static upload = async (pictureObject: TFile, fileName?: string, folderName?: string, setProgress?: (progress: number) => any) => {
        const { base64, name } = pictureObject;
        const res = await LoopFront.request({
            url: 'pictures/upload',
            data: { base64img: base64, filename: fileName || name, folder: folderName || 'default' },
            method: 'POST',
            onUploadProgress: (progressEvent: any) => {
                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                setProgress && setProgress(Math.floor(progress));

            }
        }).catch(error => { throw error });
        return res.data;
    }

    static getPictureUrl = (picture?: TPicture | null, width: number = 800) => picture ? `${picture.url}?tr=w-${width}` : ''

    static getPictureThumbnail = (picture?: TPicture | null) => picture ? picture.thumbnailUrl || picture.thumbnail : ''


    // static uploadMulti = (files: TFile[], entityImagesFolder: string, onEachUpload?: any, setProgress?: (progress: number) => any) => {
    //     return utilities.sequentialPromises(files,
    //         (fileItem) => PictureModel.upload(fileItem, fileItem.name, entityImagesFolder, setProgress),
    //         onEachUpload
    //     );

    // }
}

export default PictureModel