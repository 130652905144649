import _ from 'lodash';
import { TUser, TUserListingFilter } from "./@types";

class UserUtils {
	static buildQueryFromFilter = (appliedFilter: TUserListingFilter) => {
		let filter: Record<string, any> = {};
		// let and: any[] = [];
		// let status: any[] = [];
		// let cityIds: any[] = [];
		let allUsers: boolean = false;
		let appliedKeys: Record<string, number> = {};

		if (appliedFilter.status.length > 0) {
			_.forEach(appliedFilter.status, (s) => {
				if (s.label === 'All') allUsers = true;
				else {
					filter[s.name] = s.value;
					appliedKeys[s.name] = (appliedKeys[s.name] || 0) + 1;
				}
			});
		}

		if (allUsers) filter = _.omit(filter, ['isApproved', 'ban']);
		else {
			Object.keys(appliedKeys).forEach((k) => {
				if (appliedKeys[k] > 1) filter = _.omit(filter, k);
			});
		}
		return filter;
	};
	static getUserFullName = (user?: TUser) => {
		if (!user) return '';
		if (user.firstName) return `${user.firstName ?? ''} ${user.lastName ?? ''}`;
		if (user.name) return user.name;
		return '';
	};
}

export default UserUtils;