import LoopFront from 'loop-front';
import { TDispatch } from 'Models/App/@types';
import { TPathwayStep } from 'Models/PathwayStep/@types';



const CustomActions = {
	SHOW_FORM: "SHOW_FORM",
	HIDE_FORM: "HIDE_FORM",

	SHOW_PATHWAY_STEP_FORM: "SHOW_PATHWAY_STEP_FORM",
	HIDE_PATHWAY_STEP_FORM: "HIDE_PATHWAY_STEP_FORM",

	PATHWAYS_NEW_PATHWAY: "PATHWAYS_NEW_PATHWAY",
	PATHWAYS_UPDATE_PATHWAY: "PATHWAYS_UPDATE_PATHWAY",

	DELETING_PATHWAY_STEP: "DELETING_PATHWAY_STEP",
	PATHWAY_STEP_DELETED: "PATHWAY_STEP_DELETED",
	PATHWAY_STEP_DELETE_FAILED: "PATHWAY_STEP_DELETE_FAILED",

	REORDERING_PATHWAY_STEPS: "REORDERING_PATHWAY_STEPS",
	PATHWAY_STEPS_REORDER_SUCCESS: "PATHWAY_STEPS_REORDER_SUCCESS",
	PATHWAY_STEPS_REORDER_FAIL: "PATHWAY_STEPS_REORDER_FAIL"
};

const CustomActivites = {
	COUNT: 'count',
	PUBLISH: 'publish'
};

const CustomEntities = {
	PATHWAY_STEPS: 'pathwaySteps'
};


class Pathway extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivites> {
	constructor() {
		super('pathways', CustomActions, CustomEntities, CustomActivites);
	}

	deletePathwayStep = (id: string, pathwayStepId: string) => async (dispatch: TDispatch) => {
		try {
			dispatch({ type: this.Actions.DELETING_PATHWAY_STEP })
			const { data } = await LoopFront.request({
				url: `/${this.ModelName}/${id}/${this.Entities.PATHWAY_STEPS}/${pathwayStepId}`,
				method: 'delete'
			})
			dispatch({ type: this.Actions.PATHWAY_STEP_DELETED, data, additionalDispatchData: { id, pathwayStepId: pathwayStepId, } })
		} catch (error) {
			dispatch({ type: this.Actions.PATHWAY_STEP_DELETE_FAILED })
			throw error;
		}
	}

	reorderPathwaySteps = (pathwayId: string, updatedPathwaySteps: TPathwayStep[]) => async (dispatch: any) => {
		dispatch({ type: this.Actions.REORDERING_PATHWAY_STEPS, data: updatedPathwaySteps })
		try {
			await this.requestPatchItem(pathwayId, { pathwayStepIds: updatedPathwaySteps.map(p => p.id) })
			dispatch({ type: this.Actions.PATHWAY_STEPS_REORDER_SUCCESS, data: { pathwayId, updatedPathwaySteps } })
		} catch (error) {
			dispatch({ type: this.Actions.PATHWAY_STEPS_REORDER_FAIL })
			throw error;
		}
	}


}

export const OPathway = new Pathway();
