import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { Component } from 'react';
import Loader from '../Loader';

export interface ConfirmationDialogProps {
	open: boolean;
	onClose?: () => void;
	title?: string;
	message?: string;
	onAgree?: () => void;
	onDisagree?: () => void;
	loading?: boolean;
}

export default class ConfirmationDialog extends Component<ConfirmationDialogProps> {
	render() {
		const { open, title, message, loading } = this.props;
		return (
			<div>
				<Dialog open={open} keepMounted onClose={this.props.onClose}>
					<DialogTitle>{title || 'Confirmation'}</DialogTitle>
					<DialogContent>
						<Typography>{message}</Typography>
					</DialogContent>
					{loading ? (
						<Loader />
					) : (
						<DialogActions>
							<Button onClick={this.props.onAgree}>Yes</Button>
							<Button variant="contained" onClick={this.props.onDisagree} color="primary">
								No
							</Button>
						</DialogActions>
					)}
				</Dialog>
			</div>
		);
	}
}
