import { Box, Collapse } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import LinkPreviewCard from '../../Components/Cards/LinkPreviewCard';
import RequestOnType from '../../Components/RequestOnType';
import { TLinkPreview } from '../../Models/Post/@types';
import Config from '../../Resources/Config';
import utilities from '../../Resources/Utils';

export interface FetchUrlDataProps {
	onSelect?: (item: TLinkPreview) => void;
	initialPost?: TLinkPreview;
}

const FetchUrlData: React.FC<FetchUrlDataProps> = (props) => {
	const { onSelect, initialPost } = props;

	const [linkPreview, setLinkPreview] = useState<TLinkPreview | undefined>(initialPost);

	const onResponse = (res: any) => {
		setLinkPreview(res.data);
	};
	// const onChange = (text: string) => { setLinkPreview(undefined); };

	useEffect(() => {
		if (linkPreview) {
			onSelect?.(linkPreview);
		}
		// eslint-disable-next-line
	}, [linkPreview]);

	return (
		<div>
			<Box my={2}>
				<RequestOnType
					queryKey={'url'}
					validations={['URL']}
					onResponse={onResponse}
					showLoading
					config={{
						url: 'posts/preview-link',
						headers: { Authorization: utilities.getAccessToken() },
						baseURL: Config.API_BASE_URL,
					}}
					// onChange={onChange}
				/>
			</Box>
			<Box my={1}>
				{linkPreview ? (
					<Collapse in={Boolean(linkPreview)}>
						<LinkPreviewCard previewData={linkPreview} onChange={setLinkPreview} />
					</Collapse>
				) : null}
			</Box>
		</div>
	);
};

export default FetchUrlData;
