import { request } from 'Resources/AxiosUtils';
import { JSONType, Params } from 'typings/global';
import { Company, CompanyListicle } from './@types';
import { parseToCompany, parseToCompanyListicle } from './CompanyParsers';

class CompanyModel {
	static getAllCompanies = async (params?: Params): Promise<Company[]> => {
		const res = await request<JSONType[]>({
			url: '/companies',
			method: 'GET',
			params,
		});
		return res.map(parseToCompany);
	};

	static getCompany = async (id: string, params?: Params): Promise<Company> => {
		const res = await request<JSONType>({
			url: `/companies/${id}`,
			method: 'GET',
			params,
		});
		return parseToCompany(res);
	};

	static createCompany = async (companyData: Partial<Company>) => {
		const res = await request<JSONType>({
			url: `/companies/createNewC`,
			method: 'POST',
			data: companyData,
		});
		return parseToCompany(res);
	};

	static updateCompany = async (id: string, companyData: Partial<Company>) => {
		const res = await request<JSONType>({
			url: `/companies/${id}`,
			method: 'PATCH',
			data: companyData,
		});
		return parseToCompany(res);
	};

	static getCount = async (params?: Params): Promise<number> => {
		const { count } = await request<{ count: number }>({
			url: '/companies/count',
			method: 'GET',
			params,
		});
		return count;
	};

	static deleteCompany = async (id: string): Promise<{ count: number }> => {
		return request<{ count: number }>({
			url: `/companies/${id}`,
			method: 'DELETE',
		});
	};

	static deleteCompanyListicle = async (id: string): Promise<{ count: number }> => {
		return request<{ count: number }>({
			url: `/companyListicles/${id}`,
			method: 'DELETE',
		});
	};

	static getCompanyListicles = async (params?: Params): Promise<CompanyListicle[]> => {
		const res = await request<JSONType[]>({
			url: 'companyListicles',
			method: 'GET',
			params,
		});
		return res.map(parseToCompanyListicle);
	};

	static createCompanyListicle = async (data: Partial<CompanyListicle>): Promise<CompanyListicle> => {
		const res = await request<JSONType>({
			url: 'companyListicles',
			method: 'POST',
			data,
		});
		return parseToCompanyListicle(res);
	};

	static updateCompanyListicle = async (id: string, companyData: Partial<Company>) => {
		const res = await request<JSONType>({
			url: `/companyListicles/${id}`,
			method: 'PATCH',
			data: companyData,
		});
		return parseToCompanyListicle(res);
	};

	static getCompanyListicle = async (id: string, params?: Params): Promise<CompanyListicle> => {
		const res = await request<JSONType>({
			url: `companyListicles/${id}`,
			method: 'GET',
			params,
		});
		return parseToCompanyListicle(res);
	};

	static getCompanyListiclesCount = async (params?: Params): Promise<number> => {
		const { count } = await request<{ count: number }>({
			url: '/companyListicles/count',
			method: 'GET',
			params,
		});
		return count;
	};

	static changePublishCompanyListicle = async (id: string, publish: boolean): Promise<CompanyListicle> => {
		const res = request<CompanyListicle>({
			url: `companyListicles/${id}/publish`,
			method: 'POST',
			data: { publish },
		});
		return parseToCompanyListicle(res);
	};
}

export default CompanyModel;
