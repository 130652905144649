import { Reducer } from "redux";
import { TPostState } from "./@types";
import { TAction } from "loop-front";
import { OPost } from ".";
import _ from 'lodash';
import utilities from "../../Resources/Utils";
import { parsePost } from "./PostParsers";

const InitialState: TPostState = {
	showMainForm: false,
	list: [],
	sort: 'created',
	loading: false,
	sortOrder: 'DESC',
	appliedSort: 'created',
	appliedSortOrder: 'DESC',
	listingFilter: {
		type: [],
		date: 'none'
	},
	currentAppliedFilter: {
		type: [],
		date: 'none'
	},
	editedPost: undefined,
};



export const RPost: Reducer<TPostState, TAction> = (state = InitialState, action) => {

	const {
		SHOW_FORM,
		HIDE_FORM,
		LIST_RECEIVED,
		ITEM_PATCH_SUCCESS,
		POST_SET_SORT,
		POST_SET_SORT_ORDER,
		POSTS_LOADING,
		POST_APPLY_SORTING,
		POST_RESET_SORTING,
		CLEAR_POST_LISTING_FILTERS,
		APPLY_SELECTED_POST_FILTERS,
		POST_SET_LISTING_FILTER_TYPE,
		POST_SET_LISTING_FILTER_DATE,
		POST_FILTER_SET_DATE,
		SET_EDITED_POST
	} = OPost.Actions;


	switch (action.type) {
		case OPost.Actions.ITEM_ACTIVITY_DELETE_SUCCESS: {
			switch (action.activity) {
				case OPost.Activites.DELETE:
					return { ...state, list: utilities.updateItemList(state.list || [], action.additionalDispatchData.post, 'DELETE') };
				default: return { ...state };
			}
		}
		case CLEAR_POST_LISTING_FILTERS: {
			return { ...state, listingFilter: { type: [], date: 'none' }, currentAppliedFilter: { type: [] } };
		}
		case APPLY_SELECTED_POST_FILTERS: {
			return { ...state, currentAppliedFilter: action.data };
		}
		case POST_SET_LISTING_FILTER_TYPE: {
			const curFilter = state.listingFilter.type;
			let type: any;
			if (_.find(curFilter, action.data))
				type = curFilter.filter(f => f.label !== _.get(action.data, 'label'));
			else
				type = [...curFilter, action.data];

			return { ...state, listingFilter: { ...state.listingFilter, type } };
		}
		case POST_FILTER_SET_DATE: {
			const { type, value } = action.data;
			if (type === 'start')
				return { ...state, filterStartDate: value };
			if (type === 'end')
				return { ...state, filterEndDate: value };
			return { ...state };
		}
		case POST_SET_LISTING_FILTER_DATE: {
			return { ...state, listingFilter: { ...state.listingFilter, date: action.data === state.listingFilter.date ? 'none' : action.data } };
		}
		case POSTS_LOADING: return { ...state, loading: action.data };
		case POST_APPLY_SORTING: return { ...state, appliedSort: state.sort, appliedSortOrder: state.sortOrder };
		case POST_RESET_SORTING:
			return {
				...state, sort: InitialState.sort, sortOrder: InitialState.sortOrder, appliedSort: InitialState.sort, appliedSortOrder: InitialState.sortOrder
			};
		case POST_SET_SORT: return { ...state, sort: action.data };
		case POST_SET_SORT_ORDER: return { ...state, sortOrder: action.data };
		case ITEM_PATCH_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE') };
		case LIST_RECEIVED: return { ...state, list: action.data.map(parsePost) };
		case SHOW_FORM: return { ...state, showMainForm: true };

		case HIDE_FORM: return { ...state, showMainForm: false };
		case SET_EDITED_POST: return { ...state, editedPost: action.data };

		default: return state;
	}
};