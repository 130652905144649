import { useContext } from 'react';
import { ToastContext } from '../Components/Toast/AppToast';

const SUCCESS_TOAST_MESSAGE = 'Success';
const ERROR_TOAST_MESSAGE = 'Something went wrong';

const useToastMessage = () => {
	const { showToast } = useContext(ToastContext);

	const withToast = async (action: () => any, toastConfig: WithToastConfig = {}) => {
		const { successToastMessage = SUCCESS_TOAST_MESSAGE, showSuccessMessage = true } = toastConfig;

		try {
			const res = await action();
			successToastMessage && showSuccessMessage && showToast(toastConfig.successToastMessage || SUCCESS_TOAST_MESSAGE, { variant: 'success' });
			return res;
		} catch (error) {
			const msg =
				toastConfig.showApiErrorMsg === false
					? toastConfig.errorToastMessage || ERROR_TOAST_MESSAGE
					: error?.response?.data?.error?.message || error.message;
			showToast(typeof msg === 'string' ? msg : toastConfig.fallbackError || 'Something went wrong', { variant: 'error' });
			throw error;
		}
	};

	return withToast;
};

export interface WithToastConfig {
	successToastMessage?: string;
	errorToastMessage?: string;
	showApiErrorMsg?: boolean;
	showSuccessMessage?: boolean;
	fallbackError?: string;
}

export default useToastMessage;