import { JSONType } from 'typings/global';
import { Job } from './@types';

export const parseToJobListing = (obj: JSONType): Job => {
	return { ...obj } as Job;
};

export const parseJobForm = (obj: Partial<Job>): JSONType => {
	const { microCourses, ...rest } = obj;
	return { ...rest, microCourseIds: microCourses?.map((microCourse) => microCourse.id) ?? [] };
};
