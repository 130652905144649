
import utilities from '../../Resources/Utils';

class TagCore {

    static filterTags = (tagfilter: Record<string, any>) => utilities.request({
        url: `tagcategories`,
        params: {
            filter: {
                include: {
                    relation: 'tags',
                    scope: {
                        ...tagfilter
                    }
                }
            }
        }
    })
}


export default TagCore