import { TUser } from './@types';
import { TMilestone } from './@types';
import { omit } from 'lodash';

export const parseUserFormData = (data: Partial<TUser>) => {
	const res = omit({ ...data, _companyLogo: data.companyLogo, _profileImage: data.profileImage }, [
		'profileImage',
		'companyLogo',
		'repeat-password',
	]);
	return res;
};
export const parseUser = (obj: Record<string, any>): TUser => {
	return { ...obj, milestones: obj._milestones, companyLogo: obj._companyLogo, profileImage: obj._profileImage } as TUser;
};

export const parseMilestoneForm = (obj: Partial<TMilestone>): Record<string, any> => {
	return { ...obj } as TMilestone;
};
