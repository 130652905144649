import { createMuiTheme } from "@material-ui/core";

const MuiTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#727fff',
			light: '#bab8ff',
			contrastText: "#fff",
		},
		secondary: {
			main: '#4EBC95',
			light: '#A6DDCA',
			contrastText: "#fff"
		},
		grey: {
			"100": '#2A2A2A',
			"200": "#6B738E",
			"300": "#AEB4C5",
			"400": "#D0D4E1",
			"500": "#EAEBEF",
			"600": "#868B9B",
			"700": "#F2F2F2",
			"800": "#49516b",
			"900": "#898989"
		},
		text: {
			primary: "#2a2a2a",
			secondary: "#484848"
		},
		background: {
			default: "#F4F5FE",
			paper: "#fff"
		},
		common: {
			black: '#000',
			white: '#fff'
		},
		error: {
			main: '#FF0000'
		}
	},
	typography: {
		fontWeightBold: 700,
		fontFamily: 'Lato',
	},

	overrides: {
		MuiFab: {
			root: {
				backgroundColor: '#fff'
			}
		},
		MuiCheckbox: {
			root: {
				padding: 0
			}
		},
		MuiTooltip: {
			tooltip: {
				color: '#2a2a2a'
			}
		}
	}
});

export default MuiTheme;