import { createStyles, Fab, Icon, Theme, Tooltip, WithStyles, withStyles } from '@material-ui/core';
import { IconProps } from '@material-ui/core/Icon';
import React, { Component } from 'react';
import SelectTextField, { SelectTextFieldProps } from '../SelectTextField';

export interface HeaderActionConfig {
	type: 'icon' | 'select';
	config: {
		icon?: string;
		color?: IconProps['color'];
		title?: string;
		onClick?: (event?: React.MouseEvent<any>) => void;
		onChange?: (status: boolean) => void;
		value?: string;
		flag?: boolean;
		disabled?: boolean;
		options?: Array<{
			text?: string;
			isDisabled?: boolean;
			value?: string;
		}>;
		tooltip?: string;
	};
}
export interface HeaderActionsProps extends WithStyles<typeof STYLES>, SelectTextFieldProps {
	actionConfig?: Array<HeaderActionConfig>;
}

class HeaderActions extends Component<HeaderActionsProps> {
	render() {
		const { classes, actionConfig = [] } = this.props;

		return (
			<div className={classes.root}>
				{actionConfig.map((item, index) => {
					if (item.type === 'icon') {
						var config = item.config;
						return (
							<Tooltip key={index} title={config.tooltip || ''}>
								<Fab
									disabled={config.disabled}
									key={index}
									className={classes.btn}
									size="small"
									onClick={config.disabled ? undefined : config.onClick}
								>
									<Icon color={item.config.color}>{config.icon}</Icon>
								</Fab>
							</Tooltip>
						);
					}
					if (item.type === 'select') {
						var config1 = item.config;

						return <SelectTextField key={index} config={config1} />;
					}
					return null;
				})}
			</div>
		);
	}
}

const STYLES = (theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			justifyContent: 'center'
		},
		btn: {
			margin: 5,
			// backgroundColor: theme.palette.common.white
		},
	});

export default withStyles(STYLES)(HeaderActions);
