import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TDispatch } from 'Models/App/@types';
import useAsyncTask from 'Hooks/useAsyncTask';
import { TReduxStore } from 'RootReducer';
import { ExploreHomeState, ExploreHome } from 'Models/ExploreHome/@types';
import ReorderableList from 'Components/ReorderableList';
import CuratorSpotlightCard from 'features/ExploreHome/Cards/CuratorSpotlightCard';
import MicroCourseSpotlightCard from 'features/ExploreHome/Cards/MicroCourseSpotlightCard';
import Listicle from 'features/ExploreHome/Cards/Listicle';
import { ExploreList } from 'Models/ExploreList/@types';
import { Box, makeStyles, Theme, createStyles, Chip, CircularProgress, Button, Typography } from '@material-ui/core';
import HeaderActions, { HeaderActionsProps } from 'Components/Buttons/HeaderActions';
import { getHomeDataAction, reorderExploreHomeItems, trashExploreHomeItem } from 'Models/ExploreHome/actions';
import useToastMessage from 'Hooks/useToastMessage';
import useConfirmationDialog from 'Hooks/useConfirmationDialog';
import { OExploreHome } from 'Models/ExploreHome';
import ExploreHomeItemForm from './ExploreHomeItemForm';
import { MainTagType, TTagState } from 'Models/Tag/@types';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { JSONType } from 'typings/global';

type ReduxSelector = Pick<ExploreHomeState, 'list' | 'hasMoreItems'> & { tagCategories: TTagState['list'] };
const BaseURL = '/dashboard/customiseExplore';
export const ITEMS_PER_REQUEST = 10;
const CustomiseExplore: FC = () => {
	const dispatch = useDispatch<TDispatch>();
	const { list, tagCategories, hasMoreItems } = useSelector<TReduxStore, ReduxSelector>(
		({ ExploreHome: { list, hasMoreItems }, Tag: { list: tagCategories } }) => ({
			list,
			tagCategories,
			hasMoreItems,
		})
	);
	const [pagesLoaded, setPagesLoaded] = useState(0);
	const exploreTagCategory = tagCategories?.filter((tagCategory) => tagCategory.type === MainTagType)?.[0];
	const defaultTag = exploreTagCategory?.tags?.filter((tag) => tag.value === 'all')?.[0];
	const history = useHistory();
	const location = useLocation();
	let { tag } = queryString.parse(location.search);
	if (!tag) {
		tag = defaultTag?.id || '';
	}
	const withConfirmationDialog = useConfirmationDialog();
	const withToast = useToastMessage();
	const classes = useStyles();

	const openMainForm = () => {
		dispatch({ type: OExploreHome.Actions.SET_EDITING_ITEM, data: { tagId: tag } });
		dispatch({ type: OExploreHome.Actions.SHOW_MAIN_FORM });
	};

	const HEADER_ACTION_CONFIG: HeaderActionsProps['actionConfig'] = [
		{
			type: 'icon',
			config: {
				icon: 'add',
				tooltip: 'Add a spotlight or list',
				onClick: openMainForm,
			},
		},
	];

	const getItemsListTask = useAsyncTask(async (params?: JSONType, updateExistingList?: boolean) => {
		if (!updateExistingList) {
			// Temporarily setting the data to an empty list to show feedback to user
			dispatch({ type: OExploreHome.Actions.LIST_RECEIVED, data: { data: [], updateExistingList: false } });
		}
		await getHomeDataAction((tag || '') as string, params, updateExistingList)(dispatch);
		if (updateExistingList) setPagesLoaded(pagesLoaded + 1);
		else setPagesLoaded(1);
	});

	useEffect(() => {
		if (tag) getItemsListTask.run();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tag, tagCategories]);

	const deleteExploreHomeItem = (item: ExploreHome) => {
		withConfirmationDialog(
			async () => {
				dispatch(trashExploreHomeItem([item.id]));
			},
			{ message: 'Are you sure you want to remove this item from Explore?' }
		);
	};

	const handleUpdateListOrder = async (nextList: ExploreHome[], movedItem: ExploreHome, _oldIndex: number, newIndex: number) => {
		await withToast(async () => await dispatch(reorderExploreHomeItems(movedItem.id, newIndex, nextList)), {
			errorToastMessage: 'Failed to rearrange item',
			/* showSuccessMessage: false */ successToastMessage: 'Reordered successfully',
		});
	};

	const onEditSpotlight = (item: ExploreHome) => {
		// listicles cannot be edited from this page as of now
		dispatch({ type: OExploreHome.Actions.SET_EDITING_ITEM, data: item });
		dispatch({ type: OExploreHome.Actions.SHOW_MAIN_FORM });
	};

	const renderItem = getRenderItem(deleteExploreHomeItem, onEditSpotlight);

	return (
		<Box display={'flex'} alignItems={'center'} flexDirection={'column'} width={'100%'} mt={7}>
			<Box width={'100%'} px={5} display={'flex'} justifyContent={'space-around'} boxSizing={'border-box'} mb={1}>
				{exploreTagCategory
					? exploreTagCategory?.tags?.map((exploreTag) => (
							<Chip
								key={exploreTag.id}
								color={exploreTag.id === tag ? 'primary' : undefined}
								label={exploreTag.name}
								clickable
								onClick={() => history.push(`${BaseURL}?tag=${exploreTag.id}`)}
							/>
					  ))
					: 'Tags for explore not found'}
			</Box>
			<HeaderActions actionConfig={HEADER_ACTION_CONFIG} />
			<Box className={classes.listContainer} width={'100%'} px={5} id="list-container">
				<ReorderableList<ExploreHome> list={list} handleUpdateListOrder={handleUpdateListOrder} renderItem={renderItem} />
				{hasMoreItems || getItemsListTask.status === 'PROCESSING' ? (
					<Box display="flex" justifyContent="center">
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								getItemsListTask.run({ filter: { skip: ITEMS_PER_REQUEST * pagesLoaded, limit: ITEMS_PER_REQUEST } }, true);
							}}
							disabled={getItemsListTask.status === 'PROCESSING'}
						>
							{getItemsListTask.status === 'PROCESSING' ? <CircularProgress /> : 'Load more'}
						</Button>
					</Box>
				) : (
					<Typography>End of list</Typography>
				)}
				<ExploreHomeItemForm />
			</Box>
		</Box>
	);
};

export default CustomiseExplore;

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		listContainer: {
			boxSizing: 'border-box',
			marginTop: '2rem',
		},
	})
);

const getRenderItem =
	(deleteExploreHomeItem: (item: ExploreHome) => void, onEditSpotlight: (item: ExploreHome) => void) =>
	(item: ExploreHome, dragHandleProps: any, dragged: boolean) => {
		switch (item.subjectType) {
			case 'user':
				return (
					<CuratorSpotlightCard
						editItem={onEditSpotlight}
						key={item.id}
						exploreHomeItem={item}
						deleteItem={deleteExploreHomeItem}
						color={item.color}
						dragHandleProps={dragHandleProps}
						spotlightDescription={item.spotlightDescription || ''}
					/>
				);
			case 'MicroCourse':
				return (
					<MicroCourseSpotlightCard
						editItem={onEditSpotlight}
						key={item.id}
						exploreHomeItem={item}
						deleteItem={deleteExploreHomeItem}
						color={item.color}
						dragHandleProps={dragHandleProps}
					/>
				);
			case 'ExploreList':
				return (
					<Listicle
						key={item.id}
						exploreHomeItem={item}
						deleteItem={deleteExploreHomeItem}
						color={(item.subject as ExploreList)?.color}
						dragHandleProps={dragHandleProps}
					/>
				);

			default:
				console.error({ item }, 'has no appropriate component.');
				return <></>;
			// return <ExploreHomeItem subjectType={item['subjectType']} subject={item['subject']} dragHandleProps={dragHandleProps} color={item['color']} />;
		}
	};
