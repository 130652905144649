import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useState } from 'react';
import { Crop } from 'react-image-crop';
import ImageCropper from './ImageCropper';

export interface TFile {
	name: string;
	type: string;
	size: number | string;
	base64: string | ArrayBuffer | null;
	file: any;
}

export interface FileInputProps {
	multiple?: boolean;
	accept?: string;
	style?: object;
	disabled?: boolean;
	onDone?: (files: Array<TFile>) => void;
	onChange?: (data: any) => void;
	readAs?: keyof Pick<FileReader, 'readAsDataURL' | 'readAsBinaryString'>;

	/** Will only work if multiple is false */
	withCropping?: boolean;
	cropConfig?: Crop;
}
// This component is always meant to be wrapped within another component
const FileInput: FC<FileInputProps> = (props) => {

	const [open, setOpen] = useState(false);
	const { withCropping = false, cropConfig } = props;
	const [croppedImage, setCroppedImage] = useState<TFile>();

	const classes = useStyles();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let files = e.target.files || [];
		const { onChange } = props;
		if (onChange) {
			onChange(files);
			return;
		}
		// console.log(e.target.files);
		let allFiles: Array<TFile> = [];
		try {
			Array.from(files).forEach(file => {
				let reader = new FileReader();
				reader.onload = event => {
					let fileInfo: TFile = {
						name: file.name,
						type: file.type,
						size: Math.round(file.size / 1000) + ' kB',
						base64: reader.result,
						file: file,
					};
					allFiles.push(fileInfo);
					if (allFiles.length === files.length) {
						if (withCropping && (allFiles.length === 1) && !multiple) {
							setOpen(true);
							setCroppedImage(fileInfo);
						}
						else if (props.onDone)
							props.onDone(allFiles);
					}
				};
				reader[props.readAs || 'readAsDataURL'](file);
			});
		} catch (error) {
			console.log(error);
		}
	};

	const handleComplete = (croppedBase64: string) => {
		if (croppedImage)
			props.onDone?.([{ ...croppedImage, base64: croppedBase64 }]);
	};

	const { disabled = false, multiple = false, style = {}, accept = 'image/*' } = props;
	return (
		<>
			<input
				type="file"
				onChange={handleChange}
				multiple={multiple}
				accept={accept}
				className={classes.fileInput}
				style={{ ...style }}
				disabled={disabled}
			/>
			{
				(withCropping && croppedImage?.base64 && cropConfig) ?
					<ImageCropper
						open={open}
						onClose={() => setOpen(false)}
						base64={croppedImage.base64 as string}
						cropConfig={cropConfig}
						onComplete={handleComplete}
					/>
					: null
			}
		</>
	);
};

const useStyles = makeStyles((theme: Theme) => createStyles({
	fileInput: {
		cursor: 'pointer',
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		width: '100%',
		opacity: 0,
		zIndex: 5
	}
}));

export default FileInput;