import { request } from 'Resources/AxiosUtils';
import { JSONType, Params } from 'typings/global';
import { Job } from './@types';
import { parseToJobListing } from './JobListingParsers';

class JobListingModel {
	static getAllJobListing = async (params?: Params): Promise<Job[]> => {
		const res = await request<JSONType[]>({
			url: '/jobListings',
			method: 'GET',
			params,
		});
		return res.map(parseToJobListing);
	};

	static getJobListing = async (id: string, params?: Params): Promise<Job> => {
		const res = await request<JSONType>({
			url: `/jobListings/${id}`,
			method: 'GET',
			params,
		});
		return parseToJobListing(res);
	};

	static createJobListing = async (data: Partial<Job>): Promise<Job> => {
		const res = await request<JSONType>({
			url: `/jobListings/`,
			method: 'POST',
			data,
		});
		return parseToJobListing(res);
	};

	static updateJobListing = async (id: string, data: Partial<Job>): Promise<Job> => {
		const res = await request<JSONType>({
			url: `/jobListings/${id}`,
			method: 'PATCH',
			data,
		});
		return parseToJobListing(res);
	};

	static deleteJobListing = async (id: string): Promise<{ count: number }> => {
		const res = await request<{ count: number }>({
			url: `jobListings/${id}`,
			method: 'DELETE',
		});
		return res;
	};
}

export default JobListingModel;
