import LoopFront from "loop-front";
import { TTag, TTagCategory, TFormType, TTagListingFilter } from "./@types";
import _ from 'lodash';
import utilities from "../../Resources/Utils";
import { OApp } from "../../Models/App";
import TagUtils from "../../Core/Tag/TagUtils";
import TagCore from '../../Core/Tag/Tag';
import { TDispatch } from "../App/@types";




const CustomActions = {
    SHOW_TAG_FORM: 'SHOW_TAG_FORM',
    CLOSE_TAG_FORM: 'CLOSE_TAG_FORM',
    SET_TAG_FILTER: 'SET_TAG_FILTER',
    SET_APPLIED_FILTER: 'SET_APPLIED_FILTER',
    APPLY_TAG_FILTER: 'APPLY_TAG_FILTER',
    RESET_TAG_FILTER: 'RESET_TAG_FILTER'
}

const CustomActivity = {

}

const CustomEntities = {
    TAG_BARS: 'bars',
}


export const requestUpdateCategoryOrder = (formData: Record<string, any>) => utilities.request({ url: `TagCategories/change-order/`, method: 'PATCH', data: formData });



export const requestTagCategories = () => {
    return utilities.request({
        url: `tagcategories`,
        params: {
            filter: {
                include: 'tags'
            }
        }
    })
}


class Tag extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivity> {
    constructor() {
        super('tagcategories', CustomActions, CustomEntities, CustomActivity);
    }


    fetchTagCategories = () => async (dispatch: TDispatch) => {
        try {
            const res = await requestTagCategories();
            dispatch({
                type: this.Actions.LIST_RECEIVED,
                data: _.orderBy(res.data, 'order', 'asc')
            })
            return res;
        } catch (error) {
            throw error;
        }
    }


    createNewTags = (tagCategoryId: string, tags: Partial<TTag>[]) => async (dispatch: TDispatch) => {
        try {
            await utilities.request({
                url: `TagCategories/${tagCategoryId}/new-tags`,
                method: 'POST',
                data: {
                    tags: tags.map(t => ({ ...t, type: 'interest' }))
                }
            })
            dispatch(OApp.showToast({ message: 'Tag(s) created successfully', variant: 'success' }))
        } catch (error) {
            dispatch(OApp.showToast({ message: 'Something went wrong', variant: 'error' }));
        }
    }


    deleteTagCategories = (ids: string[]) => async (dispatch: TDispatch) => {
        try {
            await utilities.request({
                url: 'tagcategories/delete-multiple',
                method: 'DELETE',
                data: { ids }
            })
            dispatch(OApp.showToast({ message: 'Tag categories deleted', variant: 'success' }))
        } catch (error) {
            dispatch(OApp.showToast({ message: 'Something went wrong', variant: 'error' }));
        }
    }

    updateTagCategoryOrder = (formData: Record<string, any>) => async (dispatch: TDispatch) => {
        try {
            await requestUpdateCategoryOrder(formData);
            dispatch(this.fetchTagCategories());
            dispatch(OApp.showToast({ message: 'Order updated', variant: 'success' }))
        } catch (error) {
            dispatch(OApp.showToast({ message: 'Something went wrong', variant: 'error' }));
        }
    }

    deleteTags = (ids: string[]) => async (dispatch: TDispatch) => {
        try {
            await utilities.request({
                url: 'tags/delete-multiple',
                method: 'DELETE',
                data: { ids }
            })
            dispatch(OApp.showToast({ message: 'Tag(s) deleted', variant: 'success' }))
        } catch (error) {
            dispatch(OApp.showToast({ message: 'Something went wrong', variant: 'error' }));
        }
    }


    createTagCategories = (tags: Partial<TTagCategory>[]) => async (dispatch: TDispatch) => {
        try {
            await utilities.request({
                url: 'tagcategories',
                method: 'POST',
                data: tags
            })
            dispatch(OApp.showToast({ message: 'Tag categories created successfully', variant: 'success' }))
        } catch (error) {
            dispatch(OApp.showToast({ message: 'Something went wrong', variant: 'error' }));
        }
    }

    resetFilter = () => (dispatch: TDispatch) => {
        dispatch({
            type: this.Actions.RESET_TAG_FILTER
        })
    }


    showFormDialog = (formType: TFormType, editedItem?: any) => (dispatch: TDispatch) => {
        dispatch({
            type: this.Actions.SHOW_TAG_FORM,
            data: {
                formType,
                editedItem
            }
        })
    }


    closeFormDialog = () => (dispatch: TDispatch) => {
        dispatch({
            type: this.Actions.CLOSE_TAG_FORM
        })
    }


    updateTag = (tagId: string, formData: Partial<TTag>) => async (dispatch: TDispatch) => {
        try {
            await utilities.request({
                url: `tags/${tagId}`,
                method: 'PATCH',
                data: formData
            })
            dispatch(OApp.showToast({ message: 'Tag updated successfully', variant: 'success' }))
        } catch (error) {
            dispatch(OApp.showToast({ message: 'Something went wrong', variant: 'error' }));
        }
    }


    /// FILTER
    setFilter = (filter: { name: string, value: string }) => (dispatch: TDispatch) => {
        dispatch({
            type: this.Actions.SET_TAG_FILTER,
            data: filter
        })
    }


    applySelectedFilter = (filter: TTagListingFilter) => async (dispatch: TDispatch) => {
        dispatch({
            type: this.Actions.SET_APPLIED_FILTER,
            data: filter
        })
        try {
            const _filter = TagUtils.buildFilter(filter);
            const res = await TagCore.filterTags(_filter);
            dispatch({
                type: this.Actions.APPLY_TAG_FILTER,
                data: (_.get(res, 'data') || []).filter((t: TTagCategory) => t.tags && t.tags.length > 0)
            })
        } catch (error) {
            dispatch({
                type: this.Actions.APPLY_TAG_FILTER,
                data: []
            })
        }
    }





}

export const OTag = new Tag();