import React, { FC } from 'react';
import { ColorMapKeys } from 'typings/global';
import { Card, Box, IconButton, Icon, Typography, makeStyles, Theme, createStyles } from '@material-ui/core';
import { colorMap } from 'Resources/constants';
import clsx from 'clsx';

interface SpotlightCardProps {
	title: string;
	subtitle: string;
	color: ColorMapKeys;
	dragHandleProps: any;
	onDelete: () => void;
	onEdit: () => void;
}

const SpotlightCard: FC<SpotlightCardProps> = (props) => {
	const { dragHandleProps, title, subtitle, color = 'indigo', onDelete, onEdit } = props;
	const classes = useStyles({ color });
	return <Card className={classes.root}>
		<Box display={'flex'} width={'100%'}>
			<Typography className={clsx(classes.whiteText, classes.title)} variant={'h5'}>{title}</Typography>
			<Box alignSelf={'flex-end'}>
				<IconButton size={'small'} onClick={onEdit} >
					<Icon className={classes.whiteText}>edit</Icon>
				</IconButton>
				<IconButton size={'small'} onClick={onDelete}>
					<Icon className={classes.whiteText} >delete_outline</Icon>
				</IconButton>
				<IconButton size={'small'}{...dragHandleProps} >
					<Icon className={classes.whiteText} >open_with</Icon>
				</IconButton>
			</Box>
		</Box>
		<Typography className={classes.whiteText} variant={'subtitle2'}>{subtitle}</Typography>
	</Card>;
};

export default SpotlightCard;

const useStyles = makeStyles<Theme, { color: ColorMapKeys; }>((theme) => createStyles({
	root: {
		width: '100%',
		backgroundColor: ({ color }) => colorMap[color].light,
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box'
	},
	title: {
		flex: 1,
	},
	whiteText: {
		color: theme.palette.primary.contrastText
	}
}));