import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	createStyles,
	IconButton,
	LinearProgress,
	Menu,
	MenuItem,
	Theme,
	Typography,
	WithStyles,
	withStyles,
} from '@material-ui/core';
import dayjs from 'dayjs';
import { OUser } from 'Models/User';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TPostType } from '../../Models/App/@types';
import { TPost } from '../../Models/Post/@types';
import utilities from '../../Resources/Utils';
import { SimpleCardProps } from './SimpleCard';

export type TPostStatus = 'likes' | 'comments' | 'bookmarks' | 'tags';
interface IProps extends WithStyles<typeof STYLES> {
	post: TPost;
	onStatusClick?: (item: TPost, status: TPostStatus) => Promise<void>;
	actions?: SimpleCardProps['actions'];
	postTypes?: TPostType[];
}

interface IState {
	anchorEl?: Element;
	loading: boolean;
}

class PostCard extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			loading: false,
			anchorEl: undefined,
		};
	}

	handleOpen = (event: React.MouseEvent) => this.setState({ anchorEl: event.currentTarget });

	handleClose = () => this.setState({ anchorEl: undefined });

	handleClick = (clickHandler: () => void) => {
		this.handleClose();
		clickHandler();
	};

	handleStatusClick = async (status: TPostStatus) => {
		if (!this.props.onStatusClick) return;
		this.setState({ loading: true });
		try {
			await this.props.onStatusClick(this.props.post, status);
		} catch (error) {}
		this.setState({ loading: false });
	};

	getPostType = () => {
		const { post, postTypes = [] } = this.props;
		const value = utilities.findValues(postTypes, post.type);
		if (value && value.length > 0) return value[0];
		return '';
	};

	render() {
		const { classes, post, actions = [] } = this.props;

		const image = post.image;
		const postType = this.getPostType();
		const userRedirect = post.user ? `/dashboard/users/${post.user.id}/details` : '#';

		return (
			<div className={classes.root}>
				<Card className={classes.card}>
					<Typography variant="caption" className={image ? classes.postTypeAlt : classes.postType}>
						{postType}
					</Typography>
					{image ? <CardMedia image={image} className={classes.media} component="img" /> : null}
					<CardContent className={classes.contentContainer}>
						<Typography className={classes.timestamp} variant="caption">
							{dayjs(post.created).format('DD MMM, YYYY. hh:mm a')}
						</Typography>
						<Typography variant="body2" className={classes.title}>
							{post.title}
						</Typography>
						<Link to={userRedirect}>
							<Typography variant={'caption'} component={'p'} color="primary" className={classes.secondary}>
								{post.user ? OUser.getName(post.user) : ''}
							</Typography>
						</Link>
					</CardContent>

					<CardActions>
						{this.state.loading ? (
							<Box flex="1" alignItems="center" p="5px 30px">
								<LinearProgress />
							</Box>
						) : (
							<>
								<Button className={classes.actionButtons} onClick={(e) => this.handleStatusClick('likes')}>
									<i className="material-icons">thumb_up</i> {post.likeCount || 0}
								</Button>
								<Button className={classes.actionButtons} onClick={(e) => this.handleStatusClick('comments')}>
									<i className="material-icons">chat_bubble_outline</i> {post.commentCount || 0}
								</Button>
								<Button className={classes.actionButtons} onClick={(e) => this.handleStatusClick('bookmarks')}>
									<i className="material-icons">turned_in_not</i> {post.bookmarkCount || 0}
								</Button>
								<Button
									className={classes.actionButtons}
									variant="text"
									color="primary"
									onClick={(e) => this.handleStatusClick('tags')}
								>
									{(post.tagIds || []).length} tags
								</Button>
							</>
						)}

						{actions && actions.length > 0 ? (
							<div className={classes.menu}>
								<IconButton
									aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
									onClick={this.handleOpen}
									aria-haspopup="true"
									size="small"
								>
									<i className="material-icons">more_vert</i>
								</IconButton>
								<Menu id="simple-menu" anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.handleClose}>
									{actions.map((action, index) => {
										return (
											<MenuItem key={index} onClick={() => this.handleClick(action.onClick)}>
												{action.name}
											</MenuItem>
										);
									})}
								</Menu>
							</div>
						) : null}
					</CardActions>
				</Card>
			</div>
		);
	}
}

const STYLES = (theme: Theme) =>
	createStyles({
		root: {
			position: 'relative',
		},
		card: {
			width: 220,
			minHeight: 110,
			height: 'auto',
		},
		postType: {
			position: 'absolute',
			top: -20,
		},
		postTypeAlt: {
			position: 'absolute',
			top: 0,
			padding: '1px 4px',
			background: theme.palette.secondary.main,
			color: theme.palette.common.white,
		},
		title: {
			fontWeight: theme.typography.fontWeightBold,
		},
		timestamp: {
			position: 'relative',
			background: theme.palette.grey[100],
			left: -8,
			padding: '1px 2px',
			top: -10,
			color: theme.palette.getContrastText(theme.palette.grey[100]),
		},
		actionButtons: {
			padding: 0,
			minWidth: 0,
			minHeight: 22,
			textTransform: 'none',
			marginRight: 4,
			'& i': {
				fontSize: 16,
				marginRight: 2,
			},
		},
		secondary: {
			paddingRight: 5,
		},
		contentContainer: {
			minHeight: 60,
			padding: 8,
		},

		media: {
			width: '100%',
			// height: 'auto',
			maxHeight: 220,
			minHeight: 180,
			objectFit: 'cover',
		},
		menu: {
			position: 'absolute',
			right: 2,
			marginTop: -10,
			bottom: 6,
		},
	});

export default withStyles(STYLES)(PostCard);
