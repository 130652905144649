import React, { useState } from 'react';
import ListDrawer from './index';

type TListDrawerReturn<T> = [JSX.Element, (data: T[]) => void, React.Dispatch<React.SetStateAction<string>>];
export function useListDrawer<T extends { id: string; name: string; phone?: string } = any>(
	title: string,
	renderItem?: (item: T) => JSX.Element
): TListDrawerReturn<T> {
	const [open, setOpen] = useState(false);
	const [drawerTitle, setDrawerTitle] = useState(title);
	const [drawerList, setList] = useState<T[]>([]);

	const openList = (data: T[]) => {
		setOpen(true);
		setList(data);
	};
	const closeList = () => {
		setOpen(false);
	};

	return [
		<ListDrawer<T> open={open} onClose={closeList} title={drawerTitle} list={drawerList} renderItem={renderItem} />,
		openList,
		setDrawerTitle,
	];
}
