import { Reducer } from 'redux';
import { ExploreListState } from './@types';
import { TAction } from 'loop-front';
import { OExploreList } from '.';
import { parseExploreList } from './ListParsers';
import utilities from 'Resources/Utils';

const initialState: ExploreListState = {
	list: [],
	showMainForm: false,
	editedList: undefined,
	currentList: undefined,
	totalCount: undefined,
};

export const RExploreList: Reducer<ExploreListState, TAction> = (state = initialState, action): ExploreListState => {
	const {
		SHOW_LIST_FORM,
		HIDE_LIST_FORM,
		LIST_RECEIVED,
		SET_EDITED_LIST,
		SINGLE_ITEM_RECEIVED,
		DELETE_LIST_DOCUMENT,
		CREATED_NEW_EXPLORE_LIST,
		ITEM_DELETED,
		UPDATE_CURRENT_LIST,
		POST_ENTITY_OF_ITEM_SUCCESS,
		REORDER_LIST_DOCUMENTS,
	} = OExploreList.Actions;
	const { PUBLISH } = OExploreList.Entities;
	switch (action.type) {
		case POST_ENTITY_OF_ITEM_SUCCESS: {
			switch (action.entity) {
				case PUBLISH:
					return {
						...state,
						currentList: state.currentList?.id === action.data.id ? { ...state.currentList, ...action.data } : state.currentList,
						list: utilities.updateItemList(state.list, action.data, 'UPDATE'),
					};
				default:
					return state;
			}
		}
		case SHOW_LIST_FORM:
			return { ...state, showMainForm: true };
		case HIDE_LIST_FORM:
			return { ...state, showMainForm: false };
		case LIST_RECEIVED:
			return { ...state, list: action.data?.map(parseExploreList) };
		case SET_EDITED_LIST:
			return { ...state, editedList: action.data };
		case SINGLE_ITEM_RECEIVED:
			return { ...state, currentList: parseExploreList(action.data) };
		case DELETE_LIST_DOCUMENT:
			return { ...state, currentList: action.data };
		case CREATED_NEW_EXPLORE_LIST:
			return { ...state, list: (state.list || []).concat(parseExploreList(action.data)) };
		case ITEM_DELETED:
			return { ...state, list: action.data };
		case UPDATE_CURRENT_LIST:
			return { ...state, currentList: parseExploreList(action.data?.[0]) };
		case REORDER_LIST_DOCUMENTS:
			return { ...state, currentList: { ...state.currentList!, listDocuments: action.data } };
		case OExploreList.Actions.ACTIVITY_RECEIVED: {
			switch (action.activity) {
				case OExploreList.Activites.COUNT:
					return { ...state, totalCount: action.data.count };
				default:
					return { ...state };
			}
		}
		default:
			return state;
	}
};
