import { Reducer } from 'redux';
import { TUserState, TUser } from './@types';
import { TAction } from 'loop-front';
import { OUser } from '.';
import { OPost } from '../Post';
import utilities from '../../Resources/Utils';
import { ODevice } from '../Device';
import _ from 'lodash';
import { parseUser } from './userParsers';
import { parseMicroCourse } from 'Models/MicroCourse/microCourseParser';

const INITIAL_STATE: TUserState = {
	loading: false,
	userFollowers: [],
	userFollowing: [],
	userCreatedMicroCourses: undefined,
	listingFilter: { status: [] },
	currentAppliedFilter: { status: [] },
	showMilestoneForm: false,
	editingMilestone: undefined,
	showSpecialUserForm: false,
};

export const RUser: Reducer<TUserState, TAction> = (state = INITIAL_STATE, action): TUserState => {
	const {
		USER_SET_LISTING_FILTER_STATUS,
		CLEAR_USER_LISTING_FILTERS,
		SET_USER_LISTING_LOADING,

		APPLY_SELECTED_USER_FILTERS,
		// SHOW_SPECIAL_USER_FORM,
		// HIDE_SPECIAL_USER_FORM,
		ADD_SPECIAL_USER,
	} = OUser.Actions;

	switch (action.type) {
		case SET_USER_LISTING_LOADING: {
			return { ...state, loading: action.data };
		}
		case CLEAR_USER_LISTING_FILTERS: {
			return { ...state, listingFilter: { status: [] }, currentAppliedFilter: { status: [] } };
		}
		case APPLY_SELECTED_USER_FILTERS: {
			return { ...state, currentAppliedFilter: action.data };
		}
		case USER_SET_LISTING_FILTER_STATUS: {
			const curFilter = state.listingFilter.status;
			let status: any;
			if (_.find(curFilter, action.data)) status = curFilter.filter((f) => f.label !== _.get(action.data, 'label'));
			else status = [...curFilter, action.data];

			return { ...state, listingFilter: { ...state.listingFilter, status } };
		}
		case OUser.Actions.APP_USER_RECEIVED:
			return { ...state, appUser: { ...parseUser(action.data) } };

		case OUser.Actions.SINGLE_ITEM_RECEIVED:
			return { ...state, user: { ...parseUser(action.data) } };

		case ODevice.Actions.LIST_RECEIVED:
			return { ...state, user: { ...state.user, userDeviceIds: action.data } as TUser };

		case OUser.Actions.FETCHING_SINGLE_ITEM:
			return { ...state, user: undefined, userPosts: [] };

		case OUser.Actions.LIST_RECEIVED:
			return { ...state, list: (action.data || []).filter((u: TUser) => u.email !== 'admin@chet.com').map(parseUser) };
		case OUser.Actions.SHOW_MILESTONE_FORM:
			return { ...state, showMilestoneForm: true };
		case OUser.Actions.HIDE_MILESTONE_FORM:
			return { ...state, showMilestoneForm: false, editingMilestone: undefined };
		// case SHOW_SPECIAL_USER_FORM:
		// 	return { ...state, showSpecialUserForm: true };
		// case HIDE_SPECIAL_USER_FORM:
		// 	return { ...state, showSpecialUserForm: false };
		case ADD_SPECIAL_USER:
			return { ...state, list: (state.list || []).concat(action.data) };

		case OUser.Actions.ACTIVITY_RECEIVED: {
			switch (action.activity) {
				case OUser.Activites.COUNT:
					return { ...state, totalCount: action.data.count };
				default:
					return { ...state };
			}
		}
		case OUser.Actions.SET_MILESTONES:
			return { ...state, user: { ...state.user, milestones: action.data } as TUser };
		case OUser.Actions.SET_EDITING_MILESTONE:
			return { ...state, editingMilestone: action.data };
		// case OUser.Actions.ENTITY_OF_ITEM_RECEIVED: {
		//     switch (action.entity) {
		//         case OUser.Entities.FOLLOWERS: return { ...state, userFollowers: action.data }
		//         case OUser.Entities.FOLLOWING: return { ...state, userFollowing: action.data }
		//     }
		// }
		case OUser.Actions.RECEIVED_USER_CREATED_MICROCOURSES:
			console.log('RECEIVED_USER_CREATED_MICROCOURSES');
			return { ...state, userCreatedMicroCourses: action.data.map(parseMicroCourse) };

		case OUser.Actions.ITEM_PATCH_SUCCESS:
			return { ...state, user: { ...(state.user || {}), ...parseUser(action.data) } };

		case OUser.Actions.SHOW_USER_FORM:
			return { ...state, showMainForm: true, editedUser: action.data };

		case OUser.Actions.HIDE_USER_FORM:
			return { ...state, showMainForm: false, editedUser: undefined };

		case OUser.Actions.POST_ENTITY_OF_ITEM_SUCCESS: {
			switch (action.entity) {
				case OUser.Entities.BAN:
					return {
						...state,
						user: { ...state.user, ban: action.data.ban } as TUser,
						list: utilities.updateItemList(state.list || [], action.data, 'UPDATE'),
					};
				case OUser.Entities.MILESTONES:
					return { ...state, user: { ...state.user, milestones: [...(state.user?.milestones || []), action.data] } as TUser };
				default:
					return { ...state };
			}
		}

		case OUser.Actions.ACTIVITY_POST_SUCCESS: {
			switch (action.activity) {
				case OUser.Activites.LOGOUT:
					return { ...state, appUser: undefined };
				// case OUser.Activites.IS_APPROVED :
				//     return {...state , list : utilities.updateItemList(state.list || [] , action.data , 'UPDATE'), user:action.data}
				default:
					return state;
			}
		}

		case OUser.Actions.ITEM_ACTIVITY_POST_SUCCESS: {
			switch (action.activity) {
				case OUser.Activites.IS_APPROVED:
					return { ...state, list: utilities.updateItemList(state.list || [], action.data, 'UPDATE'), user: action.data };
				default:
					return state;
			}
		}

		case OPost.Actions.ACTIVITY_POST_SUCCESS: {
			switch (action.activity) {
				case OPost.Activites.CREATE:
					return { ...state, userPosts: [action.data, ...(state.userPosts || [])] };
				default:
					return { ...state };
			}
		}

		case OPost.Actions.USERS_POST_RECEIVED:
			return { ...state, userPosts: action.data };

		case OPost.Actions.ITEM_ACTIVITY_DELETE_SUCCESS: {
			switch (action.activity) {
				case OPost.Activites.DELETE: {
					return {
						...state,
						userPosts: utilities.updateItemList(state.userPosts || [], action.additionalDispatchData.post, 'DELETE'),
					};
				}
				default:
					return state;
			}
		}
		// case 'UPDATE_ISAPPROVED_STATUS': {...state}
		default:
			return { ...state };
	}
};
