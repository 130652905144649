import React, { FC } from 'react';
import { MicroCourse } from 'Models/MicroCourse/@types';
import SpotlightCard from './SpotlightCard';
import { ExploreHome } from 'Models/ExploreHome/@types';

interface MicroCourseSpotlightCardProps {
	color: ExploreHome['color'];
	dragHandleProps: any;
	exploreHomeItem: ExploreHome;
	deleteItem: (item: ExploreHome) => void;
	editItem: (item: ExploreHome) => void;
}

const MicroCourseSpotlightCard: FC<MicroCourseSpotlightCardProps> = (props) => {
	const { exploreHomeItem, deleteItem, editItem, color = 'indigo', dragHandleProps } = props;
	const { subject } = exploreHomeItem;
	const { name, curators } = subject as MicroCourse;
	return <SpotlightCard onEdit={() => editItem(exploreHomeItem)} onDelete={() => deleteItem(exploreHomeItem)} title={name} subtitle={curators?.map(curator => curator.name).join(', ') || ''} color={color} dragHandleProps={dragHandleProps} />;
};

export default MicroCourseSpotlightCard;
