import { createStyles, Theme, makeStyles, fade } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

// eslint-disable-next-line
interface LoaderProps {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    overlaid?: boolean
}

const getDimension = (size: LoaderProps['size'] = 'md'): number => {
    switch (size) {
        case 'xs': return 24;
        case 'sm': return 32;
        case 'md': return 40;
        case 'lg': return 48;
        case 'xl': return 56;
        default: return 40;
    }
}

const Loader: FC<LoaderProps> = (props) => {

    const classes = useStyles();
    const { size = 'md', overlaid = false } = props;

    const dim = getDimension(size);

    return (
        <div className={clsx(classes.root, { [classes.overlaid]: overlaid, })} >
            <CircularProgress size={dim} />
        </div>
    )
}

const useStyles = makeStyles(((theme: Theme) => createStyles({
    root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
    },
    md: {

    },
    overlaid: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: theme.zIndex.appBar + 1000,
        height: `100vh`,
        width: `100vw`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: fade('#fff', 0.4)
    }
})))

export default Loader;