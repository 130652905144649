import { NewsletterList } from 'Models/App/@types';
import { action, Action, thunk, Thunk } from 'easy-peasy';
import { AppModel } from 'Models/App';
import { TRootStore } from 'Stores';

interface AppState {
	newsletterLists?: NewsletterList[];
	getNewsletterLists: Thunk<AppState, undefined, null, TRootStore, Promise<NewsletterList[]>>;
	setNewsletterLists: Action<AppState, NewsletterList[] | undefined>;
}

const AppStore: AppState = {
	newsletterLists: [],
	getNewsletterLists: thunk(async (actions) => {
		const newsletterLists = await AppModel.getNewsLetters();
		actions.setNewsletterLists(newsletterLists);
		return newsletterLists;
	}),
	setNewsletterLists: action((state, payload) => {
		state.newsletterLists = payload;
	})
};

export default AppStore;