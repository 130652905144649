import LoopFront, { TAction } from "loop-front";
import { TToastConfig, TDispatch } from "./@types";
import { ThunkAction } from "redux-thunk";
import { TReduxStore } from "../../RootReducer";
import utilities from "../../../src/Resources/Utils";
import { OUser } from "../User";
import { OTag } from "../Tag";
import { JSONType } from "typings/global";
import { request } from "Resources/AxiosUtils";
import { parseToNewsletterList } from "./appParsers";

const CustomActions = {
	OPEN_TOAST: 'OPEN_TOAST',
	CLOSE_TOAST: 'CLOSE_TOAST',
	SHOW_CONFIRMATION_DIALOG: 'SHOW_CONFIRMATION_DIALOG',
	HIDE_CONFIRMATION_DIALOG: 'HIDE_CONFIRMATION_DIALOG',
};

const CustomActivities = {
	CONSTANTS: 'constants'
};

class App extends LoopFront<typeof CustomActions, {}, typeof CustomActivities> {
	constructor() {
		super('appmodels', CustomActions, {}, CustomActivities);
	}

	init = (): ThunkAction<Promise<boolean>, TReduxStore, {}, TAction> => async (dispatch, getState) => {
		dispatch(this.getActivity(this.Activites.CONSTANTS));
		const access_token = utilities.getAccessToken();
		const userId = utilities.getUserId();
		if (access_token && userId) {
			try {
				const res = await OUser.requestGetItem(userId);
				dispatch({ type: OUser.Actions.APP_USER_RECEIVED, data: res.data });
			} catch (error) {
				console.log('Getting Me Error', error);
				utilities.clearCookies();
				throw error;
			}
			LoopFront.setAuthHeader(access_token);
			dispatch(OTag.fetchTagCategories());
			return Promise.resolve(true);
		}
		return Promise.resolve(false);
	};

	showToast = (toastConfig: Partial<TToastConfig>): ThunkAction<void, TReduxStore, {}, TAction> => (dispatch, getState) => {
		const { toast } = getState().App;
		if (toast.open) return;
		dispatch({ type: this.Actions.OPEN_TOAST, data: { ...toastConfig, open: true } });
	};

	closeToast = (): ThunkAction<void, TReduxStore, {}, TAction> => (dispatch, getState) => {
		const { toast } = getState().App;
		if (!toast.open) return;
		dispatch({ type: this.Actions.CLOSE_TOAST });
	};

	showConfirmationDialog = (message: string, onAccept: () => Promise<void>, onCancel: () => void) => (dispatch: TDispatch) => {
		dispatch({
			type: this.Actions.SHOW_CONFIRMATION_DIALOG,
			data: {
				message,
				onAccept,
				onCancel
			}
		});
	};
	hideConfirmationDialog = () => (dispatch: TDispatch) => {
		dispatch({
			type: this.Actions.HIDE_CONFIRMATION_DIALOG,
		});
	};

}
export const OApp = new App();

export class AppModel{
	static getNewsLetters =async ()=>{
		const res = await request<JSONType[]>({
			url: 'users/newsletter-lists',
			method: 'GET',
		})
		return res.map(parseToNewsletterList);
	}
}