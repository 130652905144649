import { ColorMap } from "typings/global";

export const QUILL_MODULES = {
	history: {
		delay: 100,
		maxStack: 200,
		userOnly: false,
	},
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{ indent: '-1' }, { indent: '+1' }],
		// [{ 'script': 'sub' }, { 'script': 'super' }],
		[{ color: [] }],
		[{ align: [] }],
		// [{ 'direction': 'rtl' }],
		// ['clean'],
	],
};

export const QUILL_FORMATS = [
	'header',
	'bold',
	'italic',
	'underline',
	'strike',
	'indent',
	'link',
	'image',
	'color',
	'script',
	'font',
	'align',
	'direction',
	'size',
	'list',
	'blockquote',
	'code-block',
];

/* Any changes in the below will need to be done on the user facing side too. */

export const indigo1 = '#474E9D',
	indigo2 = '#2C3062',
	orange1 = '#FF6C6C',
	orange2 = '#DF4A4A',
	green1 = '#1ECAA6',
	green2 = '#19AC8D',
	magenta1 = '#A73786',
	magenta2 = '#862A6B',
	blue1 = '#566DFB',
	blue2 = '#2D47E9';

export const colorMap:ColorMap = {
	indigo: {
		label: 'Indigo',
		light: indigo1,
		dark: indigo2,
	},
	orange: {
		label: 'Orange',
		light: orange1,
		dark: orange2,
	},
	green: {
		label: 'Green',
		light: green1,
		dark: green2,
	},
	blue: {
		label: 'Blue',
		light: blue1,
		dark: blue2,
	},
	magenta: {
		label: 'Magenta',
		light: magenta1,
		dark: magenta2,
	},
};
