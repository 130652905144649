import LoopFront from "loop-front";

const CustomAcitivites = {
    JOIN: 'join'
}

const CustomActions = {
    SHOW_TEAM_FORM: 'SHOW_TEAM_FORM',
    HIDE_TEAM_FORM: 'HIDE_TEAM_FORM',
    SHOW_TEAM_USER_FORM: 'SHOW_TEAM_USER_FORM',
    HIDE_TEAM_USER_FORM: 'HIDE_TEAM_USER_FORM'
}

const CustomEntities = {
    USERS: 'users'
}

class Team extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomAcitivites> {
    constructor() {
        super('teams', CustomActions, CustomEntities, CustomAcitivites);
    }
}

export const OTeam = new Team();