import { Fab } from '@material-ui/core';
import { amber, green } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OApp } from '../../Models/App';
import { TAppState, TDispatch } from '../../Models/App/@types';
import { TReduxStore } from '../../RootReducer';

interface IProps {}

const variantIcon = {
	success: 'check_circle',
	SUCCESS: 'check_circle',

	warning: 'warning',
	WARNING: 'warning',

	error: 'error',
	ERROR: 'error',

	info: 'info',
	INFO: 'info',
};

const useStyles1 = makeStyles((theme: Theme) => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.primary.main,
	},
	warning: {
		backgroundColor: amber[700],
	},

	SUCCESS: {
		backgroundColor: green[600],
	},
	ERROR: {
		backgroundColor: theme.palette.error.dark,
	},
	INFO: {
		backgroundColor: theme.palette.primary.main,
	},
	WARNING: {
		backgroundColor: amber[700],
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
	closeBtn: {
		background: 'transparent',
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
			background: 'transparent',
		},
	},
}));

export interface Props {
	className?: string;
	message?: string;
	onClose?: () => void;
	variant: keyof typeof variantIcon;
}

function MySnackbarContentWrapper(props: Props) {
	const classes = useStyles1();
	const { className, message, onClose, variant, ...other } = props;
	const Icon = variantIcon[variant];

	return (
		<SnackbarContent
			className={clsx(classes[variant], className)}
			aria-describedby="toast-message"
			message={
				<span id="toast-message" className={classes.message}>
					<i className={clsx(classes.icon, classes.iconVariant, 'material-icons')}>{Icon}</i>
					{message}
				</span>
			}
			action={[
				<Fab className={classes.closeBtn} size="small" key="close" aria-label="close" color="inherit" onClick={onClose}>
					<i className={clsx(classes.icon, 'material-icons')}>close</i>
				</Fab>,
			]}
			{...other}
		/>
	);
}

const ToastMessage: React.FC<IProps> = () => {
	const { toast } = useSelector<TReduxStore, Pick<TAppState, 'toast'>>(({ App }) => ({ toast: App.toast }));
	const dispatch = useDispatch<TDispatch>();

	const handleClose = (event?: SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		dispatch(OApp.closeToast());
	};

	return (
		<div>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={toast.open}
				autoHideDuration={2500}
				onClose={handleClose}
			>
				<MySnackbarContentWrapper onClose={handleClose} variant={toast.variant} message={toast.message} />
			</Snackbar>
		</div>
	);
};

export default ToastMessage;
