import { JSONType } from 'typings/global';
import { request } from 'Resources/AxiosUtils';
import { parseToElite } from './EliteParsers';
import { Params } from 'typings/global';
import { Elite } from './@types';

class EliteModel {
	static getAllElites = async (params?: Params): Promise<Elite[]> => {
		const res = await request({
			url: '/Elites',
			method: 'GET',
			params,
		});
		return res.map(parseToElite);
	};
	static getSingleElite = async (id: string, params?: Params): Promise<Elite> => {
		const res = await request({
			url: `/Elites/${id}`,
			method: 'GET',
			params,
		});
		return parseToElite(res);
	};
	static addElite = async (userId: string, tagId: string): Promise<Elite> => {
		const res = await request<Elite>({
			url: '/Elites',
			method: 'POST',
			data: { userId, tagId },
		});
		return parseToElite(res);
	};
	static deleteElite = async (id: string): Promise<{ count: number }> => {
		return request<{ count: number }>({ url: `/Elites/${id}`, method: 'DELETE' });
	};
	static reorderElite = (id: string, order: number) => {
		return request<JSONType>({
			url: 'Elites/change-order',
			method: 'PATCH',
			data: { id, order },
		});
	};
}
export default EliteModel;
