import React, { FC } from 'react';
import { ListDocument, ExploreList } from 'Models/ExploreList/@types';
import { Card, Typography, createStyles, makeStyles, Theme } from '@material-ui/core';
import { MicroCourse } from 'Models/MicroCourse/@types';
import { TPost } from 'Models/Post/@types';
import { colorMap } from 'Resources/constants';
import { TUser } from 'Models/User/@types';

interface ListDocumentCardProps {
	listDocument: ListDocument;
	color: ExploreList['color'];
}

const ListDocumentCard: FC<ListDocumentCardProps> = (props) => {
	const { listDocument, color = 'indigo' } = props;

	const classes = useStyles();
	return <Card className={classes.root} style={{ backgroundColor: colorMap[color].light }} >
		<Typography className={classes.whiteText} variant={'subtitle1'} > {getName(listDocument)} </Typography>
	</Card>;
};

export default ListDocumentCard;

const useStyles = makeStyles<Theme>((theme) => createStyles({
	root: {
		maxWidth: 250,
		padding: '1rem',
		marginRight: '1rem',
		minWidth: 215
	},
	whiteText: {
		color: theme.palette.primary.contrastText
	}
}));

const getName = (listDocument: ListDocument) => {
	const { subject, subjectType } = listDocument;
	switch (subjectType) {
		case 'user':
		case 'MicroCourse':
			return (subject as MicroCourse | TUser).name;
		case 'Post':
			return (subject as TPost).title;
		default:
			return (subject as MicroCourse).name;
	}
};