import { action, Action, thunk, Thunk } from 'easy-peasy';
import { Company, CompanyListicle } from 'Models/Company/@types';
import { TRootStore } from 'Stores';
import { Params } from 'typings/global';
import CompanyModel from 'Models/Company';
import { Job } from 'Models/JobListing/@types';
import JobListingModel from 'Models/JobListing';

export interface CompanyState {
	companiesCount: number;
	companyListiclesCount: number;
	allCompanies: Company[];
	company?: Company;
	companyListicles: CompanyListicle[];
	companyListicle?: CompanyListicle;

	getCompaniesCount: Thunk<CompanyState, { params?: Params }, unknown, TRootStore, Promise<number>>;
	getAllCompanies: Thunk<CompanyState, { params?: Params }, unknown, TRootStore, Promise<Company[]>>;
	getCompany: Thunk<CompanyState, { id: string; params?: Params }, unknown, TRootStore, Promise<Company>>;
	deleteCompany: Thunk<CompanyState, string, unknown, TRootStore, Promise<boolean>>;
	getCompanyListicles: Thunk<CompanyState, { params?: Params }, unknown, TRootStore, Promise<CompanyListicle[]>>;
	createCompany: Thunk<CompanyState, Partial<Company>, unknown, TRootStore, Promise<Company>>;
	updateCompany: Thunk<CompanyState, { id: string; companyData: Partial<Company> }, unknown, TRootStore, Promise<Company>>;

	getCompanyListicle: Thunk<CompanyState, { id: string; params?: Params }, unknown, TRootStore, Promise<CompanyListicle>>;
	getCompanyListiclesCount: Thunk<CompanyState, { params?: Params }, unknown, TRootStore, Promise<number>>;
	deleteCompanyListicle: Thunk<CompanyState, string, unknown, TRootStore, Promise<boolean>>;
	createCompanyListicle: Thunk<CompanyState, Partial<CompanyListicle>, unknown, TRootStore, Promise<CompanyListicle>>;
	updateCompanyListicle: Thunk<CompanyState, { id: string; listicleData: Partial<CompanyListicle> }, unknown, TRootStore, Promise<CompanyListicle>>;
	togglePublishCompanyListicle: Thunk<CompanyState, { id: string; isPublished: boolean }, unknown, TRootStore, Promise<CompanyListicle>>;

	createJob: Thunk<CompanyState, Partial<Job>, unknown, TRootStore, Promise<Job>>;
	updateJob: Thunk<CompanyState, { id: string; data: Partial<Job> }, unknown, TRootStore, Promise<Job>>;
	deleteJob: Thunk<CompanyState, string, unknown, TRootStore, Promise<boolean>>;

	setCompany: Action<CompanyState, Company | undefined>;
	setAllCompanies: Action<CompanyState, Company[]>;
	setCompaniesCount: Action<CompanyState, number>;

	setCompanyListicles: Action<CompanyState, CompanyListicle[]>;
	setCompanyListicle: Action<CompanyState, CompanyListicle | undefined>;
	setCompanyListiclesCount: Action<CompanyState, number>;
}

const CompanyStore: CompanyState = {
	companiesCount: 0,
	companyListiclesCount: 0,
	allCompanies: [],
	company: undefined,
	companyListicle: undefined,
	companyListicles: [],
	getCompany: thunk(async (actions, { id, params }) => {
		const company = await CompanyModel.getCompany(id, params);
		actions.setCompany(company);
		return company;
	}),
	getCompaniesCount: thunk(async (actions, { params }) => {
		const count = await CompanyModel.getCount(params);
		actions.setCompaniesCount(count);
		return count;
	}),
	getAllCompanies: thunk(async (actions, { params }) => {
		const companies = await CompanyModel.getAllCompanies(params);
		actions.setAllCompanies(companies);
		return companies;
	}),

	createCompany: thunk(async (actions, companyData, { getState }) => {
		const { allCompanies } = getState();
		const company = await CompanyModel.createCompany(companyData);
		actions.setAllCompanies([...allCompanies, company]);
		actions.setCompany(company);
		return company;
	}),
	updateCompany: thunk(async (actions, { id, companyData }) => {
		const company = await CompanyModel.updateCompany(id, companyData);
		actions.setCompany(company);
		return company;
	}),
	deleteCompany: thunk(async (actions, id, { getState }) => {
		const { company } = getState();
		const { count } = await CompanyModel.deleteCompany(id);
		if (count && company && company.id === id) {
			actions.setCompany(undefined);
		}
		return !!count;
	}),

	getCompanyListicles: thunk(async (actions, { params }) => {
		const companyListicles = await CompanyModel.getCompanyListicles(params);
		actions.setCompanyListicles(companyListicles);
		return companyListicles;
	}),
	getCompanyListicle: thunk(async (actions, { id, params }) => {
		const companyListicle = await CompanyModel.getCompanyListicle(id, params);
		actions.setCompanyListicle(companyListicle);
		return companyListicle;
	}),
	getCompanyListiclesCount: thunk(async (actions, { params }) => {
		const count = await CompanyModel.getCompanyListiclesCount(params);
		actions.setCompaniesCount(count);
		return count;
	}),
	deleteCompanyListicle: thunk(async (actions, id, { getState }) => {
		const { count } = await CompanyModel.deleteCompanyListicle(id);
		const { companyListicle } = getState();
		if (count && companyListicle && companyListicle.id === id) {
			actions.setCompanyListicle(undefined);
		}
		return !!count;
	}),
	createCompanyListicle: thunk(async (actions, listicleData, { getState }) => {
		const companyListicle = await CompanyModel.createCompanyListicle(listicleData);
		const { companyListicles } = getState();
		actions.setCompanyListicles([...companyListicles, companyListicle]);
		return companyListicle;
	}),
	updateCompanyListicle: thunk(async (actions, { id, listicleData }) => {
		const companyListicle = await CompanyModel.updateCompanyListicle(id, listicleData);
		actions.setCompanyListicle(companyListicle);
		return companyListicle;
	}),
	togglePublishCompanyListicle: thunk(async (_, { id, isPublished }) => {
		const updatedCompanyListicle = await CompanyModel.changePublishCompanyListicle(id, !isPublished);
		return updatedCompanyListicle;
	}),

	createJob: thunk(async (actions, jobData, { getState }) => {
		const { company } = getState();
		const jobListing = await JobListingModel.createJobListing(jobData);
		if (company) actions.setCompany({ ...(company ?? {}), jobListings: [...(company?.jobListings ?? []), jobListing] });
		return jobListing;
	}),

	updateJob: thunk(async (actions, { id, data }, { getState }) => {
		const { company } = getState();
		const updatedJobListing = await JobListingModel.updateJobListing(id, data);
		if (company)
			actions.setCompany({
				...company,
				jobListings: company.jobListings?.map((jobListing) => ({ ...(jobListing.id !== id ? jobListing : updatedJobListing) })) ?? [],
			});
		return updatedJobListing;
	}),
	deleteJob: thunk(async (actions, id, { getState }) => {
		const { company } = getState();

		const { count } = await JobListingModel.deleteJobListing(id);
		if (company)
			actions.setCompany({
				...company,
				jobListings: company.jobListings?.filter((jobListing) => jobListing.id !== id) ?? [],
			});
		return !!count;
	}),

	setCompany: action((state, payload) => {
		state.company = payload;
	}),
	setAllCompanies: action((state, payload) => {
		state.allCompanies = payload;
	}),
	setCompaniesCount: action((state, payload) => {
		state.companiesCount = payload;
	}),
	setCompanyListicles: action((state, payload) => {
		state.companyListicles = payload;
	}),
	setCompanyListiclesCount: action((state, payload) => {
		state.companyListiclesCount = payload;
	}),
	setCompanyListicle: action((state, payload) => {
		state.companyListicle = payload;
	}),
};

export default CompanyStore;
