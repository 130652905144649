import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core';
import React, { Component } from 'react';
import Pagination from 'react-js-pagination';

export interface PaginationComponentProps extends WithStyles<typeof STYLES> {
	onPageChange?: (pageNumber: number) => void;
	currentPage: number;
	totalItemsCount?: number;
	pageRangeDisplayed?: number;
	itemsCountPerPage: number;
}

interface IState {
	activePage: number;
}

class PaginationComponent extends Component<PaginationComponentProps, IState> {
	constructor(props: PaginationComponentProps) {
		super(props);
		this.state = {
			activePage: 1,
		};
	}

	// shouldComponentUpdate(nextProps) {
	//     if (!_.isEqual(this.props.totalItemsCount, nextProps.totalItemsCount) || !_.isEmpty(this.props.handlePageChange, nextProps.handlePageChange))
	//         return true
	//     return true
	// }

	componentDidMount = () => {
		this.setState({ activePage: this.props.currentPage });
	};

	handlePageChange = (pageNumber: number) => {
		this.setState({ activePage: pageNumber });
		if (typeof this.props.onPageChange === 'function') this.props.onPageChange(pageNumber);
	};
	render() {
		const { classes, totalItemsCount = 0, pageRangeDisplayed = 3, itemsCountPerPage } = this.props;
		if (totalItemsCount < 1) {
			return null;/* <div>NO items</div> */;
		}
		return (
			<Pagination
				innerClass={classes.ul}
				activeClass={classes.activeLi}
				itemClass={classes.li}
				activePage={this.state.activePage}
				itemsCountPerPage={itemsCountPerPage}
				totalItemsCount={totalItemsCount}
				pageRangeDisplayed={pageRangeDisplayed}
				onChange={this.handlePageChange}
				hideFirstLastPages={true}
			/>
		);
	}
}

const STYLES = (theme: Theme) =>
	createStyles({
		ul: {
			display: 'flex',
			listStyle: 'none',
			justifyContent: 'space-around',
			alignItems: 'center',
			margin: 0,
			padding: '20px 70px',
		},
		li: {
			borderRadius: 2,
			'& > a': {
				fontFamily: theme.typography.fontFamily,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: 30,
				width: 40,
				color: theme.palette.grey['100'],
			},
		},
		activeLi: {
			backgroundColor: theme.palette.primary.main,
			'& > a': {
				color: theme.palette.common.white,
			},
		},
	});

export default withStyles(STYLES)(PaginationComponent);
