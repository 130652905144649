import { AppBar, Button, CircularProgress, createStyles, Dialog, IconButton, Theme, Toolbar, Typography, withStyles } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';

export interface FullScreenDialogProps extends WithStyles<typeof STYLES> {
	open: boolean;
	onClose?: () => void;
	onSubmit: () => void;
	heading: string;
	loading?: boolean;
}

export class FullScreenDialog extends Component<FullScreenDialogProps> {
	render() {
		const { classes, open, loading } = this.props;
		return (
			<Dialog fullScreen open={open} classes={{ paper: classes.dialogBoxPaper, scrollPaper: classes.scrollPaper }} onClose={this.props.onClose}>
				<ValidatorForm onSubmit={this.props.onSubmit} ref="form">
					<AppBar position="sticky" className={classes.appBar}>
						<Toolbar>
							<IconButton onClick={this.props.onClose} className={classes.closeBtn}>
								<i className="material-icons">close</i>
							</IconButton>
							<Typography className={classes.formTitle}>
								<b>{this.props.heading}</b>
							</Typography>
							<Button className={classes.saveBtn} type="submit" disabled={loading}>
								{loading ? <CircularProgress color="inherit" thickness={5} size={24} /> : 'Submit'}
							</Button>
						</Toolbar>
					</AppBar>
					<div className={classes.formContent}>{this.props.children}</div>
				</ValidatorForm>
			</Dialog>
		);
	}
}

const STYLES = (theme: Theme) =>
	createStyles({
		appBar: {},
		formTitle: {
			flex: 1,
			color: theme.palette.common.white,
			marginLeft: 20,
			// lineHeight: '16px',
			padding: '0 8px',
			fontWeight: theme.typography.fontWeightBold,
		},
		formContent: {
			maxWidth: 960,
			margin: '64px auto 0px auto',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
		},
		saveBtn: {
			color: theme.palette.common.white,
			fontWeight: theme.typography.fontWeightBold,
		},
		closeBtn: {
			color: theme.palette.common.white,
		},
		dialogBoxPaper: {
			background: '#FAFAFA',
			zIndex: 999,
			position: 'relative',
		},
		scrollPaper: {
			zIndex: 999,
			position: 'relative',
		},
	});

export default withStyles(STYLES)(FullScreenDialog);
