import React, { FC } from "react";
import AppDialogProvider from "Components/Dialogs/AppDialogProvider";
import { StoreProvider } from 'easy-peasy';
import store from 'Stores';
import AppToastProvider from 'Components/Toast/AppToast';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MuiTheme from 'MuiTheme';



const RootProvider: FC = ({ children }) => {
	return <BrowserRouter>
		<MuiThemeProvider theme={MuiTheme}>
			<AppToastProvider>
				<AppDialogProvider>
					<StoreProvider store={store} >
						{children}
					</StoreProvider>
				</AppDialogProvider>
			</AppToastProvider>
		</MuiThemeProvider>
	</BrowserRouter>;
};

export default RootProvider;