import React, { FC } from "react";
import { ExploreHome } from "Models/ExploreHome/@types";
import { TUser } from "Models/User/@types";
import SpotlightCard from "./SpotlightCard";

interface CuratorSpotlightCardProps {
	exploreHomeItem: ExploreHome;
	color: ExploreHome['color'];
	spotlightDescription: string;
	dragHandleProps: any;
	deleteItem: (item: ExploreHome) => void;
	editItem: (item: ExploreHome) => void;
}

const CuratorSpotlightCard: FC<CuratorSpotlightCardProps> = (props) => {
	const { color = 'indigo', spotlightDescription, deleteItem, exploreHomeItem, editItem, dragHandleProps } = props;
	const { subject: curator } = exploreHomeItem;
	const { name } = curator as TUser;
	return <SpotlightCard onDelete={() => deleteItem(exploreHomeItem)} onEdit={() => editItem(exploreHomeItem)} title={name} subtitle={spotlightDescription} color={color} dragHandleProps={dragHandleProps} />;;
};

export default CuratorSpotlightCard;