import { JSONType } from "typings/global";
import { Asset } from "./@types";

export const parseAsset = (data: JSONType): Asset => {
	const { id, name, url, mimetype, size, ...rest } = data;
	return {
		id,
		name,
		mimeType: mimetype,
		size,
		url,
		...rest,
	} as Asset;
};
