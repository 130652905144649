
let STAGING_API_URL = 'https://chetstaging-api.mithyalabs.com/api/';
let PRODUCTION_API_URL = 'https://api.chetnetwork.com/api/';

let API_BASE_URL: string = process.env.REACT_APP_NODE_ENV || STAGING_API_URL;

switch (process.env.REACT_APP_NODE_ENV) {
	case 'staging':
		API_BASE_URL = STAGING_API_URL;
		break;
	case 'production':
		API_BASE_URL = PRODUCTION_API_URL;
		break;
	default: ;
}

const config = {
	API_BASE_URL
};

export default config;