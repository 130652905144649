import { Button, CircularProgress, Collapse, createStyles, FormControl, Theme, WithStyles, withStyles } from '@material-ui/core';
import { AxiosPromise } from 'axios';
import { TAction } from 'loop-front';
import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import Loader from '../../Components/Loader';
import { OApp } from '../../Models/App';
import { TToastConfig } from '../../Models/App/@types';
import { TLoginFormData } from '../../Models/Auth/@types';
import { TUser } from '../../Models/User/@types';
import { TReduxStore } from '../../RootReducer';
import { userLogin } from './redux-config';

interface IState {
	formData: TLoginFormData;
	loading: boolean;
	splash: boolean;
}

interface IStateProps {
	appUser?: TUser;
}
interface IDispatchProps {
	login: (formData: TLoginFormData) => AxiosPromise;
	showToast: (toastConfig: Partial<TToastConfig>) => void;
	appInitialize: () => Promise<boolean>;
}

// eslint-disable-next-line
interface Props extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps { }

class LandingPage extends Component<Props, IState> {
	constructor(props: Props) {
		super(props);
		this.state = {
			formData: {
				email: '',
				password: '',
			},
			loading: false,
			splash: true,
		};
	}

	async componentDidMount() {
		const res = await this.props.appInitialize();
		if (!res) {
			this.setState({ splash: false });
		}
	}

	handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		this.setState({ formData: { ...this.state.formData, [event.target.name]: event.target.value } });
	};
	handleSubmit = async () => {
		this.setState({ loading: true });
		await this.props.login(this.state.formData).catch((error) => {
			this.setState({ loading: false });
			this.props.showToast({ message: 'Invalid Credentials', variant: 'ERROR' });
			throw error;
		});
	};
	render() {
		const { classes, appUser } = this.props;
		const { formData, loading, splash } = this.state;
		if (appUser) return <Redirect to={'/dashboard'} />;
		return (
			<div>
				<div className={classes.landingPageContainer}>
					<div className={classes.formContainer}>
						<Collapse in={splash}>
							<Loader />
						</Collapse>
						<Collapse in={!splash}>
							<ValidatorForm ref="form" onSubmit={this.handleSubmit}>
								<FormControl fullWidth className={classes.formControl}>
									<TextValidator
										label="Email"
										onChange={this.handleChange}
										name="email"
										validators={['required', 'isEmail']}
										errorMessages={['Required', 'Email is not valid']}
										fullWidth
										value={formData.email}
									/>
								</FormControl>
								<FormControl fullWidth className={classes.formControl}>
									<TextValidator
										label="Password"
										onChange={this.handleChange}
										name="password"
										type="password"
										validators={['required']}
										errorMessages={['Required']}
										fullWidth
										value={formData.password}
									/>
								</FormControl>
								<Button type="submit" fullWidth variant="contained" color="primary" size="large" disabled={loading}>
									{loading ? <CircularProgress thickness={5} color="inherit" /> : 'Submit'}
								</Button>
							</ValidatorForm>
						</Collapse>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: TReduxStore): IStateProps => ({
	appUser: state.User.appUser,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<TReduxStore, {}, TAction>): IDispatchProps => ({
	login: (formData) => dispatch(userLogin(formData)),
	showToast: (toastConfig) => dispatch(OApp.showToast(toastConfig)),
	appInitialize: () => dispatch(OApp.init()),
});

const STYLES = (theme: Theme) =>
	createStyles({
		landingPageContainer: {
			position: 'absolute',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			display: 'flex',
			alignItems: 'center',
		},
		logoContainer: {
			marginBottom: 30,
			'& > img': {
				maxWidth: '100%',
			},
		},
		formContainer: {
			maxWidth: 300,
			margin: '0 auto',
		},
		formControl: {
			marginBottom: 30,
		},
	});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(LandingPage));
