import { Fab } from '@material-ui/core';
import React, { FC, useState } from 'react';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog';

export interface DeleteButtonProps {
	backgroundColor?: string;
	iconColor?: string;
	handleDelete: () => void;
	alertMessage?: string;
	size?: 'small' | 'medium' | 'large';
	raised?: boolean;
}

const DeleteButton: FC<DeleteButtonProps> = (props) => {
	const { handleDelete, alertMessage = 'Do you really want to delete this item?', raised = false, size = 'small' } = props;
	const [dialog, setDialog] = useState(false);

	const onAgree = () => {
		handleDelete();
		toggleDialog();
	};

	const toggleDialog = () => setDialog((dialog) => !dialog);

	return (
		<div>
			<Fab
				size={size}
				style={
					!raised
						? {
								boxShadow: 'none',
						  }
						: {}
				}
				onClick={toggleDialog}
			>
				<i className="material-icons">delete</i>
			</Fab>
			<ConfirmationDialog open={dialog} onAgree={onAgree} onDisagree={toggleDialog} message={alertMessage} onClose={toggleDialog} />
		</div>
	);
};

export default DeleteButton;
