import { TLoginFormData, TAuthResponse } from '../../Models/Auth/@types';
import { ThunkAction } from 'redux-thunk';
import { AxiosResponse, AxiosPromise } from 'axios';
import { TReduxStore } from '../../RootReducer';
import LoopFront, { TAction } from 'loop-front';
import { OUser } from '../../Models/User';
import utilities from '../../Resources/Utils';
import { OTag } from '../../Models/Tag';
import { TUser } from 'Models/User/@types';
import AxiosUtils from 'Resources/AxiosUtils';

export const userLogin =
	(formData: TLoginFormData): ThunkAction<AxiosPromise<TAuthResponse>, TReduxStore, {}, TAction> =>
	async (dispatch, getState) => {
		const authRes = (await dispatch(OUser.postActivity(OUser.Activites.LOGIN, formData)).catch((error) => {
			console.log('Login Error', error);
			throw error;
		})) as AxiosResponse<TAuthResponse>;
		const access_token = authRes.data.id;
		const userId = authRes.data.userId;
		const userRes = await LoopFront.request({
			url: '/users/me',
			params: {
				userFilter: { include: ['roles'] },
			},
			method: 'GET',
			headers: {
				Authorization: access_token,
			},
		});
		if (!!(userRes.data as TUser).roles.find((role) => role.name === 'ADMIN')) {
			// DO NOT LOGIN NON ADMIN USER
			dispatch(OTag.fetchTagCategories());
			utilities.saveUser(access_token, userId);
			LoopFront.setAuthHeader(authRes.data.id);
			dispatch({ type: OUser.Actions.APP_USER_RECEIVED, data: userRes.data });
			AxiosUtils.setHeader('Authorization', access_token);
		} else {
			throw new Error('Wrong credentials or user is not admin');
		}
		return authRes;
	};
