import { createStore, createTypedHooks, State } from 'easy-peasy';
import _isEqual from 'lodash/isEqual';
import Elite from './Elite';
import Companies from 'Stores/Companies';
import Auth from 'Stores/Auth';
import App from 'Stores/App';

const RootStore = {
	Auth,
	App,
	Elite,
	Companies,
};

const typedHooks = createTypedHooks<TRootStore>();

export type TRootStore = typeof RootStore;
export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = <Result>(a: (state: State<TRootStore>) => Result, b?: any) => typedHooks.useStoreState(a, b || _isEqual);

export default createStore(RootStore, {
	//Put your dependency injections here
});
