import { FC, Suspense, useState } from 'react';
import { makeStyles, Drawer, MenuItem, LinearProgress, Menu, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useLocation, Route, Redirect, useRouteMatch } from 'react-router';
import { NavLink, Link, Switch } from 'react-router-dom';
import { OUser } from '../../Models/User';
import ChetTitleLogo from 'Assets/Images/chet.svg';
import lazyImport from 'lazy-import';
import { request } from 'Resources/AxiosUtils';
import useAsyncTask from 'Hooks/useAsyncTask';

const Users = lazyImport('Screens/Users');
const Teams = lazyImport('Screens/Teams');
const Posts = lazyImport('Screens/Posts');
const Tags = lazyImport('Screens/Tags');
const Settings = lazyImport('Screens/Settings');
const MicroCourses = lazyImport('Screens/MicroCourse');
const ExploreLists = lazyImport('Screens/ExploreLists');
const CustomiseExplore = lazyImport('Screens/CustomiseExplore');
const CustomizeElite = lazyImport('Screens/CustomiseElite');
const Pathway = lazyImport('Screens/Pathway');
const CompaniesAndLists = lazyImport('Screens/CompaniesAndLists');

const PRIMARY_DRAWER_WIDTH = 150;

interface DashboardBodyProps {}

const DashboardBody: FC<DashboardBodyProps> = (props) => {
	const classes = styles();
	const { url: matchUrl } = useRouteMatch();

	const location = useLocation();
	const dispatch = useDispatch();

	const logout = async () => {
		dispatch(OUser.logout());
	};

	const regenerateFeed = async (listType: string) => {
		await request({
			url: 'userFeeds/generate-feed',
			method: 'POST',
			data: {
				userId: '*',
				listType,
				persist: false,
			},
		});
	};
	const regenerateFeedTask = useAsyncTask(regenerateFeed);
	const [menuAnchor, setMenuAnchor] = useState<HTMLLIElement | null>(null);

	return (
		<div>
			<Drawer
				variant="permanent"
				classes={{
					paper: classes.primaryPanelPaper,
				}}
				open={true}
			>
				<Link to="/" className={classes.logoContainer}>
					<img alt={'Chet Logo'} className={classes.logo} src={ChetTitleLogo} />
				</Link>

				<div className={classes.primaryPanelUpperSection}>
					{MENU_ITEM_NAV_LINKS.map((menu) => {
						let selectedClass = menu.url.split('/')[0] === location.pathname.split('/')[2] ? true : null;
						return (
							<NavLink key={menu.url} exact to={`${matchUrl}/${menu.url}`}>
								<MenuItem className={selectedClass ? classes.selectedClass : ''}>{menu.name}</MenuItem>
							</NavLink>
						);
					})}
				</div>
				{
					<div className={classes.primaryPanelLowerSection}>
						<MenuItem disabled={regenerateFeedTask.status === 'PROCESSING'} onClick={(event) => setMenuAnchor(event.currentTarget)}>
							{regenerateFeedTask.status === 'PROCESSING' ? (
								<>
									<CircularProgress size={28} /> Regen...
								</>
							) : (
								'REGENERATE LISTS'
							)}
						</MenuItem>
						<Link to="#" onClick={logout}>
							<MenuItem>Sign Out</MenuItem>
						</Link>
					</div>
				}
			</Drawer>
			<Menu
				open={!!menuAnchor}
				anchorEl={menuAnchor}
				anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
				onClose={() => setMenuAnchor(null)}
			>
				{lists.map((list) => (
					<MenuItem
						key={list.value}
						onClick={async () => {
							regenerateFeedTask.run(list.value);
							setMenuAnchor(null);
						}}
					>
						{list.name}
					</MenuItem>
				))}
			</Menu>
			<div className={classes.dashboardContent}>
				<Suspense fallback={<LinearProgress />}>
					<Switch>
						<Route exact path="/dashboard" render={() => <Redirect to="/dashboard/posts" />} />
						{MENU_ITEM_NAV_LINKS.map((nav) => {
							return <Route key={nav.url} path={`${matchUrl}/${nav.path || nav.url}`} component={nav.component} />;
						})}
					</Switch>
				</Suspense>
			</div>
		</div>
	);
};

export default DashboardBody;

const styles = makeStyles((theme) => ({
	primaryPanelPaper: {
		backgroundColor: theme.palette.grey['100'],
		width: PRIMARY_DRAWER_WIDTH,
		'& > div li': {
			color: 'white',
			fontSize: '14px',
			fontWeight: theme.typography.fontWeightBold,
			lineHeight: '32px',
		},
	},
	primaryPanelUpperSection: {
		minHeight: 400,
	},
	primaryPanelLowerSection: {
		marginTop: 60,
	},
	dashboardContent: {
		marginLeft: '150px',
	},
	MenuItem: {
		padding: 0,
		height: 30,
		paddingLeft: 16,
	},
	selectedClass: {
		backgroundColor: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	logoContainer: {
		width: '100%',
		justifyContent: 'center',
		display: 'flex',
		height: 50,
		marginBottom: 30,
		transition: '500ms',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	logo: {
		width: '60%',
		marginLeft: 10,
		marginRight: 'auto',
	},
}));

const MENU_ITEM_NAV_LINKS = [
	{
		name: 'Posts',
		url: 'posts',
		path: 'posts',
		component: Posts,
	},
	{
		name: 'Topics',
		url: 'topics',
		path: 'topics',
		component: Tags,
	},
	{
		name: 'Microcourse',
		url: 'MicroCourses',
		path: 'microCourses/:id?',
		component: MicroCourses,
	},
	{
		name: 'Pathways',
		url: 'pathways',
		path: 'pathways/:id?',
		component: Pathway,
	},
	{
		name: 'Users',
		url: 'users',
		path: 'users/:id?',
		component: Users,
	},
	{
		name: 'Teams',
		url: 'teams',
		path: 'teams/:id?',
		component: Teams,
	},
	{
		name: 'Settings',
		url: 'setting',
		path: 'setting',
		component: Settings,
	},
	{
		name: 'Lists',
		url: 'exploreLists',
		path: 'exploreLists/:id?',
		component: ExploreLists,
	},
	{
		name: 'Explore',
		url: 'customiseExplore',
		path: 'customiseExplore',
		component: CustomiseExplore,
	},
	{
		name: 'Elite',
		url: 'Elite',
		path: 'elite',
		component: CustomizeElite,
	},
	{
		name: 'Companies & lists',
		url: 'Companies-listicles',
		path: 'companies-listicles',
		component: CompaniesAndLists,
	},
];

const lists = [
	{
		name: 'All',
		value: '*',
	},
	{
		name: 'Discover',
		value: 'discover',
	},
	{
		name: 'Popular curators',
		value: 'popularCurators3',
	},
	{
		name: 'Newest collections and links',
		value: 'newestCollectionAndLinks',
	},
	{
		name: 'Tags you might be interested in',
		value: 'tagsYouMightBeInterestedIn',
	},
];
