import axios from "axios";
import { AxiosError, AxiosRequestConfig } from "axios";

export const request = async <T = Record<string, any>>(
  config: AxiosRequestConfig,
  log = true
): Promise<T> => {
  if (!axios.defaults.baseURL) {
    throw new Error("Error: Base Url is not provided");
  }
  const resp = await axios.request<T>(config);
  return resp.data;
};

const AxiosUtils = {
  setBaseAPI_URL: (url: string) => (axios.defaults.baseURL = url),

  setHeader: (type = "Content-Type", value = "application/json") =>
    (axios.defaults.headers.post[type] = value),

  setAuthHeader: (access_token?: string) =>
    (axios.defaults.headers.common["Authorization"] = access_token),

  throwError: (error: AxiosError) => {
    console.error("Error", error.response);
    throw error;
  },
};

export default AxiosUtils;
