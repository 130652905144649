import { Reducer } from 'redux';
import { ExploreHomeState } from './@types';
import { TAction } from 'loop-front';
import { OExploreHome } from '.';
import { parseExploreHome } from './ExploreHomeParsers';
import utilities from 'Resources/Utils';
import { ITEMS_PER_REQUEST } from 'Screens/CustomiseExplore';

const initialState: ExploreHomeState = {
	list: [],
	showMainForm: false,
	editingItem: undefined,
	hasMoreItems: true,
};

export const RExploreHome: Reducer<ExploreHomeState, TAction> = (state = initialState, action): ExploreHomeState => {
	const {
		LIST_RECEIVED,
		CHANGE_ORDER,
		DELETE_LIST_ITEMS,
		SHOW_MAIN_FORM,
		HIDE_MAIN_FORM,
		ADD_LIST_ITEM,
		POST_ITEM_SUCCESS,
		SET_EDITING_ITEM,
		UPDATE_LIST_ITEM,
	} = OExploreHome.Actions;

	switch (action.type) {
		case LIST_RECEIVED:
			if (action.data.updateExistingList)
				return {
					...state,
					list: [...state.list, ...action.data.data?.map(parseExploreHome)],
					hasMoreItems: !!action.data.data.length && action.data.data.length === ITEMS_PER_REQUEST,
				};
			return {
				...state,
				list: action.data.data?.map(parseExploreHome),
				hasMoreItems: !!action.data.data.length && action.data.data.length === ITEMS_PER_REQUEST,
			};
		case CHANGE_ORDER:
			return { ...state, list: action.data };
		case DELETE_LIST_ITEMS:
			return { ...state, list: action.data };
		case SHOW_MAIN_FORM:
			return { ...state, showMainForm: true };
		case HIDE_MAIN_FORM:
			return { ...state, showMainForm: false };
		case ADD_LIST_ITEM:
			return { ...state, list: [...state.list, parseExploreHome(action.data)] };
		case POST_ITEM_SUCCESS:
			return state;
		case SET_EDITING_ITEM:
			return { ...state, editingItem: action.data };
		case UPDATE_LIST_ITEM:
			return { ...state, list: utilities.updateItemList(state.list, action.data, 'UPDATE') };
		default:
			return state;
	}
};
