import { JSONType } from 'typings/global';
import { Company, CompanyListicle } from './@types';

export const parseToCompany = (obj: JSONType): Company => {
	return { ...obj } as Company;
};

export const parseToCompanyListicle = (obj: JSONType): CompanyListicle => {
	return { ...obj } as CompanyListicle;
};

export const parseCompanyListicleForm = (obj: Partial<CompanyListicle>): JSONType => {
	const { companies, ...rest } = obj;
	return { ...rest, companyIds: companies?.map((company) => company.id) ?? [] };
};
