import LoopFront from 'loop-front';
import { TDispatch } from 'Models/App/@types';

const CustomActions = {
	SHOW_LIST_FORM: 'SHOW_LIST_FORM',
	HIDE_LIST_FORM: 'HIDE_LIST_FORM',
	LIST_RECEIVED: 'LIST_RECEIVED',
	SET_SUBJECTS: 'SET_SUBJECTS',
	SET_EDITED_LIST: 'SET_EDITED_LIST',
	DELETE_LIST_DOCUMENT: 'DELETE_LIST_DOCUMENT',
	CREATED_NEW_EXPLORE_LIST: 'CREATED_NEW_EXPLORE_LIST',
	UPDATE_CURRENT_LIST: 'UPDATE_CURRENT_LIST',
	PUBLISH: 'publish',
	REORDER_LIST_DOCUMENTS: 'REORDER_LIST_DOCUMENTS',
};

const CustomEntities = {
	PUBLISH: 'publish',
};
const CustomActivities = {
	CREATE_NEW: '',
	// GET_SINGLE: '',
	DELETE_SINGLE: 'trash',
};

class ExploreList extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivities> {
	constructor() {
		super('exploreLists', CustomActions, CustomEntities, CustomActivities);
	}
	getExploreListCount = () => async (dispatch: TDispatch) => {
		try {
			await dispatch(OExploreList.getActivity(OExploreList.Activites.COUNT));
		} catch (error) {
			throw error;
		}
	};
}

export const OExploreList = new ExploreList();
