import { Badge, Box, Button, Chip, createStyles, Drawer, Fab, IconButton, makeStyles, Paper, Theme, Typography, useTheme } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TDispatch, TPostType } from '../../Models/App/@types';
import { OPost } from '../../Models/Post';
import { TPostListingFilter, TPostSort, TPostSortOrder } from '../../Models/Post/@types';
import { TUserListingFilter } from '../../Models/User/@types';
import { TReduxStore } from '../../RootReducer';

interface IProps {}

const Filter: React.FC<IProps> = (props) => {
	const theme = useTheme<Theme>();
	const classes = useStyles(theme);
	const [open, setOpen] = React.useState(false);
	const listingFilter = useSelector<TReduxStore, TUserListingFilter>((state) => state.User.currentAppliedFilter);

	const getBadge = () => {
		const filterKeys = Object.keys(listingFilter) as Array<keyof TUserListingFilter>;
		let count = 0;
		filterKeys.forEach((k) => {
			count += listingFilter[k].length;
		});
		return count;
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Fab size="small" onClick={handleClick}>
				<Badge badgeContent={getBadge()} color="primary">
					<i className="material-icons">filter_list</i>
				</Badge>
			</Fab>

			<Drawer open={open} anchor="top" onClose={(e) => handleClose()}>
				<Paper role="presentation" className={classes.filterRoot} square>
					<Box pt="8px" width="350px" overflow="auto" display="flex" flexDirection="column">
						<IconButton className={classes.closeBtn} onClick={(e) => handleClose()}>
							<i className="material-icons">close</i>
						</IconButton>
						<PostType />
						<DateRange />
						<Box width="350px" className={classes.actionContainer}>
							<ActionButtons close={() => handleClose()} />
						</Box>
					</Box>
				</Paper>
			</Drawer>
		</>
	);
};

function PostType() {
	// const config: Array<{ label: string, name: string, value: any }> = []
	const dispatch = useDispatch<TDispatch>();
	const listingFilter = useSelector<TReduxStore, TPostListingFilter>((state) => state.Post.listingFilter);
	const postTypes = useSelector<TReduxStore, TPostType[] | undefined>((state) => state.App.postTypes);
	if (!postTypes) return <div />;

	const config = postTypes.map((p) => ({ label: p.name, name: 'type', value: p.value }));

	const handleChange = (value: any) => {
		dispatch(OPost.setFilter('type', value));
	};

	const isSelected = (conf: typeof config[0]) => {
		return !!_.find(listingFilter.type, { name: conf.name, value: conf.value });
	};

	return (
		<Box p="8px">
			<Box my="0px">
				<Typography>
					<b>POST TYPE</b>
				</Typography>
			</Box>
			<Box display="flex" flexDirection="row" p="10px 0px" style={{ boxSizing: 'border-box', flexWrap: 'wrap' }}>
				{config.map((c) => (
					<Box display="inline-block" m="4px" key={c.label}>
						<Chip color={isSelected(c) ? 'primary' : 'default'} label={c.label} onClick={(e) => handleChange(c)} />
					</Box>
				))}
			</Box>
		</Box>
	);
}

function DateRange() {
	const dispatch = useDispatch<TDispatch>();
	const listingFilter = useSelector<TReduxStore, TPostListingFilter>((state) => state.Post.listingFilter);
	const startDate = useSelector<TReduxStore, string | undefined>((state) => state.Post.filterStartDate);
	const endDate = useSelector<TReduxStore, string | undefined>((state) => state.Post.filterEndDate);

	const config = OPost.dateOptions;
	const handleChange = (value: any) => {
		dispatch(OPost.setFilter('date', value));
	};

	const handleDateChange = (type: 'start' | 'end') => (date: MaterialUiPickersDate) => {
		if (!date) return;
		dispatch(OPost.setPostDateFilter(type, date.toISOString()));
	};

	const isSelected = (conf: typeof config[0]) => {
		return listingFilter.date === conf.key;
	};

	return (
		<Box p="8px">
			<Box my="0px">
				<Typography>
					<b>DATE</b>
				</Typography>
			</Box>
			<Box display="flex" flexDirection="row" p="10px 0px" style={{ boxSizing: 'border-box', flexWrap: 'wrap' }}>
				{config.map((c) => (
					<Box display="inline-block" m="4px" key={c.label}>
						<Chip color={isSelected(c) ? 'primary' : 'default'} label={c.label} onClick={(e) => handleChange(c.key)} />
					</Box>
				))}
				{listingFilter.date === 'range' ? (
					<Box mt="10px" mx="10px" display="flex" justifyContent="space-between">
						<Box mr="10px">
							<DatePicker label="Start date" variant="inline" autoOk value={startDate || null} onChange={handleDateChange('start')} />
						</Box>
						<DatePicker variant="inline" autoOk value={endDate || null} onChange={handleDateChange('end')} label="End date" />
					</Box>
				) : null}
			</Box>
		</Box>
	);
}

function ActionButtons(props: { close: () => void }) {
	const dispatch = useDispatch<TDispatch>();
	const listingFilter = useSelector<TReduxStore, TPostListingFilter>((state) => state.Post.listingFilter);
	const sortOrder = useSelector<TReduxStore, TPostSortOrder>((state) => state.Post.sortOrder);
	const sort = useSelector<TReduxStore, TPostSort>((state) => state.Post.sort);

	const apply = () => {
		dispatch(OPost.applySelectedFilter(listingFilter, sort, sortOrder));
		props.close();
	};

	const reset = () => {
		dispatch(OPost.resetFilter());
		props.close();
	};
	return (
		<Box display="flex">
			<Button size="large" style={{ borderRadius: 0 }} variant="contained" color="primary" onClick={(e) => apply()} fullWidth>
				Apply
			</Button>
			<Button size="large" style={{ borderRadius: 0 }} variant="contained" color="primary" onClick={(e) => reset()} fullWidth>
				Reset
			</Button>
		</Box>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		filterRoot: {
			position: 'fixed',
			right: 0,
			margin: 0,
			zIndex: 1500,
			top: 0,
			bottom: 0,
			paddingBottom: 50,
			overflow: 'auto',
			transition: '300ms ease-out',
		},
		actionContainer: {
			position: 'fixed',
			bottom: 0,
		},
		closeBtn: {
			position: 'absolute',
			right: 0,
			top: 0,
		},
	})
);

export default Filter;
