import { Reducer } from "redux";
import { TTagState, TFormType, TTagCategory, TTag } from './@types';
import { TAction } from 'loop-front';
import { OTag } from '.';
import _ from 'lodash';


const initialState: TTagState = {
    showMainForm: false,
    filteredList: [],
    listingFilter: [],
    currentAppliedFilter: [],
    totalCount: 0,
    formData: {}
}

export const RTag: Reducer<TTagState, TAction> = (state = initialState, action): TTagState => {
    const {
        LIST_RECEIVED,
        SHOW_TAG_FORM,
        CLOSE_TAG_FORM,
        SET_TAG_FILTER,
        APPLY_TAG_FILTER,
        RESET_TAG_FILTER,
        SET_APPLIED_FILTER
    } = OTag.Actions;


    switch (action.type) {
        case SET_APPLIED_FILTER: {
            return { ...state, currentAppliedFilter: action.data }
        }
        case RESET_TAG_FILTER: {
            return { ...state, filteredList: state.list || [], listingFilter: [], currentAppliedFilter: [] }
        }
        case APPLY_TAG_FILTER: {
            return { ...state, filteredList: action.data }
        }
        case SET_TAG_FILTER: {
            const listingFilter = _.find(state.listingFilter, { value: action.data.value }) ?
                [...state.listingFilter.filter(f => f.value !== action.data.value)] : [...state.listingFilter, action.data];
            return { ...state, listingFilter };

        }
        case SHOW_TAG_FORM: {
            const formFor = action.data.formType as TFormType;
            let selectedTagCategory: TTagCategory | undefined = undefined;
            let selectedTag: TTag | undefined = undefined;
            if (formFor === 'new tags' || formFor === 'update category')
                selectedTagCategory = action.data.editedItem;
            if (formFor === 'edit tag')
                selectedTag = action.data.editedItem

            return {
                ...state, showMainForm: true, formFor,
                selectedTagCategory,
                selectedTag
            }
        }
        case CLOSE_TAG_FORM: {
            return { ...state, showMainForm: false, formFor: undefined }
        }
        case LIST_RECEIVED: {
            return { ...state, list: action.data }
        }

        default: return state;
    }
}