import React, { FC } from 'react';
import { Select, MenuItem, Box, makeStyles, Theme, createStyles, Typography, FormControl, InputLabel } from '@material-ui/core';
import { colorMap } from 'Resources/constants';
import { FormikProps } from 'formik';
import { ColorMapKeys } from 'typings/global';

interface ColorSwatchPickerProps {
	formikProps: FormikProps<any>;
	valueKey: string;
}


const ColorSwatchPicker: FC<ColorSwatchPickerProps> = (props) => {
	const { formikProps, valueKey } = props;
	const classes = useStyles();
	const updateValue = (event: React.ChangeEvent<{
		name?: string | undefined;
		value: unknown | string;
	}>) => {
		const { value } = event.target;
		formikProps.setFieldValue(valueKey, value, true);
	};

	return <FormControl fullWidth>
		<InputLabel>Colour</InputLabel>
		<Select name={valueKey} onChange={updateValue} value={formikProps.values[valueKey] || ''}>
			<MenuItem value={''} disabled>Select</MenuItem>
			{(Object.keys(colorMap) as ColorMapKeys[]).map((color: ColorMapKeys) => <MenuItem key={color} value={color}>
				<Box display={'flex'} justifyContent={'space-between'} px={1} width={'100%'} >
					<Typography>{colorMap[color].label}</Typography>
					<Box display={'flex'}>
						<div className={classes.colorSwatch} style={{ backgroundColor: colorMap[color].light }} />
						<div className={classes.colorSwatch} style={{ backgroundColor: colorMap[color].dark }} /></Box>
				</Box>
			</MenuItem>)}
		</Select>
	</FormControl>;
};

export default ColorSwatchPicker;

const useStyles = makeStyles<Theme>((theme) => createStyles({
	colorSwatch: {
		width: 10, height: 20,
	}
}));