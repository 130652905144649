import { TAction } from 'loop-front';
import { TPathwayStep } from 'Models/PathwayStep/@types';
import { Reducer } from 'redux';
import utilities from 'Resources/Utils';
import { OPathway } from '.';
import { TPathwayState } from './@types';
import { parseToPathway } from './PathwayParsers';

const INITIAL_STATE: TPathwayState = {
	loading: false,
	loadingPathway: false,
	showPathwayStepForm: false,
	showMainForm: false,
	allPathways: [],
	count: 0,
};

let pathwayStepSnapshot: TPathwayStep[] = [];

export const RPathway: Reducer<TPathwayState, TAction> = (state = INITIAL_STATE, action): TPathwayState => {
	const {
		FETCHING_LIST,
		LIST_RECEIVED,
		ACTIVITY_RECEIVED,
		SHOW_FORM,
		HIDE_FORM,
		SHOW_PATHWAY_STEP_FORM,
		HIDE_PATHWAY_STEP_FORM,
		PATHWAYS_UPDATE_PATHWAY,
		PATHWAYS_NEW_PATHWAY,
		FETCHING_SINGLE_ITEM,
		SINGLE_ITEM_RECEIVED,
		ITEM_ACTIVITY_POST_SUCCESS,
		ITEM_DELETED,
		PATHWAY_STEP_DELETED,
		PATHWAY_STEP_DELETE_FAILED,
		DELETING_PATHWAY_STEP,
		PATHWAY_STEPS_REORDER_FAIL,
		REORDERING_PATHWAY_STEPS,
		PATHWAY_STEPS_REORDER_SUCCESS,
	} = OPathway.Actions;

	switch (action.type) {
		case REORDERING_PATHWAY_STEPS: {
			pathwayStepSnapshot = state.pathway?.pathwaySteps || [];
			const updatedPathwaySteps = action.data as TPathwayStep[];
			if (state.pathway)
				return {
					...state,
					loadingPathway: true,
					pathway: {
						...state.pathway,
						pathwayStepIds: updatedPathwaySteps?.map((p) => p.id),
						pathwaySteps: updatedPathwaySteps || state.pathway.pathwaySteps,
					},
				};
			return { ...state, loadingPathway: true };
		}
		case PATHWAY_STEPS_REORDER_FAIL: {
			const updatedPathwaySteps = [...pathwayStepSnapshot];
			if (state.pathway)
				return {
					...state,
					loadingPathway: false,
					pathway: {
						...state.pathway,
						pathwayStepIds: updatedPathwaySteps?.map((p) => p.id),
						pathwaySteps: updatedPathwaySteps || state.pathway.pathwaySteps,
					},
				};
			return { ...state, loadingPathway: false };
		}
		case PATHWAY_STEPS_REORDER_SUCCESS: {
			const { updatedPathwaySteps } = action.data as { pathwayId: string; updatedPathwaySteps: TPathwayStep[] };
			if (state.pathway)
				return {
					...state,
					loadingPathway: false,
					pathway: {
						...state.pathway,
						pathwayStepIds: updatedPathwaySteps?.map((p) => p.id),
						pathwaySteps: updatedPathwaySteps || state.pathway.pathwaySteps,
					},
				};
			return { ...state, loadingPathway: false };
		}
		case PATHWAY_STEP_DELETE_FAILED: {
			return { ...state, loadingPathway: false };
		}
		case DELETING_PATHWAY_STEP: {
			return { ...state, loadingPathway: true };
		}
		case PATHWAY_STEP_DELETED: {
			if (state.pathway) {
				const dataToUpdate = {
					pathwayStepIds: state.pathway.pathwayStepIds.filter((p) => p !== action.additionalDispatchData.pathwayStepId),
					pathwaySteps: (state.pathway.pathwaySteps || []).filter((p) => p.id !== action.additionalDispatchData.pathwayStepId),
				};

				return {
					...state,
					pathway: {
						...state.pathway,
						...dataToUpdate,
					},
					allPathways: [...state.allPathways.map((p) => (p.id === action.additionalDispatchData.id ? { ...p, ...dataToUpdate } : p))],
					loadingPathway: false,
				};
			}
			return { ...state, loadingPathway: false };
		}
		case ITEM_DELETED: {
			const { count } = action.data;
			if (count)
				return {
					...state,
					allPathways: utilities.updateItemList(state.allPathways, action.additionalDispatchData, 'DELETE'),
					pathway: undefined,
				};
			return { ...state };
		}
		case FETCHING_SINGLE_ITEM: {
			return { ...state, loadingPathway: true };
		}
		case SINGLE_ITEM_RECEIVED: {
			return { ...state, pathway: parseToPathway(action.data), loadingPathway: false };
		}
		case PATHWAYS_NEW_PATHWAY: {
			return { ...state, allPathways: [parseToPathway(action.data), ...state.allPathways] };
		}
		case PATHWAYS_UPDATE_PATHWAY: {
			return {
				...state,
				allPathways: utilities.updateItemList(state.allPathways, action.data, 'UPDATE'),
				pathway: state.pathway?.id === action.data?.id ? { ...state.pathway, ...action.data } : state.pathway,
			};
		}
		case SHOW_FORM: {
			return { ...state, showMainForm: true, editedPathway: action.data };
		}
		case SHOW_PATHWAY_STEP_FORM: {
			return { ...state, showPathwayStepForm: true, editedPathwayStep: action.data };
		}
		case HIDE_FORM: {
			return { ...state, showMainForm: false, editedPathway: undefined };
		}
		case HIDE_PATHWAY_STEP_FORM: {
			return { ...state, showPathwayStepForm: false, editedPathwayStep: undefined };
		}
		case ACTIVITY_RECEIVED: {
			switch (action.activity) {
				case OPathway.Activites.COUNT:
					return { ...state, count: action.data.count };
			}
			return { ...state };
		}
		case ITEM_ACTIVITY_POST_SUCCESS: {
			switch (action.activity) {
				case OPathway.Activites.PUBLISH:
					return {
						...state,
						allPathways: utilities.updateItemList(state.allPathways, action.data, 'UPDATE'),
						pathway: state.pathway ? action.data : undefined,
					};
			}
			return { ...state };
		}
		case FETCHING_LIST: {
			return { ...state, loading: true };
		}
		case LIST_RECEIVED: {
			return { ...state, allPathways: action.data, loading: false };
		}
		default:
			return { ...state };
	}
};
