import { Box, Chip, createStyles, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PostCard, { TPostStatus } from '../../Components/Cards/PostCard';
import UserCardAlt from '../../Components/Cards/UserCardAlt';
import Gallery from '../../Components/Gallery';
import { useListDrawer } from '../../Components/ListDrawer/hook';
import { useAssignTag } from '../../EntityForm/Tag/AssignTag';
import { OApp } from '../../Models/App';
import { TDispatch, TPostType } from '../../Models/App/@types';
import { OPost } from '../../Models/Post';
import { TPost, TPostListingFilter, TPostSort, TPostSortOrder } from '../../Models/Post/@types';
import PostUtils from '../../Models/Post/PostUtils';
import { TTag, TTagCategory } from '../../Models/Tag/@types';
import { TUser } from '../../Models/User/@types';
import { TReduxStore } from '../../RootReducer';
import { usePagination } from '../hooks';
import Filter from './Filter';
import Sort from './Sort';
import PostForm from 'Screens/Users/PostForm';

interface IProps { }

export const STATIC_POST_FILTER = {
	include: ['user'],
};

export const ITEM_LIMIT = 32;

const Posts: React.FC<IProps> = (props) => {
	const classes = useStyles(props);

	const posts = useSelector<TReduxStore, TPost[]>((state) => state.Post.list);
	const tagCategories = useSelector<TReduxStore, TTagCategory[]>((state) => state.Tag.list || []);
	const dispatch = useDispatch<TDispatch>();
	const [loading, setLoading] = useState(false);
	const { currentPage, onPageChange, paginationFilter, limit, reset: resetPagination } = usePagination(ITEM_LIMIT);
	const postTypes = useSelector<TReduxStore, TPostType[] | undefined>((state) => state.App.postTypes);
	const sort = useSelector<TReduxStore, TPostSort>((state) => state.Post.appliedSort);
	const sortOrder = useSelector<TReduxStore, TPostSortOrder>((state) => state.Post.appliedSortOrder);
	const appliedFilter = useSelector<TReduxStore, TPostListingFilter>((state) => state.Post.currentAppliedFilter);
	const dateFrom = useSelector<TReduxStore, string | undefined>((state) => state.Post.filterStartDate);
	const dateTo = useSelector<TReduxStore, string | undefined>((state) => state.Post.filterEndDate);
	const postsLoading = useSelector<TReduxStore, boolean>((state) => state.Post.loading);
	const [total, setTotal] = useState(0);

	useEffect(() => {
		getPageData();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		resetPagination();
		// eslint-disable-next-line
	}, [sort, sortOrder]);

	// ---------------------- ASSIGN TAG TO ENTITY HOOK--------------------------
	const handleTagAssign = async (tagIds: string[], item: TPost) => {
		try {
			await dispatch(OPost.patchItem(item.id, { tagIds }));
			dispatch(
				OApp.showToast({
					message: `Tags added to "${item.title}"`,
					variant: 'success',
				})
			);
		} catch (error) {
			dispatch(
				OApp.showToast({
					message: `Something went wrong`,
					variant: 'error',
				})
			);
		}
	};
	const { AssignTagComponent, assignTag } = useAssignTag<TPost>(handleTagAssign, tagCategories);
	// -----------------------------------------------------------------

	// ------------------- SIDE DRAWER HOOK-----------------------------
	const renderTagDrawerItem = (item: TTag) => (
		<Box m="4px" display="inline-block">
			<Chip label={item.name} />
		</Box>
	);
	const renderUserDrawerItem = (item: TUser) => (
		<Box borderBottom="1px solid grey">
			<UserCardAlt user={item} listItem={true} redirectUrl={`/dashboard/users/${item.id}/details`} />
		</Box>
	);

	const [TagListDrawer, openTagList] = useListDrawer<TTag>('Assigned Tags', renderTagDrawerItem);
	const [UserListDrawer, openUserList, setDrawerTitle] = useListDrawer<TUser>('Users', renderUserDrawerItem);
	// -----------------------------------------------------------------

	let apply = '';
	for (let i = 0; i < appliedFilter.type.length; i++) apply += appliedFilter.type[i].value;

	const getPageData = async () => {
		setLoading(true);
		try {
			await dispatch(
				OPost.getItemsList({
					filter: {
						...paginationFilter,
						...STATIC_POST_FILTER,
						where: {
							deleted: false,
							postType: 'post',
							...PostUtils.buildQueryFromFilter(appliedFilter, dateFrom, dateTo),
						},
						order: PostUtils.getOrderFilter(sort, sortOrder),
					},
				})
			);
		} catch (error) { }
		setLoading(false);
	};

	const TotalCount = async () => {
		let filter = {
			where: {
				deleted: false,
				postType: 'post',
				...PostUtils.buildQueryFromFilter(appliedFilter, dateFrom, dateTo),
			},
		};
		try {
			const res = await OPost.requestGetActivity('count', { ...filter });
			setTotal(_.get(res, 'data.count'));
		} catch (err) { }
	};
	useEffect(() => {
		TotalCount();
		// eslint-disable-next-line
	}, [apply]);

	useEffect(() => {
		getPageData();
		// eslint-disable-next-line
	}, [currentPage]);

	const showTagsOfPost = async (id: string) => {
		try {
			const res = await OPost.requestGetItem(id, {
				filter: { include: 'tags' },
			});
			openTagList(_.get(res, 'data.tags'));
		} catch (error) { }
	};
	const showLikersOfPost = async (id: string) => {
		try {
			const res = await OPost.requestLikers(id);
			setDrawerTitle('People who liked this');
			openUserList(_.get(res, 'data'));
		} catch (error) { }
	};
	const showCommentersOfPost = async (id: string) => {
		try {
			const res = await OPost.requestCommenters(id);
			setDrawerTitle('People who commented on this');
			openUserList(_.get(res, 'data'));
		} catch (error) { }
	};
	const showBookmarkersOfPost = async (id: string) => {
		try {
			const res = await OPost.requestBookmarkers(id);
			setDrawerTitle('People who bookmarked this');
			openUserList(_.get(res, 'data'));
		} catch (error) { }
	};

	/// HANDLE click on - likes, comments, bookmarks and tags ----------------------
	const handleStatusClick = async (item: TPost, status: TPostStatus) => {
		switch (status) {
			case 'bookmarks':
				await showBookmarkersOfPost(item.id);
				break;
			case 'comments':
				await showCommentersOfPost(item.id);
				break;
			case 'likes':
				await showLikersOfPost(item.id);
				break;
			case 'tags':
				await showTagsOfPost(item.id);
		}
	};
	// -------------------------------------------------------------------------------

	const deletePost = async (post: TPost) => {
		try {
			await dispatch(OPost.deleteItemActivity(post.id, OPost.Activites.DELETE, {}, undefined, { post }));
			return;
		} catch (error) {
			throw error;
		}
	};

	const handleDeletePostClick = async (item: TPost) => {
		dispatch(
			OApp.showConfirmationDialog(
				'Are you sure you want to delete the post?',
				async () => await deletePost(item),
				() => null
			)
		);
	};

	const renderItem = (item: TPost) => {
		const title = `TAGS: "${item.title}" ${item.user ? `by "${item.user.name}"` : ''}`;

		const actions = [
			{
				name: 'Delete',
				value: 'delete',
				onClick: () => handleDeletePostClick(item),
			},
			{
				name: 'Add Tags',
				value: 'tags',
				onClick: () => assignTag(item.tagIds, item, title),
			},
			{
				name: 'Edit Post', value: 'edit', onClick: () => { dispatch({ type: OPost.Actions.SHOW_FORM }); dispatch({ type: OPost.Actions.SET_EDITED_POST, data: item }); }
			}
		];
		return <PostCard postTypes={postTypes} onStatusClick={handleStatusClick} post={item} actions={actions} />;
	};

	return (
		<div className={classes.root}>
			<Box maxWidth="960px" margin="0 auto">
				<Box position="sticky" alignItems="center" display="flex" mt="20px" justifyContent="flex-end" width="960px" top="20px" zIndex="1">
					<Sort />
					<Box ml="10px">
						<Filter />
					</Box>
				</Box>
				<Box mt="45px" mb="100px">
					{loading || postsLoading ? (
						<LinearProgress />
					) : (
						<Gallery<TPost>
							data={posts}
							cols={4}
							renderItem={renderItem}
							paginationProps={{
								pageRangeDisplayed: 5,
								currentPage,
								itemsCountPerPage: limit,
								onPageChange,
								totalItemsCount: total,
							}}
						/>
					)}
				</Box>
			</Box>
			{AssignTagComponent}
			{TagListDrawer}
			{UserListDrawer}
			<PostForm />
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {},
	})
);

export default Posts;
