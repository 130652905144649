import { TPicture } from '../Picture/@types';

export type TRange = 1 | 2 | 3 | 4;

export interface TTagCategory {
	id: string;
	name: string;
	order: number;
	highlightText?: string;
	tagIds: string[];
	tags?: TTag[];
	_picture?: TPicture;
	webOnly?: boolean;
	type?: typeof MainTagType | null; // null is to allow for updating the data on the back-end since we can't send {type:undefined} over the network.
}

export interface TTag {
	value: string;
	name: string;
	highlightText?: string;
	popular?: boolean;
	featured?: boolean;
	trending?: boolean;
	force?: boolean;
	id: string;
	type: string;
	_picture?: TPicture;
	metaTags?: string;
	sendgridListId?: string;
	emoji?: string;
}

export type TTagListingFilter = {
	name: string;
	value: string;
}[];

export interface TTagState {
	totalCount: number;
	list?: TTagCategory[];
	filteredList: TTagCategory[];

	listingFilter: TTagListingFilter;
	currentAppliedFilter: TTagListingFilter;

	selectedTagCategory?: TTagCategory;
	selectedTag?: TTag;

	formData: Record<string, any>;

	showMainForm: boolean;
	formFor?: TFormType;
}

export type TFormType = 'new categories' | 'new tags' | 'update category' | 'edit tag';
export const MainTagType = 'exploreListCategory';
