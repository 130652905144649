import { omit } from 'lodash';
import { TPost, TContentDuration } from './@types';

export const parsePost = (obj: Record<string, any>): TPost => {
	return {
		...obj,
		...(obj.contentDuration ? { contentDuration: getTime(obj.contentDuration) } : {}),
		...(obj._assets ? { assets: obj._assets } : {}),
	} as TPost;
};

const getTime = (min: number): TContentDuration => {
	const hours = Math.floor(min / 60);
	const minutes = Math.floor(min % 60);
	const sec = Math.round(+(min % 1).toFixed(2) * 60);
	return { hours, minutes, sec };
};

export const parsePostForm = (obj: Partial<TPost>): Record<string, any> => {
	const _assets = obj.assets;
	obj = omit(obj, 'assets');
	return {
		...obj,
		...(obj.contentDuration ? { contentDuration: convertToMinutes(obj.contentDuration) } : {}),
		_assets,
	};
};

const convertToMinutes = (duration: { hours?: number | string; minutes?: number; sec?: number }): number => {
	const { hours, minutes, sec } = duration;
	const decimal = +((sec || 0) / 60).toFixed(2);
	return +(+(hours || 0) * 60 + +(minutes || 0) + decimal); // TextField always gives string as value -_-
};
