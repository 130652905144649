import LoopFront, { TAction } from 'loop-front';
import { ThunkAction } from 'redux-thunk';
import { TReduxStore } from '../../RootReducer';
import utilities from '../../Resources/Utils';
import { TUserFilterKey, TUserListingFilter, TUser } from './@types';
import { TDispatch } from '../App/@types';
import UserUtils from './userUtils';
import _ from 'lodash';

import { Token } from 'Models/Auth/@types';
import AxiosUtils, { request } from 'Resources/AxiosUtils';
import { JSONType, Params } from 'typings/global';
import { parseUser } from './userParsers';
import { OMicroCourse } from 'Models/MicroCourse';

const CustomActions = {
	APP_USER_RECEIVED: 'APP_USER_RECEIVED',
	SHOW_USER_FORM: 'SHOW_USER_FORM',
	HIDE_USER_FORM: 'HIDE_USER_FORM',
	USER_SET_LISTING_FILTER_STATUS: 'USER_SET_LISTING_FILTER_STATUS',
	SET_USER_LISTING_LOADING: 'SET_USER_LISTING_LOADING',
	APPLY_SELECTED_USER_FILTERS: 'APPLY_SELECTED_USER_FILTERS',
	CLEAR_USER_LISTING_FILTERS: 'CLEAR_USER_LISTING_FILTERS',
	SHOW_MILESTONE_FORM: 'SHOW_MILESTONE_FORM',
	HIDE_MILESTONE_FORM: 'HIDE_MILESTONE_FORM',
	SET_EDITING_MILESTONE: 'SET_EDITING_MILESTONE',
	SET_MILESTONES: 'SET_MILESTONES',
	// SHOW_SPECIAL_USER_FORM: 'SHOW_SPECIAL_USER_FORM',
	// HIDE_SPECIAL_USER_FORM: 'HIDE_SPECIAL_USER_FORM',
	ADD_SPECIAL_USER: 'ADD_SPECIAL_USER',
	RECEIVED_USER_CREATED_MICROCOURSES: 'RECEIVED_USER_CREATED_MICROCOURSES',
};

const CustomActivites = {
	LOGIN: 'login',
	LOGOUT: 'logout',
	IS_APPROVED: 'approve',
	AUTOCOMPLETE: 'autocomplete',
};

const CustomEntities = {
	POSTS: 'posts',
	BAN: 'ban',
	FOLLOWERS: 'followers',
	FOLLOWING: 'following',
	MILESTONES: 'milestones',
};

export const USER_INCLUDES = [];

class User extends LoopFront<typeof CustomActions, typeof CustomEntities, typeof CustomActivites> {
	constructor() {
		super('users', CustomActions, CustomEntities, CustomActivites);
	}

	userStatus = [
		{ label: 'All', name: 'all', value: true },
		{ label: 'Verified', name: 'isApproved', value: true },
		{ label: 'Unverified', name: 'isApproved', value: false },
		{ label: 'Banned', name: 'ban', value: true },
		{ label: 'Active', name: 'ban', value: false },
		{ label: 'Featured', name: 'featured', value: true },
		{ label: 'Curator', name: 'isCurator', value: true },
	];

	logout = (): ThunkAction<void, TReduxStore, {}, TAction> => async (dispatch, getState) => {
		utilities.clearCookies();
		await this.requestPostActivity(this.Activites.LOGOUT).catch((error) => {});
		dispatch({ type: this.Actions.ACTIVITY_POST_SUCCESS, activity: this.Activites.LOGOUT });
		LoopFront.setAuthHeader('');
	};

	requestFollowers = (userId: string, params?: any) => this.requestGetEntityByItem(userId, this.Entities.FOLLOWERS, params);
	requestFollowing = (userId: string, params?: any) => this.requestGetEntityByItem(userId, this.Entities.FOLLOWING, params);
	requestCreatedMicroCourses =
		(userId: string): ThunkAction<void, TReduxStore, {}, TAction> =>
		async (dispatch) => {
			const microCourses = (await OMicroCourse.requestGetItemsList({ filter: { where: { curatorIds: { inq: [userId] } } } })).data;
			console.log({ microCourses });
			dispatch({ type: this.Actions.RECEIVED_USER_CREATED_MICROCOURSES, data: microCourses });
		};
	setFilter = (filter: TUserFilterKey, value: any) => (dispatch: TDispatch) => {
		if (filter === 'status')
			return dispatch({
				type: this.Actions.USER_SET_LISTING_FILTER_STATUS,
				data: value,
			});
	};
	applySelectedFilter = (appliedFilter?: TUserListingFilter) => async (dispatch: TDispatch, getState: () => TReduxStore) => {
		if (!appliedFilter) appliedFilter = getState().User.listingFilter;
		const whereQuery = UserUtils.buildQueryFromFilter(appliedFilter);
		dispatch({
			type: this.Actions.SET_USER_LISTING_LOADING,
			data: true,
		});
		dispatch({
			type: this.Actions.APPLY_SELECTED_USER_FILTERS,
			data: appliedFilter,
		});
		try {
			await dispatch(
				this.getItemsList({
					filter: {
						limit: _.isEmpty(whereQuery) ? 20 : undefined,
						// order: listOrder['Created des'],
						where: whereQuery,
					},
				})
			);
		} catch (error) {}
		try {
			await dispatch(this.getUserCount(appliedFilter));
		} catch (error) {}
		dispatch({
			type: this.Actions.SET_USER_LISTING_LOADING,
			data: false,
		});
	};

	getUserCount = (filter?: TUserListingFilter) => async (dispatch: TDispatch, getState: () => TReduxStore) => {
		if (!filter) filter = getState().User.listingFilter;
		const whereQuery = UserUtils.buildQueryFromFilter(filter);
		try {
			await dispatch(OUser.getActivity(OUser.Activites.COUNT, { where: whereQuery }));
		} catch (error) {}
	};

	resetFilter = () => async (dispatch: TDispatch) => {
		dispatch({
			type: this.Actions.CLEAR_USER_LISTING_FILTERS,
		});
		dispatch({
			type: this.Actions.SET_USER_LISTING_LOADING,
			data: true,
		});
		try {
			await dispatch(
				this.getItemsList({
					filter: {
						limit: 20,
						// order: listOrder['Created des'],
					},
				})
			);
		} catch (error) {}
		dispatch({
			type: this.Actions.SET_USER_LISTING_LOADING,
			data: false,
		});
	};

	getName = (user: TUser) => {
		if (user.firstName) return `${user.firstName} ${user.lastName ?? ''}`;
		return user.name;
	};
}

export const OUser = new User();

export interface ReduxSelector {
	list?: Array<TUser>;
	totalCount?: number;
	user?: TUser;
}

class UserModel {
	static login = async (credentials: { email: string; password: string; isWeb: boolean }) => {
		const data = await request<any>({
			url: '/users/login',
			method: 'post',
			data: credentials,
		}).catch(AxiosUtils.throwError);
		const token: Token = {
			id: data.id,
			userId: data.userId,
			ttl: data.ttl,
			created: data.created,
		};

		return token;
	};
	static logOut = async () => {
		let resp;
		try {
			resp = await request({
				url: '/users/logout',
				method: 'post',
			});
		} catch (error) {
			throw error;
		}
		return resp;
	};

	static fetchMe = async (access_token: string) => {
		const user = await request<TUser>({
			url: `/users/me`,
			headers: { Authorization: access_token },
		});
		return parseUser(user);
	};
	static updatePassword = async (oldPassword: string, newPassword: string): Promise<boolean> => {
		const { statusCode } = await request<JSONType>({
			url: 'users/update-password',
			method: 'PATCH',
			data: { oldPassword, newPassword },
		});
		if (statusCode === 200) return true;
		else return false;
	};
	static getUser = async (id: string, params?: Params) => {
		const user = await request<JSONType>({
			url: `users/${id}`,
			method: 'GET',
			params,
		});
		return parseUser(user);
	};
}

export default UserModel;
