import React, { useState, useEffect } from 'react';
import { TextField, Box, Typography, IconButton, InputAdornment, Icon } from '@material-ui/core';
import Axios, { CancelTokenSource } from 'axios';
import utilities from '../Resources/Utils';
import { TRequestConfig } from 'loop-front';
import Loader from './Loader';
import { TextFieldProps } from '@material-ui/core/TextField';

const AxiosInstance = Axios.create();

export interface RequestOnTypeProps {
	label?: string;
	validations?: Array<'URL'>;
	queryKey: string;
	onResponse: (response: any) => void;
	onLoad?: (loading: boolean) => void;
	onChange?: (input: string) => void;
	showLoading?: boolean;
	config: TRequestConfig;
	textFieldProps?: Omit<TextFieldProps, 'name' | 'label' | 'value' | 'onChange' | 'fullWidth' | 'variant'>;
	selectedText?: string;
	onSelectedTextClear?: () => void;

	// Request will be made again when this array changes
	extraData?: Array<any>;
}

let LinkPreviewTimeoutHandle: NodeJS.Timeout;
let LinkPreviewAjaxCallHandle: CancelTokenSource;

const RequestOnType: React.FC<RequestOnTypeProps> = (props) => {
	const {
		label = 'Enter URL',
		validations = [],
		queryKey = 'q',
		config,
		onResponse,
		showLoading,
		onLoad,
		textFieldProps,
		selectedText = '',
		extraData = [],
	} = props;

	const shouldBeURL = validations.find((item) => item === 'URL');

	const [input, setInput] = useState('');
	const [loadingPreview, setLoadingPreview] = useState(false);
	const [invalidUrl, setInvalidUrl] = useState('');
	const [error, setError] = useState<string>();

	const setLoader = (status: boolean) => {
		typeof onLoad === 'function' && onLoad(status);
		setLoadingPreview(status);
	};

	const onChange = async (text: string, loadMore: boolean = false) => {
		setInput(text);
		setError(undefined);
		if (text.length === 0) {
			setLoader(false);
		}
		if (LinkPreviewTimeoutHandle) {
			clearTimeout(LinkPreviewTimeoutHandle);
		}

		shouldBeURL && setInvalidUrl('');
		typeof props.onChange === 'function' && !loadMore && props.onChange(text);

		if (!text) {
			/**
			 * If the previous request is not completed and the text is cleared,
			 * so that previous request will be canceled/
			 */

			if (LinkPreviewAjaxCallHandle) {
				LinkPreviewAjaxCallHandle.cancel('Input cleared ' + text);
			}
			return;
		}
		loadMore || setLoader(true);
		LinkPreviewTimeoutHandle = setTimeout(async () => {
			if (shouldBeURL && !utilities.validateUrl(text)) {
				setInvalidUrl('Enter a valid url');
				loadMore || setLoader(false);
				return;
			}

			/**
			 * @const this.LinkPreviewAjaxCallHandle`
			 * contains the token for previous request. If the subsequent request is made
			 * so the previous request with that token will be cancelled
			 */
			if (LinkPreviewAjaxCallHandle) {
				LinkPreviewAjaxCallHandle.cancel('Next Request is made for ' + text);
			}

			/**
			 * Assigning the new token to the upcoming XHR request
			 */
			LinkPreviewAjaxCallHandle = Axios.CancelToken.source();
			let reqFailed = false;
			const res = await AxiosInstance.request({
				...config,
				params: { [queryKey]: text, ...(config.params || {}) },
				cancelToken: LinkPreviewAjaxCallHandle.token,
			}).catch((error) => {
				console.log('Link Preview Error', error.response);
				setError('Unable to retrieve page at the above url. This could be due to permission requirements or the website blocking automated requests');
				setInput(text);
				onResponse({ data: { url: text } });
				setLoader(false);
				reqFailed = true;
			});
			if (!reqFailed)
				onResponse({ ...res, data: { ...(res || {}).data, url: text } }); // Some websites redirect to login (Eg.: Instagram, FB) when back-end fetches preview. Thus intended url gets replaced. We edit data in order to fix that.
			loadMore || setLoader(false);
		}, 500);
	};

	useEffect(() => {
		onChange(input, true);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, extraData);

	const onSelectedTextClear = () => {
		props.onSelectedTextClear && props.onSelectedTextClear();
		onChange('');
	};

	return (
		<div>
			<TextField
				value={selectedText || input}
				label={label}
				onChange={(e) => onChange(e.target.value)}
				fullWidth
				{...(textFieldProps || {})}
				disabled={Boolean(selectedText)}
				InputProps={{
					...(selectedText
						? {
							endAdornment: (
								<InputAdornment position={'end'} onClick={onSelectedTextClear}>
									<IconButton>
										<Icon>{'clear'}</Icon>
									</IconButton>
								</InputAdornment>
							),
						}
						: undefined),
				}}
			/>
			{error ? <Typography >{error}</Typography> : null}
			{invalidUrl && shouldBeURL ? <Typography color="error">{invalidUrl}</Typography> : null}
			{loadingPreview && showLoading ? (
				<Box my={1}>
					<Loader />
				</Box>
			) : null}
		</div>
	);
};

export default RequestOnType;
