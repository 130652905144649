import { Box, Grid } from '@material-ui/core';
import { GridProps } from '@material-ui/core/Grid';
import _ from 'lodash';
import React from 'react';
import { SortableContainer, SortableElement, SortEndHandler } from 'react-sortable-hoc';
import Pagination, { PaginationComponentProps } from '../Pagination';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';


export interface IGalleryProps<T = unknown> {
	data: T[] | undefined;
	generateKey?: (item: T, index: number) => string;
	cols?: 1 | 2 | 3 | 4 | 6 | 12;
	renderItem: (item: T) => React.ReactChild;
	renderLottieItem?: (item: T, index: number) => React.ReactChild;
	loadingCount?: number;
	spacing?: GridProps['spacing'];
	renderLoading?: () => JSX.Element;
	paginationProps?: Omit<PaginationComponentProps, 'classes'>;
	onReOrder?: SortEndHandler;
}

function Gallery<T extends any>(props: IGalleryProps<T>) {
	const classes = useStyles();
	const { data = [], renderItem, cols = 3, generateKey, loadingCount = 0, onReOrder, renderLoading = () => null, spacing = 2 } = props;

	const breakpoint = () => {
		return (12 / cols as 1 | 2 | 3 | 4 | 6 | 12);
	};

	const SortableItem = SortableElement<any>((_props: any) => {
		const { value } = _props;
		return (
			<Grid className={classes.gridItem} item key={generateKey ? generateKey(value, 0) : _.uniqueId()} md={breakpoint()} xs={12}>
				{renderItem(value)}
			</Grid>
		);
	});

	const SortableList = SortableContainer(({ items }: { items: T[]; }) => {
		return (
			<Grid container spacing={spacing}>{
				items.map((value, index) => {
					return <SortableItem key={generateKey ? generateKey(value, index) : index} index={index} value={value} />;
				})
			}</Grid>
		);
	});

	const gridList = data.map((item, i) => (<Grid className={classes.gridItem} item key={(item as Record<string, any>).id || i} md={breakpoint()} xs={12}>
		{props.renderLottieItem ? props.renderLottieItem(item, i) : props.renderItem(item)}
	</Grid>
	));

	if (!data)
		return <div />;




	return (
		<Box>
			{

				loadingCount > 0 && <Grid item md={breakpoint()} xs={12}>
					{renderLoading()}
				</Grid>

			}
			{onReOrder ? (
				<SortableList helperClass={classes.draggedEl} lockToContainerEdges items={data} axis={'xy'} onSortEnd={onReOrder} />
			) : (
				<Grid container spacing={spacing}>
					{gridList}
				</Grid>
			)
			}

			<Box width="500px" m="0 auto">
				{
					props.paginationProps ?
						<Pagination
							{...props.paginationProps}
						/> : null
				}
			</Box>
		</Box>
	);
}


const useStyles = makeStyles<Theme>((theme) => {
	return createStyles({
		draggedEl: {
			zIndex: theme.zIndex.modal + 1
		},
		gridItem: {
			// flex: 1,
			display: 'flex'
		}
	});
});
export default Gallery;