import { Reducer } from 'redux';
import { TAction } from 'loop-front';
import { TAppState } from './@types';
import { OApp } from '.';

const initialState: TAppState = {
	dialog: {
		open: false,
		message: '',
	},
	toast: {
		message: '',
		open: false,
		variant: 'success',
	},
};
export const App: Reducer<TAppState, TAction> = (state = initialState, action: TAction): TAppState => {
	const { OPEN_TOAST, CLOSE_TOAST, ACTIVITY_RECEIVED, SHOW_CONFIRMATION_DIALOG, HIDE_CONFIRMATION_DIALOG } = OApp.Actions;
	const { CONSTANTS } = OApp.Activites;
	switch (action.type) {
		case OPEN_TOAST: {
			return { ...state, toast: { ...action.data } };
		}

		case CLOSE_TOAST: {
			return { ...state, toast: { ...state.toast, open: false } };
		}

		case ACTIVITY_RECEIVED: {
			switch (action.activity) {
				case CONSTANTS: {
					return {
						...state,
						industriesList: (action.data.Experience || {}).industry || [],
						educationDegreeList: (action.data.Education || {}).degree || [],
						postScope: (action.data.Post || {}).scope || [],
						postTypes: (action.data.Post || {}).type || [],
					};
				}
				default:
					return state;
			}
		}

		case SHOW_CONFIRMATION_DIALOG: {
			return { ...state, dialog: { open: true, message: action.data.message, onAccept: action.data.onAccept, onCancel: action.data.onCancel } };
		}
		case HIDE_CONFIRMATION_DIALOG: {
			return { ...state, dialog: { open: false, message: '' } };
		}

		default:
			return state;
	}
};
