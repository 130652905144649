import { TRootStore } from 'Stores';
import { action, Action, Thunk, thunk } from 'easy-peasy';
import { Elite } from 'Models/Elite/@types';
import EliteModel from 'Models/Elite';
import { JSONType, Params } from 'typings/global';

export interface EliteState {
	tagEliteMapping: { [tagId: string]: Elite[] };
	addElite: Thunk<EliteState, { tagId: string; userId: string }, null, TRootStore, Promise<JSONType>>;
	getSingleElite: Thunk<EliteState, { eliteId: string; params: Params }, null, TRootStore, Promise<Elite>>;
	getEliteForTag: Thunk<EliteState, { tagId: string }, null, TRootStore, Promise<Elite[]>>;
	setEliteForTag: Action<EliteState, { tagId: string; eliteList: Elite[] }>;
}

export const EliteStore: EliteState = {
	tagEliteMapping: {},
	getEliteForTag: thunk(async (actions, { tagId }) => {
		const eliteList = await EliteModel.getAllElites({
			filter: {
				where: { tagId },
				include: { relation: 'user', scope: { fields: ['id', '_profileImage', 'name', 'firstName', 'lastName'] } },
				limit: 100,
				order: 'order ASC',
			},
		});
		actions.setEliteForTag({ tagId, eliteList });
		return eliteList;
	}),
	addElite: thunk(async (_, { tagId, userId }) => {
		const elite = await EliteModel.addElite(userId, tagId);
		return elite;
	}),
	getSingleElite: thunk(async (_, { eliteId, params }) => {
		return EliteModel.getSingleElite(eliteId, params);
	}),
	setEliteForTag: action((state, { tagId, eliteList }) => {
		state.tagEliteMapping = { ...state.tagEliteMapping, [tagId]: eliteList };
	}),
};

export default EliteStore;
