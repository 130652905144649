import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'universal-cookie';
import { TRequestConfig } from 'loop-front';
import _ from 'lodash';
import { FormikValues } from 'formik';


axios.defaults.headers.post['Content-Type'] = 'application/json';
// const IMAGE_KIT_URL = 'https://mithya.imagekit.io/stayuncle/';
const cookies = new Cookies();

let utilities = {


	toggleItemFromList: (list: any[] = [], item: any, key: string = 'id', comparisonFunction?: (currentItem: any, item: any) => boolean) => {
		let updatedList: any[] = [...list];
		let index = list.findIndex(i => comparisonFunction ? comparisonFunction(i, item) : i[key] === item[key]);
		index === -1 ? updatedList.push(item) : updatedList.splice(index, 1);
		return updatedList;
	},

	updateItemList: (list: Array<any>, item: any, action: 'ADD' | 'DELETE' | 'UPDATE' | 'PUT', key = 'id'): typeof list => {
		list = list || [];
		let newList = list.slice();
		let itemIndex;
		if (action === 'UPDATE') {
			return newList.map(data => data[key] === item[key] ? ({ ...data, ...item }) : data);
		} else if (action === 'ADD') {
			newList.unshift(item);
			return newList;
		} else if (action === 'DELETE') {
			return newList.filter(listItem => item[key] !== listItem[key]);
		}
		else if (action === 'PUT') {
			itemIndex = newList.findIndex(listItem => item[key] === listItem[key]);
			if (itemIndex !== -1)
				newList.splice(itemIndex, 1, item);
			else {
				newList.push(item);
			}
			return newList;
		}
		return newList;
	},

	findValues: (superSet: (Array<Object>), subSet: (string | Array<string>), findKey: string = 'value', mapKey: string = 'name', isReturnItem: Boolean = false) => {
		// findKey = findKey || 'value';
		// mapKey = mapKey || 'name';
		if (_.isString(subSet))
			subSet = [subSet];
		let filteredValues = _.filter(superSet, (item: any) => {
			return (_.indexOf(subSet, item[findKey]) !== -1);
		});
		if (isReturnItem)
			return filteredValues;
		return _.map(filteredValues, mapKey);
	},

	saveUser: (accessToken: string = '', userId: string = '') => {
		cookies.set('access_token', accessToken, { path: '/' });
		cookies.set('userId', userId, { path: '/' });
	},
	clearCookies: () => {
		cookies.remove('access_token', { path: '/' });
		cookies.remove('userId', { path: '/' });
	},

	getUserId: () => {
		return cookies.get('userId');
	},
	getAccessToken: () => {
		return cookies.get('access_token');
	},

	request: (config: AxiosRequestConfig & TRequestConfig) => {
		return axios.request(config);
	},
	jsonParser: (data: any, keys: Array<string>) => {
		let updatedObject: any;
		if (Array.isArray(data)) {
			updatedObject = [...data];
			updatedObject.forEach((objItem: any) => {
				for (let i = 0; i < keys.length; i++) {
					try {
						if (typeof objItem[keys[i]] === 'string') objItem[keys[i]] = JSON.parse(objItem[keys[i]]);
						else continue;
					} catch (e) { objItem[keys[i]] = {}; }
				}
			});
		}
		else {
			updatedObject = { ...data };
			for (let i = 0; i < keys.length; i++) {
				try {
					if (typeof data[keys[i]] === 'string') updatedObject[keys[i]] = JSON.parse(data[keys[i]]);
					else continue;
				} catch (e) { updatedObject[keys[i]] = {}; }
			}
		}
		return updatedObject;
	},

	validateEmail: (email: string) => {
		// eslint-disable-next-line
		let tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
		if (!email)
			return false;

		if (email.length > 254)
			return false;

		var valid = tester.test(email);
		if (!valid)
			return false;

		// Further checking of some things regex can't handle
		var parts = email.split("@");
		if (parts[0].length > 64)
			return false;

		var domainParts = parts[1].split(".");
		if (domainParts.some(function (part) { return part.length > 63; }))
			return false;

		return true;
	},

	containsEncodedComponents: (link: string) => {
		return decodeURI(link) !== decodeURIComponent(link);
	},

	validateUrl: (uri: string): boolean => {
		if (utilities.containsEncodedComponents(uri)) {
			uri = decodeURIComponent(uri);
		}
		// eslint-disable-next-line
		let tester = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
		if (!uri) return false;
		return tester.test(uri);
	},

	getFieldError: (fieldName: string, formikProps: FormikValues) => {
		const fieldError = _.get(formikProps.errors, fieldName);
		const isTouched = _.get(formikProps.touched, fieldName);
		if (!isTouched && formikProps.submitCount < 1)
			return '';
		return fieldError;
	},

};



export default utilities;