import { Card, CardContent, createStyles, TextField, Theme, WithStyles, withStyles } from '@material-ui/core';
import PictureInput from 'Components/PictureInput';
import PictureModel from 'Models/Picture';
import { TPicture } from 'Models/Picture/@types';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { TLinkPreview } from '../../Models/Post/@types';

interface IProps extends WithStyles<typeof STYLES>, RouteComponentProps<any> {
	previewData: TLinkPreview;
	onClick?: () => void;
	onChange?: (previewData: TLinkPreview) => void;
}

class LinkPreviewCard extends Component<IProps> {
	render() {
		const { classes, previewData, onClick, onChange } = this.props;

		return (
			<Card onClick={onClick}>
				{/* {previewData.image ? <CardMedia image={previewData.image} className={classes.image} component="img" /> : null} */}
				<PictureInput
					// withCropping
					cropConfig={{}}
					onPictureRemove={() => onChange?.({ ...previewData, image: '' })}
					onPicUpload={(pic) => onChange?.({ ...previewData, image: PictureModel.getPictureUrl(pic, 600) })}
					initialPicture={{ url: previewData?.image } as TPicture}
				/>
				<CardContent>
					{/* <Typography gutterBottom variant='h5' component='h2' >{previewData.title || ''}</Typography> */}
					<TextField
						className={classes.textField}
						label={'Title'}
						fullWidth
						value={previewData.title}
						onChange={(e) => {
							onChange?.({ ...previewData, title: e.target.value });
						}}
					/>
					<TextField
						className={classes.textField}
						label={'Description'}
						multiline
						fullWidth
						value={previewData.description || ''}
						onChange={(e) => {
							onChange?.({ ...previewData, description: e.target.value });
						}}
					/>
				</CardContent>
			</Card>
		);
	}
}

const STYLES = (theme: Theme) =>
	createStyles({
		image: {
			width: '100%',
			height: 'auto',
		},
		textField: {
			marginBottom: 16,
		},
	});

export default withRouter(withStyles(STYLES)(LinkPreviewCard));
