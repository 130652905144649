import { Box, createStyles, Drawer, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

interface IProps<T = unknown> {
	open: boolean;
	title: string;
	list: T[];
	renderItem?: (item: T) => JSX.Element;
	onClose: () => void;
}

function ListDrawer<T extends { id: string; name: string; phone?: string } = any>(props: IProps<T>) {
	const { open = false, title, list = [], renderItem } = props;
	const classes = useStyles(props);

	return (
		<Drawer anchor="right" open={open}>
			<Box className={classes.root}>
				<Box display="flex" alignItems="center" className={classes.title} justifyContent="space-between">
					<Typography>
						<b>{title}</b>
					</Typography>
					<IconButton onClick={props.onClose}>
						<i className="material-icons">close</i>
					</IconButton>
				</Box>
			</Box>
			<Box p="20px 4px" className={classes.container} width={DRAWER_WIDTH}>
				{list.map((u, i) =>
					renderItem ? (
						<span key={i}>{renderItem(u)}</span>
					) : (
						<Link to={`users/${u.id}/details`} key={u.id}>
							<Typography className={classes.item}>{u.name || u.phone}</Typography>
						</Link>
					)
				)}
			</Box>
		</Drawer>
	);
}

const DRAWER_WIDTH = 300;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: DRAWER_WIDTH,
			boxSizing: 'border-box',
			paddingLeft: 20,
			position: 'sticky',
			top: 0,
			background: theme.palette.common.white,
			boxShadow: theme.shadows[1],
		},
		title: {
			height: 64,
		},
		container: {
			boxSizing: 'border-box',
		},
		item: {
			padding: '20px 0px 20px 20px',
			borderBottom: `1px solid ${fade('#000', 0.12)}`,
		},
	})
);

export default ListDrawer;
