import { Reducer } from "redux";
import { TSettingState } from './@types';
import { TAction } from 'loop-front';
import { OSetting } from '.';
import utilities from "../../Resources/Utils";


const initialState: TSettingState = {
    list: []
}


export const RSetting: Reducer<TSettingState, TAction> = (state = initialState, action): TSettingState => {
    const {
        LIST_RECEIVED,
        ITEM_PATCH_SUCCESS,
    } = OSetting.Actions;

    switch (action.type) {
        case LIST_RECEIVED: {
            return { ...state, list: action.data }
        }

        case ITEM_PATCH_SUCCESS: {
            return { ...state, list: utilities.updateItemList(state.list, action.data, 'UPDATE') }
        }

        default: return state;
    }
}