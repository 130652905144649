import { useState } from 'react';


export const usePagination = (limit: number) => {
    const [currentPage, setCurrentPage] = useState(1);
    const filter = {
        limit,
        skip: limit * (currentPage - 1),
    }

    return {
        limit,
        currentPage,
        onPageChange: setCurrentPage,
        paginationFilter: filter,
        reset: () => setCurrentPage(1)
    }
}
