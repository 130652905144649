import { TAction } from "loop-front";
import { TTeamState } from "./@types";
import { Reducer } from "redux";
import { OTeam } from ".";
import utilities from "../../Resources/Utils";

const InitialState: TTeamState = {
    list: [],
    showMainForm: false,
    showUserTeamsForm: false
}

export const RTeam: Reducer<TTeamState, TAction> = (state = InitialState, action) => {
    switch (action.type) {

        case OTeam.Actions.LIST_RECEIVED: return { ...state, list: action.data }

        case OTeam.Actions.SHOW_TEAM_FORM: return { ...state, showMainForm: true, editedTeam: action.data }

        case OTeam.Actions.HIDE_TEAM_FORM: return { ...state, showMainForm: false, editedTeam: undefined }

        case OTeam.Actions.SHOW_TEAM_USER_FORM: return { ...state, showUserTeamsForm: true }

        case OTeam.Actions.HIDE_TEAM_USER_FORM: return { ...state, showUserTeamsForm: false }

        case OTeam.Actions.POST_ITEM_SUCCESS: return { ...state, list: [action.data, ...(state.list || [])] }

        case OTeam.Actions.ITEM_PATCH_SUCCESS: return { ...state, list: utilities.updateItemList(state.list || [], action.data, action.data.deleted ? 'DELETE' : 'UPDATE'), team: action.data }

        case OTeam.Actions.FETCHING_SINGLE_ITEM: return { ...state, team: undefined, teamUsers: [] }

        case OTeam.Actions.SINGLE_ITEM_RECEIVED: return { ...state, team: action.data }

        case OTeam.Actions.ENTITY_OF_ITEM_RECEIVED: {
            switch (action.entity) {
                case OTeam.Entities.USERS: return { ...state, teamUsers: action.data }
                default: return state;
            }
        }

        default: return state;
    }
}