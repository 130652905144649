import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
// import LandingPage from '../screens/Landing';
import Page404 from '../Screens/Page404';
import Landing from '../Screens/Landing';
import Dashboard from '../Screens/Dashboard';

interface Props { }

class AppNavigation extends Component<Props> {

    render() {
        return (
            <Switch>
                <Route path='/' exact component={Landing} />
                <Route path='/dashboard' component={Dashboard} />
                <Route path='/*' exact component={Page404} />
            </Switch>
        )
    }
}

export default AppNavigation;