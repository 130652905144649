import { ExploreHome } from './@types';

export const parseExploreHome = (obj: Record<string, any>): ExploreHome => {
	const res: ExploreHome = {
		...obj,
	} as ExploreHome;
	if (obj.subjectType === 'ExploreList') {
		obj.subject.listDocuments = obj.subject['list-documents'];
	}
	return res;
};

export const parseExploreHomeForm = (formData: Record<string, any>) => {
	return { ...formData, subjectId: formData.subject.id };
};
