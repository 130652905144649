import React, { Component } from 'react'
import { createStyles, WithStyles, withStyles, Theme } from '@material-ui/core'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps, Redirect, withRouter } from 'react-router';
import { TReduxStore } from '../../RootReducer/index';
import { TAction } from 'loop-front';
import { TUser } from '../../Models/User/@types';
import { OUser } from '../../Models/User';
import DashboardBody from './DashboardBody';

interface IStateProps {
    appUser?: TUser
}

interface IDispatchProps {
    logout: () => void
}

interface IState {
    open: boolean
}

interface OwnProps { }

// eslint-disable-next-line
interface IProps extends WithStyles<typeof STYLES>, IStateProps, IDispatchProps, RouteComponentProps, OwnProps { }

class DashboardLayout extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            open: false
        }
    }

    anchorEl: HTMLButtonElement | null = null

    openPopper = () => this.setState({ open: true });

    closePopper = () => this.setState({ open: false });

    handleLogout = () => this.props.logout();

    onButtonClick = () => !this.state.open ? this.openPopper() : null

    render() {
        const { classes, appUser } = this.props;
        if (!appUser) return <Redirect to={'/'} />
        return (
            <div className={classes.root}>
                <DashboardBody />
            </div>
        )
    }
}

const mapStateToProps = (state: TReduxStore): IStateProps => ({
    appUser: state.User.appUser
})

const mapDispatchToProps = (dispatch: ThunkDispatch<TReduxStore, {}, TAction>): IDispatchProps => ({
    logout: () => dispatch(OUser.logout())
})

const STYLES = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        // marginTop: 64,
        background: theme.palette.grey['700'],
        overflow: 'auto',
        position: 'absolute',
        top: 0, left: 0, right: 0, bottom: 0,
    },
    button: {
        color: '#fff'
    },
    flex: {
        flex: 1
    },
    userDropdown: {
        zIndex: theme.zIndex.appBar + 1
    },
    listItemIcon: {
        marginRight: 0
    }
})

export default withRouter(connect<IStateProps, IDispatchProps, OwnProps, TReduxStore>(mapStateToProps, mapDispatchToProps)(withStyles(STYLES)(DashboardLayout)))