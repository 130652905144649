import { Box, Button, Chip, createStyles, Fab, makeStyles, Paper, Theme, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import Gallery, { IGalleryProps } from 'Components/Gallery';
import useToastMessage from 'Hooks/useToastMessage';
import _ from 'lodash';
import { TDispatch } from 'Models/App/@types';
import { OTag } from 'Models/Tag';
import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import { TTag, TTagCategory } from '../../Models/Tag/@types';
import DeleteButton from '../Buttons/DeleteButton';

// import DeleteButton from 'Components/Buttons/DeleteButton';

interface IProps {
	category: TTagCategory;
	onEdit?: (tagCategory: TTagCategory) => void;
	onDelete?: (tagCategory: TTagCategory) => void;
	selectedTagIds?: string[];
	onTagSelect?: (tag: TTag) => void;
	handleDeleteTag?: (tag: TTag) => void;
	dragHandleProps?: any;
	handleAddTag?: (tagCategory: TTagCategory) => void;
}

const TagCategoryCard: React.FC<IProps> = (props) => {
	const { category, selectedTagIds = [], dragHandleProps = {} } = props;
	const { tagIds = [] } = category;
	const theme = useTheme<Theme>();
	const classes = useStyles(theme);
	const [rearrangeMode, setRearrangeMode] = useState<boolean>();
	const [tags, setTags] = useState<TTag[]>(sortTags(tagIds, category.tags ?? []));
	const withToastMessage = useToastMessage();
	const dispatch = useDispatch<TDispatch>();

	const handleAddTagClick = () => {
		if (!props.handleAddTag) return;
		props.handleAddTag(props.category);
	};

	const onEdit = () => {
		if (!props.onEdit) return;
		props.onEdit(props.category);
	};
	const onDelete = () => {
		if (!props.onDelete) return;
		props.onDelete(props.category);
	};
	const handleTagClick = (tag: any) => {
		if (!props.onTagSelect) return;
		props.onTagSelect(tag);
	};

	const handleRearrange: IGalleryProps<TTag>['onReOrder'] = async (reorderProps, _event) => {
		const { oldIndex, newIndex } = reorderProps;
		const newTagsList = arrayMove(tags, oldIndex, newIndex);
		const newIdsList: string[] = newTagsList.map(tag => tag.id);
		setTags(newTagsList);
		return await withToastMessage(async () => {
			await dispatch(OTag.patchItem(category.id, { tagIds: newIdsList }));
		},
			{ errorToastMessage: 'Failed to rearrange tags', showSuccessMessage: true, successToastMessage: 'Updated tags order' });
	};
	const renderItem = (tag: TTag) => {
		return <Paper className={classes.rearrangeItem} key={tag.id}>{tag.name}</Paper>;
	};

	return (
		<Paper className={classes.root} elevation={1}>
			<div className={classes.titleContainer}>
				<Typography className={classes.title}>{category.name}</Typography>

				<div>
					{props.onEdit ? (
						<Fab size="small" style={{ boxShadow: 'none' }} onClick={onEdit}>
							<i className="material-icons">edit</i>
						</Fab>
					) : null}
					{props.onDelete ? (
						<Box display="inline-block">
							<DeleteButton raised={false} handleDelete={onDelete} alertMessage="Are you sure you want to delete this category?" />
						</Box>
					) : null}
					{props.handleAddTag ? (
						<Button color={'primary'} onClick={handleAddTagClick}>
							ADD TOPIC
						</Button>
					) : null}
					<Button color={'secondary'} variant={rearrangeMode ? 'contained' : 'text'} onClick={() => setRearrangeMode(!rearrangeMode)}>
						REARRANGE
					</Button>
				</div>
			</div>
			{rearrangeMode ? <Gallery<TTag>
				renderItem={renderItem}
				data={tags}
				onReOrder={handleRearrange}
				cols={6}
				generateKey={(item, i) => item.id || i.toString()}
			/> : (tags).map((tag) => {
				let isActive = (selectedTagIds ?? []).findIndex((item) => item === tag.id) > -1;
				return (
					<Chip
						onClick={() => handleTagClick(tag)}
						key={tag.id}
						className={clsx(classes.chip, isActive ? classes.activeChip : classes.inactiveChip)}
						label={tag.name}
						onDelete={props.handleDeleteTag ? () => props.handleDeleteTag!(tag) : undefined}
					/>
				);
			})}
			{props.dragHandleProps ? (
				<div className={'dragHandle'} {...dragHandleProps}>
					<Fab size="small" className={classes.dragButton}>
						<i className="material-icons">unfold_more</i>
					</Fab>
				</div>
			) : null}
		</Paper>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: { padding: '25px 25px 25px 15px', position: 'relative', borderRadius: 0 },
		title: { fontSize: 16, fontWeight: 'bold', textTransform: 'uppercase' },
		rearrangeItem: {
			padding: theme.spacing(1, 2),
			cursor: 'grab',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%'
		},
		titleContainer: {
			display: 'flex',
			position: 'sticky',
			top: 0,
			alignItems: 'center',
			justifyContent: 'space-between',
			background: theme.palette.common.white,
			marginBottom: 8,
		},
		dragButton: {
			position: 'absolute',
			top: '30px',
			right: '-21px',
		},
		chip: {
			marginRight: 8,
			marginBottom: 8,
		},
		inactiveChip: {
			backgroundColor: theme.palette.grey['700'],
			'&:active': { backgroundColor: theme.palette.grey['500'], borderColor: 'white' },
			'&:hover': { backgroundColor: theme.palette.grey['500'], borderColor: 'white' },
			'&:focus': { backgroundColor: theme.palette.grey['500'], borderColor: 'white' },
		},
		activeChip: {
			backgroundColor: theme.palette.primary.main,
			color: 'white',
			'&:active': { backgroundColor: theme.palette.primary.main, borderColor: theme.palette.primary.main },
			'&:focus': { backgroundColor: theme.palette.primary.main, borderColor: theme.palette.primary.main },
			'&:hover': { backgroundColor: theme.palette.primary.main, borderColor: theme.palette.primary.main },
		},
		deleteBtn: {
			// background: THEME.colors.white,
			// color: THEME.colors.black
		},
		editBtn: {
			// background: THEME.colors.white,
			// color: THEME.colors.black
		},
	})
);

export default memo(
	TagCategoryCard,
	(
		{ onEdit, onDelete, onTagSelect, handleDeleteTag, handleAddTag, ...a },
		{ onEdit: x, onDelete: y, onTagSelect: z, handleDeleteTag: w, handleAddTag: k, ...b }
	) => _.isEqual(a, b)
);


const sortTags = (orderedIds: string[], unOrderedTags: TTag[]) => {
	const orderedTags: TTag[] = [];
	orderedIds.forEach(id => {
		const tag = unOrderedTags.find(t => t.id === id);
		if (tag)
			orderedTags.push(tag);
	});
	return orderedTags;
};