import React, { Fragment, useState } from 'react';
import RequestOnType from '../../Components/RequestOnType';
import { TLinkPreview } from '../../Models/Post/@types';
import { Box, List, ListItem, ListItemAvatar, Avatar, ListItemText, Collapse, LinearProgress, Button } from '@material-ui/core';
import LoopFront from 'loop-front';
import Loader from '../../Components/Loader';
import LinkPreviewCard from '../../Components/Cards/LinkPreviewCard';

const PODCAST_PAGE_SIZE = 10;

export interface SearchPodcastProps {
	onSelect: (podcastEpisode: TLinkPreview) => void;
	initialData?: TLinkPreview;
}

const SearchPodcast: React.FC<SearchPodcastProps> = (props) => {
	const [searchResult, setSearchResult] = useState<Array<TLinkPreview>>([]);
	const [selectedPodcast, setSelectedPodcast] = useState<TLinkPreview | null | undefined>(props.initialData);
	const [loadingEpisodes, setLoadingEpisodes] = useState(false);
	const [selectedEpisode, setSelectedEpisode] = useState<TLinkPreview>();
	const [hasMore, setHasMore] = useState(true);
	const [loadingMore, setLoadingMore] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);
	const [nextEpisodePubDate, setNextEpisodePubDate] = useState(-1);

	const parseListennotesPodcast = (data: any): Array<TLinkPreview> => {
		return (data.results || []).map((item: any) => {
			const { title_original, publisher_original, description_original, listennotes_url, thumbnail, id } = item;
			return {
				url: listennotes_url,
				image: thumbnail,
				title: title_original,
				description: description_original,
				author: publisher_original,
				listenNotes_id: id,
			};
		});
	};


	const onSelectedTextClear = () => setSelectedPodcast(null);

	const onResponse = (res: any) => {
		loadingMore && setLoadingMore(false);
		setSearchResult((prevData) => [...(pageNumber > 1 ? prevData : []), ...parseListennotesPodcast(res.data)]);
	};

	const onChange = () => {
		setSearchResult([]);
		setSelectedEpisode(undefined);
	};

	const getEpisodes = async (item: TLinkPreview, loadMore: boolean = false) => {
		loadMore ? setLoadingMore(true) : setLoadingEpisodes(true);

		if (item.listenNotes_id) {
			const res = await LoopFront.request({
				baseURL: 'https://listen-api.listennotes.com/api/v2',
				url: `podcasts/${item.listenNotes_id}`,
				headers: { 'X-ListenAPI-Key': '2ca1535469224b56b6a8372eaabb02a5' },
				params: nextEpisodePubDate === -1 ? {} : { next_episode_pub_date: nextEpisodePubDate },
			}).catch((error) => {
				console.error('Error', error.response);
				throw error.response;
			});

			loadMore ? setLoadingMore(false) : setLoadingEpisodes(false);

			setNextEpisodePubDate(res.data.next_episode_pub_date);
			setSearchResult((prevResults) => [...(loadMore ? prevResults : []), ...parseListennotesEpisodes(res.data)]);
		} else {
			setSelectedEpisode(item);
			/* Bad code but quick fix.  item can be either podcast or episode. If it is a podcast it will have listenNotes_id.  *
			 * Therefore if item doesn't have listenNotes_id, it is an episode. Set it and we're done. 										*/
			setLoadingEpisodes(false);
		}
	};

	const podcastClicked = async (item: TLinkPreview) => {
		if (selectedPodcast) {
			props.onSelect(item);
			// setSelectedEpisode(item);
			// return;
		}
		setLoadingEpisodes(true);
		setSelectedPodcast(item);
		setHasMore(true);
		getEpisodes(item);
		// setHasMore(true);
		// const res = await LoopFront.request({
		//     baseURL: 'https://listen-api.listennotes.com/api/v2',
		//     url: `podcasts/${item.id}`,
		//     headers: { 'X-ListenAPI-Key': '2ca1535469224b56b6a8372eaabb02a5' }
		// }).catch(error => {
		//     console.log('Error', error.response);
		//     throw error.response;
		// });
		// console.log('Podcast Episodes', res.data);
		// setloadingEpisodes(false);
		// setSearchResult(parseListennotesEpisodes(res.data));
	};

	const handleLoadMoreClick = React.useMemo(
		() => () => {
			if (!selectedPodcast && searchResult.length < PODCAST_PAGE_SIZE * pageNumber) {
				setHasMore(false);
				return;
			}
			setLoadingMore(true);
			selectedPodcast ? getEpisodes(selectedPodcast, true) : setPageNumber(pageNumber + 1);
		},
		// eslint-disable-next-line
		[pageNumber, searchResult]
	);

	return (
		<Fragment>
			<Box my={2}>
				<RequestOnType
					queryKey={'q'}
					onResponse={onResponse}
					onChange={onChange}
					showLoading
					config={{
						url: 'search',
						baseURL: 'https://listen-api.listennotes.com/api/v2/',
						headers: { 'X-ListenAPI-Key': '2ca1535469224b56b6a8372eaabb02a5', Authorization: '' },
						params: { type: 'podcast', offset: PODCAST_PAGE_SIZE * (pageNumber - 1), count: PODCAST_PAGE_SIZE },
					}}
					label="Start typing to find your podcast"
					selectedText={selectedPodcast ? selectedPodcast.title : ''}
					onSelectedTextClear={onSelectedTextClear}
					extraData={[pageNumber]}
				/>
			</Box>
			<Collapse in={selectedEpisode && !loadingEpisodes}>{selectedEpisode && <LinkPreviewCard onChange={(data) => {
				setSelectedEpisode(data);
				props.onSelect(data);
			}} previewData={selectedEpisode} />}</Collapse>
			{loadingEpisodes ? (
				<Loader />
			) : searchResult.length > 0 && !selectedEpisode ? (
				<Box my={1} maxHeight={400} overflow={'auto'}>
					<List>
						{searchResult.map((item) => {
							return (
								<ListItem key={item.id} button onClick={() => podcastClicked(item)}>
									<ListItemAvatar>
										<Avatar alt={item.title} src={item.image} />
									</ListItemAvatar>
									<ListItemText primary={item.title} secondary={item.author} />
								</ListItem>
							);
						})}
					</List>
					{hasMore ? (
						loadingMore ? (
							<LinearProgress />
						) : (
								<Box display={'flex'} justifyContent={'center'}>
									<Button onClick={handleLoadMoreClick}>Load More</Button>
								</Box>
							)
					) : null}
				</Box>
			) : null}
		</Fragment>
	);
};

export default SearchPodcast;

const parseListennotesEpisodes = (data: any): Array<TLinkPreview> => {
	const { description, publisher } = data;
	return (data.episodes || []).map((item: any) => {
		const { title, listennotes_url, thumbnail } = item;
		return { url: listennotes_url, image: thumbnail, title, description, author: publisher };
	});
};
