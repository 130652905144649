import { JSONType, UploadProgress } from "typings/global";
import { request } from "Resources/AxiosUtils";
import { parseAsset } from "./assetParsers";


export const uploadAsset = async (data: any, setProgress?: (progress: number) => any) => {
	let formData = new FormData();
	formData.append('', data);
	try {
		const result = await request<JSONType[]>(
			{
				url: 'assets/upload',
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				method: 'POST',
				onUploadProgress: (progressEvent: UploadProgress) => {
					const progress = (progressEvent.loaded / progressEvent.total) * 100;
					setProgress && setProgress(progress);

				}
			}
		);
		return (result || []).map(parseAsset);
	} catch (err) {
		throw err;
	}
};
