import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TReduxStore } from 'RootReducer';
import { ExploreHomeState, ExploreHome } from 'Models/ExploreHome/@types';
import { TDispatch } from 'Models/App/@types';
import { OExploreHome } from 'Models/ExploreHome';
import { Formik, FormikProps } from 'formik';
import DialogForm from 'Components/Dialogs/DialogForm';
import { FormConfig, MLFormContent } from 'react-forms';
import LoopFront from 'loop-front';
import { Paper, makeStyles, Theme, createStyles } from '@material-ui/core';
import { parseExploreHomeForm } from 'Models/ExploreHome/ExploreHomeParsers';
import ColorSwatchPicker from 'Components/ColorSwatchPicker';
import * as Yup from 'yup';
import useToastMessage from 'Hooks/useToastMessage';
import { MainTagType, TTag, TTagState } from 'Models/Tag/@types';
import { useHistory } from 'react-router';
import queryString from 'query-string';

type ReduxSelector = Pick<ExploreHomeState, 'showMainForm' | 'editingItem'> & { tagCategories: TTagState['list'] };
const ExploreHomeItemForm = () => {
	const { showMainForm, editingItem, tagCategories } = useSelector<TReduxStore, ReduxSelector>(
		({ ExploreHome: { showMainForm, editingItem }, Tag: { list: tagCategories } }) => ({ showMainForm, editingItem, tagCategories })
	);
	const dispatch = useDispatch<TDispatch>();
	const classes = useStyles();
	const withToast = useToastMessage();
	const tags = tagCategories?.filter((tagCategory) => tagCategory.type === MainTagType)?.[0]?.tags;
	const defaultTag = tags?.filter((tag) => tag.value === 'all')?.[0];
	const history = useHistory();
	let { filter } = queryString.parse(history.location.search);
	if (!filter) {
		filter = defaultTag?.id || '';
	}

	const closeForm = () => {
		dispatch({ type: OExploreHome.Actions.HIDE_MAIN_FORM });
		dispatch({ type: OExploreHome.Actions.SET_EDITING_ITEM, data: undefined });
	};

	if (!showMainForm) return null;
	const handleSubmit = (formData: any) => {
		closeForm();
		withToast(
			async () => {
				const type = formData.subjectType;
				if (!formData.id) {
					const res = await dispatch(OExploreHome.postItem(parseExploreHomeForm(formData)));
					const newItem = await LoopFront.request({
						url: `exploreHomes/${res.data.id}`,
						method: 'GET',
						params: {
							filter: {
								...(type === 'ExploreList'
									? {
											include: {
												relation: 'subject',
												scope: { include: { relation: 'list-documents', scope: { include: 'subject' } } },
											},
									  }
									: { include: { relation: 'subject' } }),
							},
						},
					});
					if (formData.tagId === filter) dispatch({ type: OExploreHome.Actions.ADD_LIST_ITEM, data: newItem.data });
					else {
						console.error({ selected: formData.tagId, filter });
					}
				} else {
					const res = await dispatch(OExploreHome.patchItem(formData.id, parseExploreHomeForm(formData)));
					const newItem = await LoopFront.request({
						url: `exploreHomes/${res.data.id}`,
						method: 'GET',
						params: {
							filter: {
								...(type === 'ExploreList'
									? {
											include: {
												relation: 'subject',
												scope: { include: { relation: 'list-documents', scope: { include: 'subject' } } },
											},
									  }
									: { include: { relation: 'subject' } }),
								// ...(type === 'user' ? {} : {})
							},
						},
					});
					dispatch({ type: OExploreHome.Actions.UPDATE_LIST_ITEM, data: newItem.data });
				}
			},
			{
				successToastMessage: formData.id ? 'Updated item in explore' : 'Added item to explore',
				errorToastMessage: 'Failed to add item to explore',
			}
		);
	};
	const getQueryResponse = async (term: string, formikProps: FormikProps<any>) => {
		const doc_type = formikProps.values.subjectType;
		const res = await LoopFront.request({
			url: 'users/autocomplete',
			params: {
				term,
				filter: {
					doc_type,
					...(doc_type === 'user'
						? {
								where: {
									isCurator: {
										ifExistsThen: true,
									},
								},
						  }
						: {}),
				},
			},
		});
		return res.data.results;
	};

	return (
		<Formik<Partial<ExploreHome>>
			enableReinitialize
			initialValues={editingItem || {}}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
		>
			{(formikProps) => (
				<DialogForm
					heading={editingItem?.id ? 'Edit item' : 'Add item to Explore home'}
					onClose={closeForm}
					open={showMainForm}
					onSubmit={formikProps.handleSubmit}
				>
					<Paper className={classes.paper}>
						<MLFormContent
							formId={'explore-home-item'}
							schema={getFormConfig(getQueryResponse, formikProps, tags || [])}
							formikProps={formikProps}
						/>
						{formikProps.values.subjectType === 'MicroCourse' || formikProps.values.subjectType === 'user' ? (
							<ColorSwatchPicker formikProps={formikProps} valueKey={'color'} />
						) : null}
					</Paper>
				</DialogForm>
			)}
		</Formik>
	);
};

export default ExploreHomeItemForm;

const validationSchema = Yup.object({
	subjectType: Yup.string().required('Please select what type of item you would like to add to Explore page'),
	subject: Yup.mixed().required('Please select an item to be added'),
});

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		paper: {
			width: 450,
			padding: '1rem',
		},
	})
);

const getFormConfig = (
	getQueryResponse: (input: string, formikProps: FormikProps<any>) => Promise<Array<any>>,
	formikProps: FormikProps<any>,
	tags: TTag[]
): FormConfig[] => [
	{
		type: 'select',
		valueKey: 'subjectType',
		fieldProps: {
			formControlProps: {
				fullWidth: true,
			},
			options: [
				{ name: 'Curator spotlight', value: 'user' },
				{ name: 'Microcourse spotlight', value: 'MicroCourse' },
				{ name: 'List', value: 'ExploreList' },
			],
			label: 'Select type of item you want to add',
		},
	},
	{
		type: 'select',
		valueKey: 'tagId',
		fieldProps: {
			formControlProps: {
				fullWidth: true,
			},
			options: tags.map((tag) => ({ name: tag.name, value: tag.id })),
			label: 'Tag',
		},
	},
	{
		type: 'autocomplete',
		valueKey: 'subject',
		fieldProps: {
			InputProps: { label: 'Pick item' },
			getQueryResponse: (input: string) => getQueryResponse(input, formikProps),
			getOptionLabel: (value: any) => {
				return value?.name || value?.title || JSON.stringify(value);
			},
			fullWidth: true,
		},
		condition: {
			defaultProps: {
				disabled: false,
			},
			postEffectProps: {
				disabled: true,
			},
			hidden: false,
			logicOpn: 'AND',
			values: [
				{
					compareValue: 'user',
					key: 'subjectType',
					operator: '!==',
				},
				{
					compareValue: 'MicroCourse',
					key: 'subjectType',
					operator: '!==',
				},
				{
					compareValue: 'ExploreList',
					key: 'subjectType',
					operator: '!==',
				},
			],
		},
	},
	{
		type: 'text',
		valueKey: 'spotlightDescription',
		fieldProps: {
			label: "Describe the curator's journey",
			fullWidth: true,
		},
		condition: {
			postEffectProps: {
				disabled: false,
			},
			hidden: true,
			logicOpn: 'AND',
			values: [
				{
					compareValue: 'user',
					key: 'subjectType',
					operator: '===',
				},
			],
		},
	},
];
